import { useEffect, useMemo, useReducer, useState, useRef } from "react";
import GTable from "../../design/components/GTable";
import Button from "../../design/Button";
import { Filters } from '../../dashboard/Filters';
import AnimationPage from "../../shared/animation-page/AnimationPage";
import {JService} from '../../api-service/ApiService';
import { Box, Chip, TextField, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../design/colors";
import styles from "../../design/styles";
import { filtersReducer, formatDate, formatTime, getOrgURLParam } from "../../../utility-functions";
import classes from './SmsLogs.module.css';

// # assets
import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";
import {
  callIcon,
  callLogsTableIncomingArrow,
  callLogsTableOutgoingArrow,
  errorIcon,
  messageIcon,
  
} from "../../../utility-functions/assetLoader";
import { enqueueSnackbar } from "notistack";
import { CustomChip } from "../../design/components/customComponents";

const MESSAGE_TYPES_MAPPING = {
  "inbound": 1,
  "outbound": 2,
  "unread":3
};

const initialState = {
    users: { all: [], selected: [] },
    teams: { all: [], selected: [] },
    tags: { all: [], selected: [] },
    messageType:{
    all:[
        {id:'inbound', name:'Inbound'},
        {id:'outbound', name:'Outbound'},
        {id:'unread', name:'Unread'}
        ],
    selected:[]
    },
    searchText: "",
    searchFields:[]

  };

const formatCalendarDate = (date) => {
    return `${new Date(date).getFullYear()}-${(
      "0" +
      (new Date(date).getMonth() + 1)
    ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
  };
  const localStyles = {
    spanNoOverFlowStyleForLogsContact: {
      display: "inline-block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "160px",
    },
  };

const SmsLogs=({users, teams, allTags, id, fetchData, tagDetailsSet, setTagDetailsSet, handleOpenConversation, setMessageHistoryModal, messagingEnabled, initiateCall})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const [availableData,dispatchAvailableData]=useReducer(filtersReducer,initialState, )

    const navigate=useNavigate()
    const dispatch=useDispatch()

    const reduxAccount=useSelector((state)=>state?.account?.value)
    const reduxCredits=useSelector((state)=>state?.account?.value?.credits)
    const reduxDialer = useSelector((state) => state?.globalDialer?.value);

    const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1

    const [gTablePageNumber, setGTablePageNumber]=useState(0)
    const [showLoader, setShowLoader]= useState(false)
    const [pageData, setPageData] = useState({rowCount:0, pageSize:10})
    const [data, setData]=useState([])
    const [rowsTags, setRowsTags] = useState([]);
    const [calendar,setCalendar]=useState({date_start: formatCalendarDate(Date.now() - 86400000), //  2 days data
    date_end: formatCalendarDate(Date.now())})
    const [showTable, setShowTable]=useState(true)
    

    const selectedRowTagRef = useRef([]);

    const handlePageChange=(newPage, pageDirection)=>{
      if (pageDirection && newPage > -1) {
        const _type = "fetch".concat(pageDirection);
        fetchMessageLogs(_type, newPage + 1, pageData.pageSize);
        
      }
    }

    const handlePageSizeChange=(newSize)=>{
    setGTablePageNumber(0);
    fetchMessageLogs("page-size-change", 1, newSize);
    }

    const fetchMessageLogs=(type, page_number, page_size, calendarObj={})=>{
      setShowLoader(true)

      const date_start=calendarObj.date_start || calendar.date_start
      const date_end=calendarObj.date_end || calendar.date_end

      setShowTable(false)

      let url= `/api/v1/core/retrieve-messages/${getOrgURLParam()}?webapp=True&page=${page_number}&page_size=${page_size}&date_start=${date_start}&date_end=${date_end}`;
      if(availableData.users?.selected?.length)
      url += `&users=${JSON.stringify(availableData?.users?.selected)}`;

      if (Object.keys(availableData?.teams?.selected)?.length)
        url += `&teams=${JSON.stringify(Object.keys(availableData.teams.selected)?.map(at => Number(at)))}`;

      if (availableData.tags?.selected?.length)
        url += `&tags=${JSON.stringify(availableData.tags.selected)}`;

      if (availableData.messageType.selected?.length) {
              let temp = null;
              try {
                temp = availableData.messageType.selected?.map(
                  (s) => MESSAGE_TYPES_MAPPING[s]
                );
              } catch (er) {
                temp = null;
              }
              if (temp instanceof Array)
                url += `&message_types=${JSON.stringify(temp)}`;
            }
    
      if (availableData?.searchText?.trim()?.length !== 0) {
          url += `&search=${encodeURIComponent(
          availableData?.searchText?.trim()
          )}`;
      }
    
      if (type === "filters-changed" && gTablePageNumber > 0) {
       
        setGTablePageNumber(0);
       
      }
      if (!showLoader && showTable) setShowLoader(true);
      JService.get(url)
      .then((res)=>{
        if(!res.success) 
          throw new Error(res.message || 'Failed to retrieve message logs')

        setData(res?.data?.results)
        setPageData({rowCount:res?.data?.count || 0, pageSize:page_size})
      
        enqueueSnackbar(res?.message, {variant:'success'})
      })
      .catch((err)=>{
        console.log(err)
        enqueueSnackbar(err?.message, {variant:'error'})
      })
      .finally(()=>{
        setShowLoader(false)
        setShowTable(true)
      })
    }

  const handleFilterApply=(filterName, val)=>{
    if (filterName === "Date") {
      setCalendar(val);
      
       dispatchAvailableData({ type: "clearAllFilters", payload: "" });
      setGTablePageNumber(0);
    } else console.log(filterName, val);
   
  }

  // onSearch is called from GTable
  const onSearch = (searchText) => {
    // used for reset when cleared and value of textField is null
    if(window.location.pathname?.includes('messages'))
    window.location.replace('/activity-logs/messages')
    else
    navigate(0);
  };

  const customDownloadHandler=(e)=> {
    let url = `/api/v1/core/send-sms-messages/${getOrgURLParam()}?webapp=true&date_start=${calendar?.date_start}&date_end=${calendar?.date_end}`;

    if (availableData?.users?.selected?.length)
      url += `&users=${JSON.stringify(availableData?.users?.selected)}`;

    if (Object.keys(availableData?.teams?.selected)?.length)
      url += `&teams=${JSON.stringify(Object.keys(availableData.teams.selected)?.map(at => Number(at)))}`;

    if (availableData?.tags?.selected?.length)
      url += `&tags=${JSON.stringify(availableData?.tags?.selected)}`;

    if (availableData.messageType.selected?.length) {
        let temp = null;
        try {
          temp = availableData.messageType.selected?.map(
            (s) => MESSAGE_TYPES_MAPPING[s]
          );
        } catch (er) {
          temp = null;
        }
        if (temp instanceof Array)
          url += `&message_types=${JSON.stringify(temp)}`;
    }

    if (availableData?.searchText?.trim()?.length !== 0) {
      url += `&search=${encodeURIComponent(availableData?.searchText?.trim())}`;
    }

    JService.get(url)
      .then((res) => {
        if (!res?.success)
          throw new Error(
            res?.detail || "Error occurred while sending report to inbox"
          );

        enqueueSnackbar(res?.message || "You will receive the call logs report in your inbox", {variant:'success'} )
      })
      .catch((err) => {
        enqueueSnackbar(err?.message, {variant:'error'})
      });
  }

  function handleTagItemClick(e, payload = [], message_id = -1) {
    JService.patch(
      API_BASE_URL + "/api/v1/core/update-message-record/",
      JSON.stringify({ message_id, tag_ids: [...rowsTags, ...payload] })
    )
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Failed to add tag");
       setData(prev=>{
        let temp=[...prev];
        const newData=temp.map((elem)=>{
          if(elem.id===message_id){
            return {
              ...elem, tags:[...res?.data?.tags]
            }
          }
          else{
            return elem
          }
        })
        return newData
        
       })
       enqueueSnackbar(res?.message, {variant:'success'})
       setRowsTags([]);
       
      })
      .catch((err) => {enqueueSnackbar(err?.message, {variant:'error'})})
      .finally(() => {
        if (showLoader) setShowLoader(false);
      });
  }

  function handleRemoveFromSelectedTags(rowId, selectedTagId, selectedTags) {
    let payload = selectedTags
      ?.filter((s) => s?.id != selectedTagId)
      ?.map((sf) => sf?.id);

    JService.patch(
      API_BASE_URL + "/api/v1/core/update-message-record/",
      JSON.stringify({ message_id: rowId, tag_ids: payload })
    )
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Failed to remove tag");
        enqueueSnackbar(res?.message,{variant:'success'})
        setRowsTags([]);
        setData(prev=>{
          let temp=[...prev];
          const newData=temp.map((elem)=>{
            if(elem.id===rowId){
              return {
                ...elem, tags:[...res?.data?.tags]
              }
            }
            else{
              return elem
            }
          })
          return newData
          
         })
        console.log(
          "selectedRowTagRef.current?.map(tag => tag?.id): ",
          selectedRowTagRef.current
        );
        
      })
      .catch((err) => {
       enqueueSnackbar(err?.message,{variant:'error'})
      })
      .finally(() => {
        if (showLoader) setShowLoader(false);
      });
  }

  const onRowClick=(params)=>{
    const candidateDetails={
      name:params?.row?.contact_name,
      phone_number:params?.row?.contact_number
    }
    //when view btn is clicked
    if(reduxAccount?.data?.email === params?.row?.creator){
    setMessageHistoryModal({visible:true, id:params?.row?.id, candidateDetails, viewOnly:false, viewedMsgId:params?.row?.id})
    }
    else{
    setMessageHistoryModal({visible:true, id:params?.row?.id, candidateDetails, viewOnly:true, viewedMsgId:params?.row?.id})
    }
  }

   const cols = useMemo(()=>{
      return [
        {
              field: "logsContact",
              headerClassName: "user-table-header-user-name",
              headerName: "Contact",
              minWidth: 285,
              renderCell: (params) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: !params?.row?.read_status? '4px !important':'14px !important',
                    color: colors.nc_gray_900,
                    ...styles.regular_font,
                    ...styles.t7,
                  }}
                >
                  <div style={{display:'flex',alignContent:'flex-start'}}>
                   {!params?.row?.read_status && <span className={classes.unreadCircle}></span>}
                  <Box sx={{display:'flex', flexDirection:'column', maxWidth:'152px'}}>
                  
                  
                  
                  <span
                    style={{
                      display: "inline-block",
                      marginBottom: "4px",
                      ...localStyles.spanNoOverFlowStyleForLogsContact,
                    }}
                  >
                    {params?.row?.contact_name}
                
                  </span>
                 
                  {maskNumbers && params?.row?.contact_name?.trim()?.length ? null :
                  <span
                    className="nc-gray-600 t7"
                    style={{ ...localStyles.spanNoOverFlowStyleForLogsContact }}
                  >
                    {params?.row?.contact_number}
                  </span>}
                 
                  </Box>
                  </div>
                  {reduxAccount?.data?.license && reduxAccount?.data?.license!=='Viewer' &&
                    <div className="table-cell-edit">
                    <div>
                    <span className={classes.contactIcons} onClick={(e)=>initiateCall(e, params?.row?.contact_number)} onMouseDown={(e) => e.stopPropagation()}>{callIcon()}</span>
                    <span onClick={(e)=>messagingEnabled && !params?.row?.contact_number?.startsWith('+91') ? handleOpenConversation(e,params,'sms-logs'):null} disabled={!messagingEnabled || params?.row?.contact_number?.startsWith('+91')} className={`${classes.contactIcons} ${!messagingEnabled || params?.row?.contact_number?.startsWith('+91') ? classes.messageDisabled:''}`}>{messageIcon()}</span>
                    </div>
                  </div>}
                </Box>
              )
        },
        {
          field: "logsContactedOn",
          headerClassName: "user-table-header-user-name",
          headerName: "Contacted on",
          // flex: 1.2,
          minWidth: 220,
          renderCell: (params) => {
            const arrowIdentifier =
              params?.row?.direction === "OUTBOUND"?
               params?.row?.status === "failed"
              ? "errorIcon"
              : "arrowOut":
               params?.row?.direction=== "INBOUND"
              ? params?.row?.status === "delivered"
                ? "arrowIn"
                : "errorIcon"
              : null;
                
            let arrow = null;

            if (arrowIdentifier === "arrowIn")
              arrow = callLogsTableIncomingArrow(colors.ic_green_400);
            else if (arrowIdentifier === "arrowOut")
              arrow = callLogsTableOutgoingArrow(colors.ic_green_400);
            else if (arrowIdentifier==='errorIcon')
              arrow=errorIcon(colors.ic_red_400);
           return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  paddingLeft: "14px",
                  color: colors.nc_gray_900,
                  ...styles.regular_font,
                  ...styles.t7,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "4px",
                    alignItems: "center",
                  }}
                >
                  {arrowIdentifier === "arrowIn" && (
                    <div
                      style={{
                        transform: "rotate(0deg)",
                        display: "inline-block",
                        marginTop: "8px",
                      }}
                    >
                      {arrow}
                    </div>
                  )}
                 
                  {arrowIdentifier !== "arrowIn" &&
            
                      (
                      <div
                        style={{
                          transform: "rotate(0deg)",
                          display: "inline-block",
                        }}
                      >
                        {arrow}
                      </div>
                    )}

                  <span
                    style={{ marginLeft: "12px", display: "inline-block" }}
                  >
                    {formatDate(params?.row?.timestamp)}
                  </span>
                </Box>

                <p
                  style={{ paddingLeft: "28px" }}
                  className="nc-gray-600 t7"
                >
                  &nbsp;{formatTime(params?.row?.timestamp)}
                </p>
              </Box>
            );
          },
        },
        {
          field: "logsUser",
          headerClassName: "user-table-header-user-name",
          headerName: "User",
          minWidth: 220,
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  paddingLeft: "12px",
                  color: colors.nc_gray_900,
                  ...styles.regular_font,
                  ...styles.t7,
                }}
              >
                <Box
                  sx={{
                    marginBottom: "4px",
                    alignItems: "center",
                    marginLeft: "12px",
                  }}
                >
                  <p>{params?.row?.creator_name}</p>
                  <p className="nc-gray-600 t7">
                    {params?.row?.user_number}
                  </p>
                </Box>
              </Box>
            );
          },
        },
        {
          field: "logsMessage",
          headerName: "Message",
          minWidth: 200,
          renderCell: (params) => (
            <Box sx={{ padding: "0px" }}>
              <Button
                onClick={() => onRowClick(params)}
                buttonText="View"
                variant="text"
                hierarchy="white"
                icon={null}
                isLoading={false}
                disabled={false}
                styleOverride={{
                  textDecoration: "none",
                  fontSize:'14px',
                  padding:'0',
                  "&:hover": {
                    textDecoration: "underline",
                    backgroundColor:'transparent'
                  },
                  transition: "all 0.3s ease-out",
                }}
              />
            </Box>
          ),
        },
        {
          field:"logsCost",
          headerName:`Cost (${reduxCredits?.currency})`,
          minWidth: 220,
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  paddingLeft: "14px",
                  color: colors.nc_gray_900,
                  ...styles.regular_font,
                  ...styles.t7,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "4px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ marginLeft: "12px", display: "inline-block" }}
                  >
                    {!isNaN(params?.row?.cost)?Number(params?.row?.cost).toFixed(3):'0.000'}
                  </span>
                </Box>
              </Box>
            );
          },

        },
        {
          field: "logsTags",
          headerName: "Tags",
          headerClassName: "logs-table-header-call-reason-outcome",
          minWidth: 400,
          renderCell: (params) => {
            const tagIdArray = params?.row?.tags?.map((tag) => tag?.id);
            return (
              <Autocomplete
                disabled={!allTags || allTags?.length === 0}
                onChange={(e, value, reason, details) => {
                  selectedRowTagRef.current = value;

                  if (!value) return;
                  handleTagItemClick(
                    e,
                    value?.map((v) => v?.id) || [],
                    params?.row?.id
                  );
                }}
                disableClearable
                filterSelectedOptions
                multiple={true}
                options={allTags}
                limitTags={2}
                onOpen={(e) =>
                  (selectedRowTagRef.current = params?.row?.tags)
                }
                defaultValue={params?.row?.tags}
                renderTags={(valueArray, getTagsProps, state) => {
                  return params?.row?.tags?.map((tag, index) =>
                    index > 1 ? (
                      index === 2 ? (
                        "..."
                      ) : null
                    ) : (
                      <CustomChip
                      key={index}
                      label={tag?.name}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromSelectedTags(
                          params?.row?.id,
                          tag?.id,
                          params?.row?.tags || []
                        );
                      }}
                    />
                    )
                  );
                }}
                // disableCloseOnSelect
                getOptionLabel={(option) => option?.name}
                // dropdown
                renderOption={(props, option, { selected }) => {
                  let isSelected =
                    selected || tagIdArray?.includes(option?.id);
                  return (
                    <li
                      style={{
                        backgroundColor: isSelected
                          ? colors.ic_green_100
                          : colors.ic_white,
                        pointerEvents: isSelected ? "none" : "all",
                      }}
                      key={option?.id}
                      {...props}
                    >
                      <Chip
                        label={option?.name}
                        onMouseDown={(e) => null}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid #E1E6E2",
                          marginRight: "8px",
                          height: "26px",
                          background: colors.ic_white,
                          ...styles.b2,
                          ...styles.regular_font,
                          color: colors.nc_gray_900,
                          lineHeight: "auto",
                          pointerEvents: isSelected ? "none" : "all",
                        }}
                      />
                    </li>
                  );
                }}
                sx={{
                  ...styles.b2,
                  ...styles.regular_font,
                  color: colors.nc_gray_900,
                  width: 500,
                  height: "40px",
                  "& .MuiFormControl-root": {
                    height: "100%",
                  },
                  "& .MuiInputBase-root": {
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    maxWidth: "360px",
                    ".Mui-focused:": {
                      border: `1px solid ${colors.ic_green_500}`,
                      boxShadow: "0px 0px 0px 4px #F0FCF4",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${colors.ic_green_300}`,
                    boxShadow: "0px 0px 0px 4px #F0FCF4",
                  },
                }}
                renderInput={(_params) => (
                  
                  <TextField
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    {..._params}
                    label={""}
                    placeholder={
                      params?.row?.tags?.length === 0
                        ? "+ Add Tags"
                        : ""
                    }
                    maxRows={1}
                    multiline={false}
                    sx={{
                      maxWidth: "360px",
                      color: colors.nc_gray_900,
                      ...styles.b2,
                      ...styles.regular_font,
                    }}
                   
                  />
                )}
              />
            );
          },
        },
      ]
    },[])

    useEffect(()=>{
      if(allTags && Array.isArray(allTags) && allTags.length>0){
         dispatchAvailableData({
            type: "setPayloadToAllOfType",
            payload: { payloadFor: "tags", payload: allTags },
          });
      }
    },[allTags])
  
    useEffect(()=>{
      if(users && Array.isArray(users) && users.length>0){
        dispatchAvailableData({
              type: "setPayloadToAllOfType",
              payload: {
                payloadFor: "users",
                payload: users?.map((rd) => ({
                  name: rd?.first_name + " " + rd?.last_name,
                  id: rd?.id,
                })),
              },
            });
      }
    },[users])
  
    useEffect(()=>{
      if(teams && Array.isArray(teams) && teams.length>0){
        const teamUserMapping = {}
        data?.forEach((u) => {
          teamUserMapping[u.id] = u.users || []
        })
        dispatchAvailableData({
            type: "setPayloadToAllOfType",
            payload: {
              payloadFor: "teams",
              payload: teams?.map((u) => ({
                name: u.name,
                id: u.id,
                // users: u.users || [],
              })),
              extraPayload: teamUserMapping
            },
          });
      }
    },[teams])
  


    useEffect(()=>{
      fetchMessageLogs('filters-changed',1, 10)
    },[availableData?.users?.selected, availableData?.teams?.selected, availableData?.messageType?.selected, availableData?.tags?.selected, availableData?.searchText])

    
    return (
    <div className="screen-container call-logs-override-screen-container">
	  
      <Filters
        onApply={handleFilterApply}
        fromScreen="sms-logs"
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        filtersList={[
          { name: "Message type", isSearch: false, payloadFor: 'messageType' },
          { name: "Team", isSearch: true, payloadFor: "teams" },
          { name: "Users", isSearch: true, payloadFor: "users" },
          { name: "Tags", isSearch: true, payloadFor: "tags" },
        ]}
      />
      {showTable ? (
        <GTable
          className="call-logs-gtable"
          leftHeader={{
            isSearch: true,
            filters: [
            ],
          }}
          searchFields={["logsContact"]}
          dispatchAvailableData={dispatchAvailableData}
          availableData={availableData}
          fromScreen="sms-logs" 
          refreshHandler={null}
          rightHeader={{ isRefresh: true, isDownload: true }}
          download={{
            isCustom: true,
            customDownloadHandler,
            data: {},
            body: null,
            filename:
              "frejun-sms-logs-" +
              new Date().toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }),
          }}
          rows={data}
          columns={cols}
          useMuiTable={true}
          isLoading={showLoader}
          getTableRowClassName='logs-table-row' // to be revised
          additionalProps={{ bgHover: true }}
          onRowClick={() => null}
          onSearch={onSearch}
          combineSearchAndFilters={() => null}
          rowCount={pageData.rowCount || 0}
          handlePageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPage={pageData.pageSize || 10}
          gTablePageNumber={gTablePageNumber}
          setGTablePageNumber={setGTablePageNumber}
          paginationMode="server"
        />
       ) : (
        <AnimationPage />
      )} 
    </div>
    )
}

export default SmsLogs;