import { useEffect, useState } from "react";
import Colors from "../colors";
import "./gfilterStyles.css";
import GFilter from './GFilter';

export default function GFilters({
  filters,
  dispatchAvailableData,
  availableData,
  fromScreen,
  isDateFilterApplied = false,
  clearDateFilterHandler,
}) {
  const [isFilters, setIsFilters] = useState(false);
  
  const handleSelectedTeamsChange = (selectedTeams, changeType) => {
    const teamsFilter = filters.find(elem => elem?.payloadFor === 'teams');
    const usersFilter = filters.find(elem => elem?.payloadFor === 'users');
    if(!teamsFilter || !usersFilter) return;
    const {teamUserMapping} = availableData?.teams;
    if(changeType === 'apply'){
      const users = new Set(availableData?.users?.selected ?? [])
      const usersInTeams = new Set()
      selectedTeams.forEach(teamid => {
          teamUserMapping[teamid].forEach(user => {
            usersInTeams.add(user)
            // if(!availableData?.teams?.selected?.includes(teamid)){
            users.add(user)
          // }
        })
      })

      availableData?.teams?.selected?.forEach(teamid => {
        if(!selectedTeams.includes(teamid)){
          teamUserMapping?.[`${teamid}`]?.forEach(user => {
            if(!usersInTeams.has(user)){
              users.delete(user)
            }
          })
        }
      })
      dispatchAvailableData({
        type: "handleSelectedOfType",
        payload: {payloadFor: "users", payload: [...users] }
      })
    }
    else if(changeType === 'clear'){
      const usersInTeams = new Set();
      availableData?.teams?.selected?.forEach(teamid=>{
        const users = teamUserMapping[teamid];
        users.forEach(user=> usersInTeams.add(user))
      });
      const users = new Set(availableData?.users?.selected ?? []);
      usersInTeams.forEach(user => users.delete(user))
      dispatchAvailableData({
        type:"handleSelectedOfType",
        payload: {payloadFor: 'users', payload: [...users]}
      })
    }
  }

  const areFiltersApplied = () => {
    return Object.values(availableData)?.some(elem => elem?.selected?.length > 0) || isDateFilterApplied;
  }

  useEffect(() => {
    if (availableData) {
      const filtersApplied = areFiltersApplied();
      if (filtersApplied && !isFilters){
        setIsFilters(true);
      }
      else if(!filtersApplied && isFilters){
        setIsFilters(false);
      }
    }
  }, [availableData, isDateFilterApplied]); // eslint-disable-line

  function determineDisabledState(payloadFor) {
    if(availableData?.[payloadFor]?.all?.length === 0) return true;
    return false;
  }

  return (
    <>
      {filters.map((filter, index) => (
        <GFilter
          fromScreen={fromScreen}
          isDisabled={determineDisabledState(filter?.payloadFor)}
          isSearch={filter.isSearch}
          payloadFor={filter.payloadFor}
          key={index}
          title={filter.name || filter?.split(",")[0]}
          dispatchAvailableData={dispatchAvailableData}
          availableData={availableData}
          handleSelectedTeamsChange={handleSelectedTeamsChange}
        />
      ))}
    {filters.length > 0 && <div
      onClick={(e) => {
        if (!isFilters) return;
        dispatchAvailableData({ type: "clearAllFilters", payload: null });
        if (fromScreen === "dashboard" || fromScreen === "call-logs" || fromScreen==="credit-history" || fromScreen==="sms-logs") clearDateFilterHandler();
        setIsFilters(false);
      }}
      className="regular-font t6 clear-gfilters"
      style={{
        color: isFilters ? Colors.ic_green_500 : Colors.nc_gray_400,
        cursor: isFilters ? "pointer" : "not-allowed",
      }}
    >
      Clear all
    </div>}
    </>
  );
}
