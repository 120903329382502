import Button from "../design/Button";
import { enqueueSnackbar } from "notistack";
import { modalCloseIcon, uploadIcon } from "../../utility-functions/assetLoader";
import colors from "../design/colors";

const Audio = ({ file, setFile, setAudioURL, id, audioURL, handleDeleteAudio, label, inputStyle={}, mode, error}) => {
    const handleAudioFileUpload = async (event, setFile, setAudioURL) => {
        const audioFile = event.target.files[0];
        if(audioFile.type !== 'audio/mpeg' || !audioFile.name?.endsWith('.mp3')){
          enqueueSnackbar("Please upload an audio file of the .mp3 format", { variant: "error"})
          event.target.files[0] = ""
          return;
        }
        setFile(id, audioFile);
        setAudioURL(id, URL.createObjectURL(audioFile));
    };

    return (
      <label htmlFor="upload-list-file" className="t7 medium-font" style={{position:'relative'}}>
        {label}
        {(!file && !audioURL) && (
          <>
            <Button buttonText='Upload audio' icon={{startIcon: uploadIcon()}} hierarchy='white' variant='outlined' onClick={()=>null} styleOverride={{width: '100%', marginTop: '8px'}}/>
            <input
              id="upload-audio"
              style={{position: 'absolute', top: '28px', left: 0, opacity: 0, width: '100%', height: '45px', ...inputStyle}}
              placeholder=""
              name="file"
              type="file"
              accept={".mp3"}
              onChange={(e) => {
                handleAudioFileUpload(e, setFile, setAudioURL);
              }}
              disabled={mode === 'Edit' && !isNaN(id)}
            />
            <p className="t7 regular-font ic-red-400" style={{marginTop: '4px'}}>{error}</p>
          </>
        )}
        {(file?.name || audioURL) && (
          <>
            <div className="">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '8px'
                }}
              >
                <audio src={audioURL} controls style={{height: '38px'}}/>
                {(mode === 'Create' || isNaN(id)) && <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteAudio(id);
                  }}
                >
                  {modalCloseIcon(colors.nc_gray_900, 16, 16)}
                </span>}
              </div>
            </div>
          </>
        )}
      </label>
    );
  };

  export default Audio;