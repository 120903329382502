import React, { useEffect, useState } from "react";
import { JService } from "../api-service/ApiService";
import Button from "../design/Button";
import { enqueueSnackbar } from 'notistack';
import { MenuItem, Select } from "@mui/material";
import colors from "../design/colors";
import commonStyles from "../design/commonStyles";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import { useSelector } from "react-redux";

function GeneralTab({ originalSettings, setOriginalSettings, surveysList }) {
  const accessList = useSelector(state => state?.account?.value?.data?.access_list);
  const [numberName, setNumberName] = useState("");
  const [numberType, setNumberType] = useState("");
  const [recordCalls, setRecordCalls] = useState(false);
  const [postCallSurveyEnabled, setPostCallSurveyEnabled] = useState(false);
  const [postCallSurvey, setPostCallSurvey] = useState()
  const [stickyAgent, setStickyAgent] = useState(false);
  const { number, location, country_code } = originalSettings;


  useEffect(() => {
    const { name, type, record_calls, post_call_survey, ivr_surveys } = originalSettings;
    setNumberName(name);
    setNumberType(type);
    setRecordCalls(record_calls);
    setStickyAgent(originalSettings?.sticky_agent);
    setPostCallSurveyEnabled(post_call_survey);
    setPostCallSurvey(ivr_surveys?.length > 0 ? ivr_surveys[0] : [])
  }, [originalSettings]);

  const isSaveDisabled = () => {
    return !(
      (numberName != originalSettings.name && numberName != "") ||
      recordCalls != originalSettings.record_calls ||
      (stickyAgent != originalSettings.sticky_agent &&
        numberType === "Voice Broadcast")
    );
  };

  const isSaveSurveySettingsDisabled = () => {
    return (
      postCallSurveyEnabled === originalSettings.post_call_survey && 
      postCallSurvey?.id === originalSettings?.['ivr_surveys']?.[0]?.id) || 
      (postCallSurveyEnabled && !postCallSurvey?.id)
  }

  const handleSave = () => {
    let url;
    const payload = {
      name: numberName,
      record_calls: recordCalls,
      survey_id: postCallSurveyEnabled ? postCallSurvey.id : null,
      post_call_survey: postCallSurveyEnabled
    };

    if (originalSettings.type === "Calling") {
      url = `/api/v1/auth/virtual-numbers/calling/${originalSettings.id}/`;
    } else if (originalSettings.type === "Voice Broadcast") {
      url = `/api/v1/auth/virtual-numbers/voice-broadcast/${originalSettings.id}/`;
      payload["sticky_agent"] = stickyAgent;
    } else return;

    JService.patch(url, payload)
      .then((res) => {
       
        if (!res?.success) throw new Error(res?.message || "Error while updating virtual number")
          
		const updatedSettings = {
			...originalSettings,
			name: numberName,
			record_calls: recordCalls,
      post_call_survey: res.data.post_call_survey,
      ivr_surveys: res.data.ivr_surveys
		};
		if (originalSettings.type === "Voice Broadcast") updatedSettings["sticky_agent"] = stickyAgent;
		setOriginalSettings(updatedSettings);
		enqueueSnackbar(res?.message, { variant: "success"})

      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };

  // const handleSaveSurveyChanges = async () => {

  // }

  return (
    <>
      <div>
        <p className="t5 medium-font">Number</p>

        <form
          className="new-contact-list-form add-contact-form virtual-numbers-form"
          style={{ maxWidth: "400px", marginBottom: "82px" }}
        >
          <label className="t7 medium-font" style={{ marginTop: "24px" }}>
            Number name
            <input
              placeholder="Enter name"
              className="px-14"
              type="text"
              onChange={(e) => {
                setNumberName(e.target.value);
              }}
              value={numberName}
            />
          </label>
          <label className="t7 medium-font" style={{ marginTop: "24px" }}>
            Virtual number
            <input
              className="px-14"
              type="text"
              value={number + " (" + location + ")"}
              disabled
            />
          </label>
          {/* <label className="t7 medium-font" style={{ marginTop: "24px" }}>
            Number type
            <input type="text" className="px-14" value={numberType} disabled />
          </label>
          {numberType === "Voice Broadcast" && (
            <label
              className="switch"
              style={{ marginTop: "32px", marginBottom: "50px" }}
            >
              <input
                type="checkbox"
                checked={stickyAgent}
                onChange={(e) => {
                  setStickyAgent(e.target.checked);
                }}
                style={{ cursor: "pointer", marginTop: "0" }}
              />
              <span className="slider round"></span>
              <p
                style={{
                  marginTop: "-6px",
                  marginLeft: "60px",
                  width: "400px",
                }}
                className="t6 regular-font nc-gray-900"
              >
                Sticky agent
              </p>
              <p
                className="t6 regular-font nc-gray-600"
                style={{
                  width: "400px",
                  marginLeft: "60px",
                  marginTop: "0",
                }}
              >
                Connect incoming call to last contacted user.
              </p>
            </label>
          )} */}
          <label className="switch" style={{ marginTop: "32px" }}>
            <input
              type="checkbox"
              checked={recordCalls}
              onChange={(e) => {
                setRecordCalls(e.target.checked);
              }}
              style={{ cursor: "pointer", marginTop: "0" }}
            />
            <span className="slider round"></span>
            <p
              style={{
                marginTop: "-6px",
                marginLeft: "60px",
                width: "400px",
              }}
              className="t6 regular-font nc-gray-900"
            >
              Record calls
            </p>
            <p
              className="t6 regular-font nc-gray-600"
              style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
            >
              {`Automatically record all incoming ${
                numberType === "Calling" ? "and outgoing calls" : "calls"
              } to this virtual number.`}
            </p>
          </label>
        </form>

        <Button
          variant="filled"
          buttonText="Save"
          icon={{}}
          disabled={isSaveDisabled()}
          isLoading={false}
          hierarchy="green"
          onClick={handleSave}
        />
      </div>
      {country_code === '+91' && accessList?.includes('IVR SURVEY') &&
      <>
        <hr style={{margin:'40px 0'}}/>
        <div style={{marginBottom:'60px'}}>
            <h2 className="t5 medium-font nc-gray-900">Post call survey</h2>
            <form style={{maxWidth: '400px'}}>
              <label className="switch" style={{ marginTop: "32px", marginBottom:'24px' }}>
                <input
                  type="checkbox"
                  checked={postCallSurveyEnabled}
                  onChange={(e) => {
                    setPostCallSurveyEnabled(e.target.checked);
                  }}
                  style={{ cursor: "pointer", marginTop: "0" }}
                />
                <span className="slider round"></span>
                <p
                  style={{
                    marginTop: "-20px",
                    marginLeft: "60px",
                    width: "400px",
                  }}
                  className="t6 regular-font nc-gray-900"
                >
                  Post call survey
                </p>
                <p
                  className="t6 regular-font nc-gray-600"
                  style={{ width: "340px", marginLeft: "60px", marginTop: "0" }}
                >
                  Allow users to initiate survey at the end of call.
                </p>
              </label>
              <label
                className="t6 nc-gray-900 medium-font"
                style={{ marginBottom: "12px", marginTop:'24px', display:'block' }}
              >
                Select survey
              </label>
              <Select
                disabled={!postCallSurveyEnabled}
                value={postCallSurvey}
                onChange={(e) => {
                  setPostCallSurvey(e.target.value);
                }}
                sx={{...commonStyles.select, marginBottom:'40px', '&.MuiList-root-MuiMenu-list': {maxHeight: '150px'}}}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => selected?.title || surveysList?.[0]?.title || 'Select'}
              >
                {
                  surveysList.map((survey)=>{
                    return (<MenuItem sx={{...commonStyles.menu_item}} value={survey} key={survey.id}>
                      {survey.title}
                      { survey.id === postCallSurvey?.id &&
                        topNavAccountSelectedAccountTickIcon()}
                    </MenuItem>)
                  })
                }
              </Select>
              <Button
                variant="filled"
                buttonText="Save"
                icon={{}}
                disabled={isSaveSurveySettingsDisabled()}
                isLoading={false}
                hierarchy="green"
                onClick={handleSave}
              />
          </form>
        </div>
      </>
      }    
      </>
  );
}

export default GeneralTab;
