import { Box } from "@mui/material";
import dialerCalling from '../../../../assets/dialer-calling.svg';
const CallConnecting = ({isBrowserCall}) => {
    return (
        <Box
            sx={{
              height: "fit-content",
              width: "312px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <div className="dialer--header">
              <div className="t7 medium-font">Connecting...</div>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                height: "339px",
                width: "312px",
              }}
            >
              <img
                style={{
                  marginBottom: "32px",
                  width: "182px",
                  height: "135px",
                }}
                src={dialerCalling}
              />
              <div
                style={{ marginBottom: "8px" }}
                className="t6 medium-font nc-gray-900"
              >
                Connecting <span className="">...</span>
              </div>
              <div
                className="t7 regular-font nc-gray-900"
                style={{ textAlign: "center", lineHeight: "22px" }}
              >
                {isBrowserCall && <p>Please wait while we connect your call</p>}
                {!isBrowserCall && <p>Please pick up incoming <br /> call on your mobile phone.</p>}
              </div>
            </Box>
          </Box>
    )
}

export default CallConnecting;