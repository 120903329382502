import React from "react";
import {createRoot} from "react-dom/client";
import "./newIndex.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import userReducer from "./components/features/user/user";
import globalModalReducer from "./components/features/modal/modal";
import campaignReducer from "./components/features/campaign/campaign";
import accountReducer from "./components/features/account/account";
import globalErrorMessageReducer from "./components/features/error-message/errorMessage";
import dashboardReducer from "./components/features/dashboard/dashboard";
import settingsOverlayReducer from "./components/features/settings-overlay/settingsOverlay";
import virtualNumberReducer from "./components/features/virtual-number/virtualNumber";
import globalDialerReducer from "./components/features/dialer/dialer";
import navbarPathsReducer from "./components/features/navbar/navbarPaths";
import globalSearchQueryReducer from "./components/features/search-query/searchQuery";
import usersReducer from "./components/features/users/users";
import calendarReducer from "./components/features/calendar/calendar";
import globalStatusReducer from "./components/features/status/status";
import globalAutodialReducer from "./components/features/autodial/autodial";
import clevertap from "clevertap-web-sdk";

export const store = configureStore({
  reducer: {
    user: userReducer,
    globalModal: globalModalReducer,
    campaignReducer,
    account: accountReducer,
    dashboard: dashboardReducer,
    globalErrorMessage: globalErrorMessageReducer,
    settingsOverlay: settingsOverlayReducer,
    virtualNumber: virtualNumberReducer,
    globalDialer: globalDialerReducer,
    globalSearchQuery: globalSearchQueryReducer,
    usersReducer,
    globalCalendar: calendarReducer,
    globalStatus: globalStatusReducer,
    autodial: globalAutodialReducer,
    navbarPaths: navbarPathsReducer,
  },
});


const container = document.getElementById("root");
const root = createRoot(container);
const isDev = false;

const CLEVERTAP_ID = isDev ? process.env.REACT_APP_CLEVERTAP_DEV : process.env.REACT_APP_CLEVERTAP_PROD;
// clevertap.init(CLEVERTAP_ID);

clevertap.privacy.push({ optOut: isDev }); // if set to true data is not tracked by the sdk // clevertap on/off toggle
clevertap.privacy.push({ useIP: !isDev }); // IP is used to get location
clevertap.spa = true;

root.render(
<Provider store={store}>
    <App />
</Provider>
)
{/* <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</React.StrictMode> */}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// serviceWorker.register()
