import { useState, useEffect } from "react";
import AnimationPage from "../../animation-page/AnimationPage";
import '../topNavStyles.css';
import { backIcon as backIcon, noSurveyIcon, surveyIcon, topNavAccountSelectedAccountTickIcon as tickIcon} from '../../../../utility-functions/assetLoader';
import { JService } from "../../../api-service/ApiService";
import { enqueueSnackbar } from "notistack";
import Button from "../../../design/Button";
import colors from "../../../design/colors";

function SendSurvey (props) {
    const [surveys, setSurveys] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [selectedSurvey, setSelectedSurvey] = useState('');
	const [showInfo, setShowInfo] = useState(true);

	const handleSendSurvey = async () => {
		try{
			setShowLoader(true);
            const candidateNumber = props.candidateNumber.number;
			const payload = JSON.stringify({
				call_id: props.callId,
				candidate_number: candidateNumber?.startsWith('+') ? candidateNumber : '+91' + candidateNumber,
				survey_id: selectedSurvey
			});
			const response = await JService.post('/api/v1/calls/send-ivr-survey/', payload);
			if (!response.success) {
				throw new Error(response?.message || 'Error while sending survey');
			}
			setShowLoader(false);
            enqueueSnackbar(response?.message || 'Survey sent successfully!', {variant: 'success'})
            props.setIsSurveySent(true);
			props.handleBack();
			}
		catch(err){
			console.error(err.message || 'Error while sending survey')
            enqueueSnackbar(err.message || 'Error while sending survey', {variant: 'error'})
		}
		finally{
			setShowLoader(false);
		}
	}

    const fetchSurveys = async () => {
		try{
			setShowLoader(true);
			let url = `/api/v1/core/retrieve-vn-surveys/?virtual_number=${encodeURIComponent(props.virtualNumber?.country_code + props.virtualNumber?.number)}`;
			const response = await JService.get(url);
			if(!response.success){
				throw new Error(response.message || 'Error while retreiving surveys')
			}
			setSurveys(response.data);
		}
		catch(err){
			console.error(err.message || 'Error while retrieving surveys');
            enqueueSnackbar(err.message || 'Error while retrieving surveys', {variant: 'error'})
		}
		finally{
			setShowLoader(false);
		}
    }

    useEffect(() => {
        fetchSurveys()
    }, []);

	useEffect(()=>{
		if(props.isBBCall && !props.isCallOngoing){
			props.handleBack();
		}
	},[props.isCallOngoing, props.isBBCall])

    return (
    <div className="survey--container">
	  <div className="survey--header">
	  	<button onClick={props.handleBack}>
            {backIcon()}
        </button>
        <p className="t7 medium-font">Send survey</p>
	  </div>
	  <div className="scrollable-container">
		{showLoader ? <AnimationPage/> :
			<ul className="survey--list">
			{surveys.map(elem => {
				return (
					<li key={elem.id} onClick={() => setSelectedSurvey(elem.id)} className={selectedSurvey === elem.id ? 'selected-survey':''}>
						<p className="survey--title t7 regular-font">{elem.title}</p>
						<span>
							{elem.id === selectedSurvey ? tickIcon(): null}
						</span>
					</li>
				)
			})}
			{surveys?.length === 0 && (
				<div className="survey--no-survey-container">
					<div className="survey--no-survey-icon">{noSurveyIcon(colors.nc_gray_900, 32, 32)}</div>
					<p className="t7 regular-font">No surveys</p>
				</div>
			)}
			</ul>
		}
	  </div>
	  {showInfo && surveys?.length > 0 && (
		<div className="survey--info">
			<p>Clicking on 'Send now' disconnects you from the call; customer stays for automated survey questions.</p>
			<button type="button" className="t7 regular-font" onClick={()=>setShowInfo(false)}>Got it!</button>
		</div>
	  )}
	  <div className="btn-container-wrapper">
      	<div className="btn-container">
            <Button hierarchy='green' variant='contained' buttonText='Send now' disabled={!selectedSurvey || showLoader} onClick={handleSendSurvey} styleOverride={{width: '100%'}}/>
        </div>
      </div>
    </div>
    )
}

export default SendSurvey;