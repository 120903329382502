import axios from "axios";
import { pushToClevertap } from "../../utility-functions/clevertap.js";
import { formatTime } from "../../utility-functions/index.js";
import { enqueueSnackbar } from "notistack";

const API_BASE_URL = process.env.REACT_APP_BETA;
const Storage_Key_Last_Refresh = "frejun-last-refresh"

const urls = {
  "/api/v1/core/send-call-logs/": "Report Downloaded",
  "/api/v1/auth/update-business-hours/": "Working Hours Set",
  "/api/v1/auth/add-users/": "New User Added",
  "/api/v1/core/create-message-template/": "Message Template Created",
  "/api/v1/calls/initiate-call/": "Call Initiated",
};

const unsafePaths = [
	"/login",
	"/interviews",
	"/forgot-password",
	"/signup",
	"/deny",
	"/invite",
	"/card-details",
	"/call-pricing-inr",
	"/call-pricing-usd",
];

const validateStatus = status => status >=200 && status <=500

export const JService = {
  get(url, params = {}, config) {
    return jwtInstance
      .get(url, { params: params, ...config })
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  post(url, data) {
    return jwtInstance
      .post(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  patch(url, data) {
    return jwtInstance
      .patch(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  put(url, data) {
    return jwtInstance
      .put(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  delete(url, data = {}) {
    return jwtInstance
      .delete(url, { data: data })
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  awaitAll() {
    return axios
      .all(Array.from(arguments))
      .then(axios.spread((...responses) => responses))
      .catch((reasons) => Promise.reject(reasons));
  },
};

const jwtInstance = axios.create({
  baseURL: process.env.REACT_APP_BETA,
});

jwtInstance.interceptors.request.use(
  async (config) => {
    const req = {
		...config,
		validateStatus,
    };

    if (["patch", "delete", "post"].includes(req.method)) {
      req.headers["content-type"] = "application/json";
      req.headers["Accept"] = "application/json";
    }
	
    req.withCredentials = true
	
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

const handlePushToClevertap = (res) => {
  let payload = {};
  for (let url in urls) {
    if (res?.config?.url?.startsWith(API_BASE_URL + url)) {
      let eventName = urls[url];
      if (eventName === "New User Added") {
        const userRole = JSON.parse(res?.config?.data || "{}").role;
        if (userRole === "Admin") {
          eventName = "New Admin Added";
        }
        payload.countryOfCalling = JSON.parse(
          res?.config?.data || "{}"
        )?.location;
        if (
          ["Hyderabad", "Bengaluru", "Mumbai"].includes(
            payload.countryOfCalling
          )
        )
          payload.countryOfCalling = "India";
      } else if (eventName === "Call Initiated") {
        payload = {
          time: formatTime(Date.now()),
          ...JSON.parse(res?.config?.data || "{}"),
        };
      }

      if (res?.data?.success) {
        pushToClevertap(eventName, true, payload);
      } else if (!res?.data?.success)
        pushToClevertap(eventName, false, payload, null, res?.data?.message);
    }
  }
};

jwtInstance.interceptors.response.use(
  	async (res) => {
		handlePushToClevertap(res);
		if(res.status === 401) {
			if(res.config) return retryRequest(res.config)
		}
    if(res.status === 204){
      return {success:true}
    }
    if(res.status >= 400 || ('data' in res && typeof data === 'object' && 'success' in res.data && !res.data.success)){
      const formattedError = findErrorString(res.data.message ?? 'Something went wrong')
		  return Promise.reject({success:false, message: formattedError});
    }
		return res?.data;
  	},
   
	  // Any status codes that falls outside the range defined in validateStatus
  	async function (error) {
      console.log("interceptor recieved response with invalid (non-2xx) status", error)
      if(error.code === 'ERR_CANCELED') return {};
      handlePushToClevertap(error.response);
      if (error?.stack?.includes("InvalidTokenError")) {
        if(error.config) await retryRequest(error.config)
      }
      const formattedError = formatError(error)
      return Promise.reject(formattedError);
  	}
);

async function retryRequest(config) {
	if(unsafePaths.includes(window.location.pathname) || window.location.pathname === "/" || config?.url?.includes("/logout")) {
		console.log("cancelling call to refresh access token")
		return
	}

  // 1 call to /refresh every min
  let lastRefresh = localStorage.getItem(Storage_Key_Last_Refresh)
  if(lastRefresh) {
    if(Date.now() - Number(lastRefresh) <= 60_000) {
      console.log("refresh cancelled")
      return
    }
  }

  localStorage.setItem(Storage_Key_Last_Refresh, Date.now())
	
	const refreshSuccess = await refreshAccessToken()
	if(refreshSuccess) {
    // ._retry doesn't run because of validateStatus ?
    console.log("refresh successful config", config)
		//reload the page
    window.location.reload()
	}
	else {
		// user should be logged out or implement retries
		enqueueSnackbar("Request failed. All tokens expired, please login again", {variant: "error"})
		safeLogout()
	}
}

function findErrorString(err){
  const genericMessage = 'Something went wrong';
  if(typeof err === 'string') return err;
  if (typeof err === 'object' && Array.isArray(err)){
    if(err.length > 0)  return findErrorString(err[0])
    else return genericMessage
  }
  if(typeof err === 'object'){
    if(Object.keys(err)?.length > 0) return findErrorString(Object.values(err)[0])
    else return genericMessage
  }
  return genericMessage
}

function formatError(error) {
	const errObj = {
    	success:false
    };
    if(error?.response?.data && typeof error.response.data==='object' && 'message' in error.response.data){
      const message = error.response.data.message;
      errObj['message'] = findErrorString(message)
    } 
    else{
      errObj['message']='Something went wrong'
    }
	return errObj
}

export async function refreshAccessToken() {
	const refreshResponse = await fetch(process.env.REACT_APP_BETA + "/api/v1/auth/token/refresh/?webapp=True", {
			method: "post",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",		
		}
	);
	return refreshResponse.status === 200
}

async function safeLogout(sendLogoutRequest = true) {
  try {
    const avoid = [
      "length",
      "clear",
      "getItem",
      "key",
      "removeItem",
      "setItem",
      "removeItem",
      Storage_Key_Last_Refresh,
    ];
    for (const x in localStorage) {
      if (
        !x ||
        x.includes("WZRK") ||
        avoid.includes(x) ||
        x.includes("redirect")
      )
        continue;
      localStorage.removeItem(x);
    }
    if(sendLogoutRequest){
      const res = await JService.get("/api/v1/auth/logout/?webapp=true")
      console.log("safelogout res", res)
    }
    if(!['/login', '/'].includes(window.location.pathname)){
      setTimeout(() => {
        window.location.replace("/login");
      }, 300);
    }
  } catch (error) {
	console.error(error)
  }
}
