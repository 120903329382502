import './pageNotFoundStyles.css'
import pageNotFoundArt from '../../assets/page-not-found-art.svg'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function PageNotFound() {
    const navigate = useNavigate()
    const handleGoToCallLogs = () => navigate('/call-logs', {replace: true})

    //This is not required most probably
    useEffect(()=>{
		// var token = localStorage.getItem('frejun-token')
		// if(token) token = JSON.parse(localStorage.getItem('frejun-token'))
        
		// if route is home check for token //  CLARIFY: why this condition ? 
		// 404's while logged in  ->  redirected to /call-logs
		// 404's while logged out ->  redirected to /login
		if(window.location.pathname === '/'){
            navigate('/login', {replace: true})
        }
    }, [])
    return(
        <div className='page-not-found-container nc-gray-900'>
            <img src={pageNotFoundArt} alt='Page not found vector art'/>
            <p className='medium-font t4'>Page not found</p>
            <p className='regular-font t5'>Looks like the page does not exist.</p>
            <input onClick={handleGoToCallLogs} type='button' className='btn btn-green regular-font b6' value='Go to Call Logs' />
        </div>
    )
}
