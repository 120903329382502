import "./signupScreen4Styles.css";

import vectorArt from "../../assets/signup-screen4.svg";
import dotsBottom from "../../assets/login-dots-bottom.svg";
import dotsTop from "../../assets/login-dots-top.svg";
import logo from "../../assets/top-nav-logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch } from "react-redux";

import {
  saveSelectedOrg,
  setAllOrgsDetails,
} from "../features/account/account";
import { Box, Select, MenuItem } from "@mui/material";
import Button from "../design/Button";
import {
  signupScreen3ButtonBackIcon,
  topNavAccountSelectedAccountTickIcon,
} from "../../utility-functions/assetLoader";
import colors from "../design/colors";
import {commonStyles} from "./commonStyles";
import './commonStyles.css';
import { JService } from "../api-service/ApiService";
import { CustomInput, CustomSelect } from "../design/components/customComponents";
import { enqueueSnackbar } from 'notistack';

const localStyles = {
  useCaseItemButtonStyle: {
    position: "absolute",
    width: "calc(50% - 12px)",
    justifyContent: "flex-start",
    "& .MuiButton-endIcon": {
      position: "absolute",
      marginRight: "unset",
      marginLeft: "unset",
      right: "18px",
    },
  },
  usesContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
 


};
// const teamSizeChoices={"1-10":1, "11-50":2, "51-100":3, "100 or more":4}
const teamSizeChoices = [{value: 1, displayValue: "1-10"},{value: 2, displayValue: "11-50"},{value: 3, displayValue: "51-100"},{value: 4, displayValue: "100 or more"},]
const useCaseChoices = [{value: "Customer support", displayValue: "Customer support"},{value: "Recruitment", displayValue: "Recruitment"}, {value: "Sales", displayValue: "Sales"}, {value: "Other", displayValue: "Other"}]
const API_BASE_URL = process.env.REACT_APP_BETA;
export default function SignupScreen4() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [organizationName, setOrganizationName] = useState({value:'',error:null});
  const [teamSize, setTeamSize] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const [showLoader,setShowLoader]=useState(false)

  const handleCompanyNameChange=(e)=>{
    setOrganizationName(prev=>({...prev,value:e.target.value}))
  }
  const handleFocus=()=>{
    setOrganizationName(prev=>({...prev,error:null}))
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // validation
    if (organizationName?.value?.trim()?.length === 0) {
      setOrganizationName(prev=>({...prev,error:'Please enter a valid name'}))
      return;
    }
    let errors=null
    setShowLoader(true)
    JService.patch("/api/v1/auth/update-organization/",
      JSON.stringify({ 
        company: organizationName?.value,
        use_case:selectedUseCase,    
        // team_size: teamSizeChoices[teamSize]       
        team_size: teamSize      
       })
    )
      .then((res) => {
        if (!res?.success){
          if(res?.details) errors=res?.details
          throw new Error(
            res?.message || res?.detail || "Could not update company details"
          );
        }
		enqueueSnackbar(res?.message || "Updated company details", { variant: "success"})
       
        dispatch(setAllOrgsDetails([res?.data]));
        dispatch(saveSelectedOrg(res?.data?.id));
        navigate("/card-details");
      })
      .catch((err) =>{
        if(errors && errors?.company){
          setOrganizationName(prev=>({...prev,error:Array.isArray(errors?.company)?errors?.company[0]:errors.company}))
        }
        else enqueueSnackbar(err?.message || "Could not update company details", { variant: "error"})
  })
      .finally(() => {
        setShowLoader(false)
        if(!errors || !errors?.company)
        setOrganizationName({value:'',error:null});
        setSelectedUseCase("");
        setTeamSize(null)
      });
  };

  return (
      <div className="signup-screen4-container signup-screen-container nc-gray-900">
        <div className="signup-art">
        <a href="https://frejun.com/" target="_blank" rel="noopener noreferrer"> <img src={logo} alt="Logo" /></a>
          <img src={dotsTop} />
          <img src={vectorArt} />
          <img src={dotsBottom} />
        </div>

        <div
          className="form signup-screen-form screen4"
          style={{
            width: "50vw",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            justifyContent:'center'
          }}
        >
          
          <p className="title medium-font t4">Company details</p>
          <form className="signup-screen-form4">
          <CustomInput label='Company name' type='text' name='company' value={organizationName} handleChange={handleCompanyNameChange} handleFocus={handleFocus}/>
          <div className="select-container">
            <CustomSelect 
              label="Team size"
              value={teamSize}
              handleChange={(e) => setTeamSize(e.target.value)}
              renderVal={(val) => {
                return (
                  val ? <span className="nc-gray-900 t7 regular-font"> 
                          {teamSizeChoices.find(elem => elem.value === val)?.displayValue} 
                        </span>
                  : <span className="nc-gray-400 t7 regular-font"> Select team size </span>
                )
              }}
              options={teamSizeChoices}
            />
          </div>
          <div className="select-container">
            <CustomSelect
              label="Planning to use FreJun for?"
              value={selectedUseCase}
              handleChange={(e) => setSelectedUseCase(e.target.value)}
              options={useCaseChoices}
              defaultValue="Select use"
            />
          </div>
         <div className="buttons-container">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop:'32px',

            }}
          >
            <Button
           
              disabled={
                !organizationName?.value ||
                organizationName?.value?.trim()?.length === 0 ||
                !selectedUseCase ||
                !teamSize
              }
              icon={{ startIcon: null, endIcon: null }}
              hierarchy="green"
              variant="contained"
              buttonText="Next"
              isLoading={showLoader}
              onClick={handleSubmit}
              styleOverride={{

                  '@media screen and (max-width: 768px)': {
                    width:"330px" },
                  
                marginRight: "8px",
                border: `1px solid ${
                  !organizationName?.value ||
                  organizationName?.value?.trim()?.length === 0 ||
                  !selectedUseCase
                    ? colors.nc_gray_300
                    : colors.ic_green_400
                }`,
              }}
            />
          </Box>
          </div>
          </form>
        </div>
      </div>
       
  
  );
}
