import { useEffect, useState } from 'react';
import { dropdownIcon } from '../../../utility-functions/assetLoader';
import {Checkbox, Menu, MenuItem, Box} from "@mui/material";
import Button from '../Button';
import checkboxCheckedIcon from "../../../assets/gusers-and-teams-checbox-checked-icon.svg";
import checkboxIcon from "../../../assets/gusers-and-teams-checbox-icon.svg";
import searchIcon from "../../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import './gfilterStyles.css';
import colors from '../colors';
import '../../../index.css';

const GFilter = ({title, isDisabled, isSearch, availableData, dispatchAvailableData, payloadFor, handleSelectedTeamsChange}) => {
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const [optionsToDisplay, setOptionsToDisplay] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);

    const handleToggleableItemClick = (e, id, name, users) => {
        if(selectedOptions.includes(id)){
            setSelectedOptions((prev) => prev.filter((elem) => elem != id));
        }
        else{
            setSelectedOptions(prev => [...prev, id]);
        }
    };

    const handleClearCurrentFilter = (e) => {
        if(payloadFor === 'teams') handleSelectedTeamsChange([], 'clear');
        setAppliedFilters([]);
        dispatchAvailableData({type: "handleClearOfType", payload: { payloadFor, payload: [] }});
        setTimeout(() => {
            handleClose();
            setSearchText('');
        }, 100);
    }

    const handleApplyFilter = () => {
        if(payloadFor === 'teams') handleSelectedTeamsChange(selectedOptions, 'apply')
        setAppliedFilters(selectedOptions);
        dispatchAvailableData({ type: "handleSelectedOfType", payload: {payloadFor, payload: selectedOptions}});
        setTimeout(() => {
            handleClose();
            setSearchText('');
        }, 100);
    }

    const handleFilterClick = (e) => {
        setDropdownAnchorEl(e.currentTarget || null);
        setIsDropdownVisible((prevState) => !prevState);
    }

    const handleClose = () => {
        setIsDropdownVisible(false);
        setDropdownAnchorEl(null);
    }

    function filterAutoClose(e) {
        const path = e.composedPath()?.map((pathItem) => {
          let newPathItem = null;
          newPathItem =
            pathItem?.tagName?.toLowerCase() +
            (pathItem?.className ? "." + pathItem?.className : "");
          return newPathItem;
        });
        // clicked outside
        if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
          // console.log("clicked outside")
          // a checked checkbox should remain chekd when reopend after unchecking and not clicking on Apply/ Clear
          setSelectedOptions(appliedFilters);
          setIsDropdownVisible(false);
          setDropdownAnchorEl(null);
          return;
        }
    }   
    
    useEffect(() => {
        if(!availableData || !availableData[payloadFor] || !availableData[payloadFor].all)
             return;
        setOptions([...availableData[payloadFor].all]);
        if(!availableData[payloadFor].selected) return;
        setSelectedOptions([...availableData[payloadFor].selected]);
        setAppliedFilters([...availableData[payloadFor].selected]);
    }, [availableData]);

    useEffect(() => {
        if (!searchText || searchText.trim().length < 1) {
            setOptionsToDisplay(options);
            return;
        }
        let searchedResult = options?.filter(({ name }) =>
            name?.toLowerCase().includes(searchText.toLowerCase())
        );
        setOptionsToDisplay(searchedResult);
      }, [searchText, options]);

    useEffect(() => {
        if(isDropdownVisible) window.addEventListener("click", filterAutoClose);
        else window.removeEventListener("click", filterAutoClose);
    
        return () => {
          window.removeEventListener("click", filterAutoClose);
        };
    }, [isDropdownVisible]);

    return (
        <>
        <div 
          className="filter gfilter-container" 
          onClick={(e) => handleFilterClick(e)}
          style={{
            backgroundColor: appliedFilters.length > 0 ? colors.ic_green_100 : colors.ic_white,
            border: `1px solid ${appliedFilters.length > 0 ? colors.ic_green_300 : colors.nc_gray_300}`,
            opacity: isDisabled ? 0.4 : 1,
            cursor: isDisabled ? 'not-allowed' : 'pointer'
          }}
        >
            <p className="b1 regular-font title-text">
                {title}
            </p>
            {appliedFilters.length > 0 && (
              <div className='badge-container'>
              {/* number of selected options in a filter */}
                <span className="b3 regular-font ic-white applied-filters-length">
                  {appliedFilters.length}
                </span>
              </div>
            )}
            <span>{dropdownIcon()}</span>
        </div>
            {!isDisabled && (
                <Menu
                    anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                    }}
                    transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                    }}
                    className="gfilter-dropdown-menu"
                    autoFocus={false}
                    open={isDropdownVisible}
                    anchorEl={dropdownAnchorEl}
                >
                    {isSearch && (
                        <div className='search-container'>
                        <div className="left-header-item search search-input-container">
                          <img
                            onClick={(e) =>
                              document.getElementById(`${title}-filter-search`)?.focus()
                            }
                            style={{ marginRight: "5px" }}
                            src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
                            alt="Search icon"
                          />
                          <input
                            id={`${title}-filter-search`}
                            className="t6 regular-font"
                            type="search"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <Box className='options-wrapper custom-scrollbar'>
                    {optionsToDisplay?.map(({ id, name }, index) => {
                        return (
                        <Box key={index} sx={{ maxHeight: "320px" }}>
                            <MenuItem
                                title={name}
                                onClick={(e) => handleToggleableItemClick(e, id, name)}
                            >
                                <Checkbox
                                disableRipple={true}
                                checkedIcon={
                                    <img
                                    src={checkboxCheckedIcon}
                                    alt="Checked checkbox "
                                    />
                                }
                                icon={
                                    <img src={checkboxIcon} alt="Unchecked checkbox " />
                                }
                                sx={{
                                    color: colors.nc_gray_400,
                                    marginRight: "4px",
                                    "&.Mui-checked": {
                                    color: colors.ic_green_300,
                                    },
                                }}
                                checked={selectedOptions?.includes(id)}
                                />
                                <span className='span-no-overflow'>
                                {name}
                                </span>
                            </MenuItem>
                        </Box>
                    );
                })}
                </Box>
                <div className="dashboard_date-menu-btn-container">
                    <Button
                        variant="outlined"
                        buttonText="Clear"
                        hierarchy="white"
                        disabled={false}
                        styleOverride={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        fontSize: "14px",
                        }}
                        onClick={handleClearCurrentFilter}
                    />
                    <Button
                        variant="outlined"
                        buttonText="Apply"
                        disabled={selectedOptions?.count < 1}
                        hierarchy="green"
                        styleOverride={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        fontSize: "14px",
                        }}
                        onClick={handleApplyFilter}
                    />
                </div>
               </Menu>
            )}
        </>
    )
}

export default GFilter;