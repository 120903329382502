import { useEffect, useState } from "react";
import { MenuItem, Menu } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import Button from "../design/Button";
import GModal from "../design/components/GModal"
import Audio from "./Audio";
import {CustomInput, CustomSelect} from '../design/components/customComponents';
import { JService } from "../api-service/ApiService";
import { threeDotsIcon, topNavAccountPlusIcon } from "../../utility-functions/assetLoader";

import styles from './SurveyModal.module.css';
import gstyles from '../design/styles';
import colors from '../design/colors';
import AnimationPage from "../shared/animation-page/AnimationPage";

const SurveyModal = ({visible, handleClose, mode, ...props}) => {
    const [showLoader, setShowLoader] = useState(false);
    const [isSavingSurvey, setIsSavingSurvey] = useState(false);
    const [campaignName, setCampaignName] = useState({value:'', error: null});
    const [responseTime, setResponseTime] = useState(5);
    const [quesRepeatCount, setQuesRepeatCount ] = useState(1);
    const [endCallAction, setEndCallAction] = useState(true);
    const [endCallAudio, setEndCallAudio] = useState({audio: null, audioURL: '', error: null});
    const [activeStep, setActiveStep] = useState({});
    const [quesTitle, setQuesTitle] = useState({value:'', error:null});
    const [stepsOptionsVisible, setStepOptionsVisible] = useState(false);
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
    const [stepOptionsId, setStepOptionsId] = useState(null);
    const [createDisabled, setCreateDisabled] = useState(mode === 'Create' ? true : false);
    const [surveyId, setSurveyId] = useState(props.id)
    const [stepBeingDragged, setStepBeingDragged] = useState(null);
    const [stepsData, setStepsData] = useState([]);
    const [introStep, setIntroStep] = useState({
            audioURL: '',
            audio: null,
            error: null,
            id: 'intro',
            index: 'intro'
        })
    const [thankYouStep, setThankYouStep] = useState({
            audioURL: '',
            audio: null,
            error: null,
            id: 'thank-you',
            index: 'thank-you'
        })
    const [tempSurveyData, setTempSurveyData] = useState({})

    const findActiveStepIdx = () => {
        return stepsData.findIndex(elem => elem.id === activeStep.id);
    }

    const handleUpdateDetails = (data, updated) => {
        const {title, response_time, repeat_question_option, end_action, end_action_recording, thank_you_recording, intro_recording, intro_recording_url, thank_you_recording_url, end_action_recording_url} = data;
            setCampaignName(prev => ({...prev, value: title}));
            setResponseTime(response_time);
            setQuesRepeatCount(repeat_question_option);
            console.log(end_action);
            setEndCallAction(end_action);
            if(end_action === 2){
                setEndCallAudio(prev => ({...prev, error: null, audio: null, audioURL: ''}));
            }
            else{
                setEndCallAudio(prev => ({...prev, audio: end_action_recording ? prev.audio : null, audioURL: end_action_recording_url, error: (end_action_recording || !updated) ? null : 'End call message is required, when end call with message is selected'}));
            }
            setIntroStep(prev => ({...prev, audio: intro_recording ? prev.audio : null, audioURL: intro_recording_url, error: (intro_recording || !updated) ? null : 'Introduction message is required'}));
            setThankYouStep(prev => ({...prev, audio: thank_you_recording ? prev.audio : null, audioURL: thank_you_recording_url, error: (thank_you_recording || !updated) ? null : 'Thank you message is required'}));
    }
    
    const fetchSurveyData = async() => {
        try{
            setShowLoader(true);
            const res = await JService.get(`/api/v1/core/ivr-survey/${props.id}/`);
            if(!res.success){
                throw new Error(res.message || 'Error while retrieving survey details');
            }
            setSurveyId(res.data.id)
            handleUpdateDetails(res.data, true);
            const {questions, questions_order} = res.data;
            let questionsOrder = {...questions_order};
            if(!Object.keys(questionsOrder).length){
                questions.forEach((ques, idx) => questionsOrder[idx + 1] = ques['id']);
            }
            let sortedQuesOrder = Object.keys(questionsOrder).sort((a, b) => Number(a) - Number(b));
            const quesData = sortedQuesOrder.map(quesKey => {
                const quesId = questionsOrder[quesKey];
                const ques = questions.find(elem => elem.id === quesId);
                return {
                    id: ques.id,
                    title: ques.title,
                    audio: ques.recording,
                    audioURL: ques.recording_url,
                    type: ques.question_type,
                    errorTitle: null,
                    errorRec: null
                }
            });
            setStepsData(quesData);
            setTempSurveyData({...res.data});
            setActiveStep(quesData[0]);
            setQuesTitle({value: quesData[0]?.title, error: null})
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while retrieving survey details', {variant:'error'});
            handleResetValues();
            handleClose();
        }
        finally{
            setShowLoader(false);
        }
    }
    const handleDeleteSurvey = async () => {
        try{
          const res = await JService.delete(`/api/v1/core/ivr-survey/${surveyId}/`);
          if(!res.success){
            throw new Error(res.message || 'Error while deleting survey');
          }
        }
        catch(err){
          enqueueSnackbar(err.message || 'Error while deleting survey', {variant:'error'});
        }
    }
    const handleCancelSurveyEdits = async () => {
        try{
            const url = `/api/v1/core/ivr-survey/${surveyId}/`;
            const payload = {
                ...tempSurveyData,
            }
            const res = await JService.patch(url, payload);
            if(!res.success){
                throw new Error(res.message || 'Error while cancelling survey editing, please try again');
            }
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while cancelling survey editing, please try again', {variant: 'error'})
        }
    }
    const handleResetValues = () => {
        setCampaignName({value: '', error: null});
        setActiveStep({audio: null, audioURL: '', error: '', id: '', index: ''});
        setEndCallAudio({audio: null, audioURL: '', error: ''});
        setThankYouStep({audio: null, audioURL: '', error: '', id: 'thank-you', index: 'thank-you'});
        setIntroStep({audio: null, audioURL: '', error: '', id: 'intro', index: 'intro'});
        setResponseTime(5);
        setQuesRepeatCount(1);
        setEndCallAction(1);
        setStepsData([]);
        setSurveyId(null);
        setStepOptionsId(null);
        setStepBeingDragged(null);
        setTempSurveyData({});
        setQuesTitle({value: '', error: null});
    }
    const handleCancel = async () => {
        if(mode === 'Create'){
            await handleDeleteSurvey();
        }
        else{
            await handleCancelSurveyEdits();
        }
        handleResetValues();
        handleClose();
    }
    const handleUpdateRecordings = async () => {
        try{
            const payload = {}
            if(introStep.audioURL !== tempSurveyData['intro_recording_url']) payload['intro_recording'] = null;
            if(thankYouStep.audioURL !== tempSurveyData['thank_you_recording_url']) payload['thank_you_recording'] = null;
            if(endCallAudio.audioURL !== tempSurveyData['end_action_recording_url']) payload['end_action_recording'] = null;
            const url = `/api/v1/core/ivr-survey/${surveyId}/`;
            if(Object.keys(payload)?.length){
                const res = await JService.patch(url, payload);
                if(!res.success){
                    console.error('Error while deleting old recordings: ', res.message);
                    throw new Error(res.message || 'Error while updating recordings');
                }
                const res2 = await Promise.all([
                    ...('intro_recording' in payload ? [handleUpdateSurveyDetails('intro_recording', introStep.audio, setIntroStep)]: []),
                    ...('thank_you_recording' in payload ? [handleUpdateSurveyDetails('thank_you_recording', thankYouStep.audio, setThankYouStep)]: []),
                    ...('end_action_recording' in payload ? [handleUpdateSurveyDetails('end_action_recording', endCallAudio.audio, setEndCallAudio)]: []),
                ])
                console.log(res2);
                if(false in res2){
                    console.error('Error while uploading new recordings: ', res.message);
                    throw new Error(res.message || 'Error while updating recordings');
                }
            }
            return true;
        }
        catch(err){
            console.error(err);
            enqueueSnackbar(err.message || 'Error while updating recordings', {variant: 'error'});
            return false;
        }
    }
    const handleCreateSurvey = async() => {
        try{
            const res = await JService.post(`/api/v1/core/create-ivr-survey/`, {})
            if(!res.success){
                throw new Error(res.message || 'Error while creating survey');
            }
            setSurveyId(res.data.id);
            handleUpdateDetails(res.data, false);
            return res.data.id;
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while creating survey', {variant:'error'});
            handleResetValues();
            handleClose();
            setShowLoader(false);
            return null;
        }
    }
    const handleSave = async() => {
        try{
            setShowLoader(true);
            setIsSavingSurvey(true);
            const payload = {}
            stepsData.forEach((step, idx) => payload[idx] = step.id);
            const res = await JService.post(`/api/v1/core/ivr-question-connection/`, payload)
            if(!res.success){
                throw new Error(res.message || 'Error while updating survey details');
            }
            let recordingsUpdated = false;
            if(mode === 'Edit'){
                recordingsUpdated = await handleUpdateRecordings();
            }
            if(recordingsUpdated || mode !== 'Edit'){
                handleResetValues();
                handleClose();
            }
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while updating survey details', {variant:'error'});
        }
        finally{
            setShowLoader(false);
            setIsSavingSurvey(false);
        }
    }
    const handleAddStep = async (id, firstStep = false) => {
        if(mode !== 'Create') return;
        try{
            const _surveyId = !isNaN(id) ? id : surveyId;
            if(stepsData.length >= 3){
                enqueueSnackbar('Cannot create more than 3 questions for a survey', {variant:'error'});
                return;
            }
            console.log({survey: _surveyId})
            const res = await JService.post(`/api/v1/core/create-ivr-survey-question/`, {survey: _surveyId})
            if(!res.success){
                throw new Error(res.message || 'Error while creating question');
            }
            const newStep = {id: res.data.id, type: res.data.question_type, title: res.data.title, audio: res.data.recording, audioURL: res.data.recording_url, errorTitle: null, errorRec: null}
            setStepsData(prev => {
                let temp = [...prev];
                temp.push(newStep);
                return temp;
            })
            if(firstStep) setActiveStep(newStep);
            return newStep;
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while creating question', {variant:'error'});
        }   
    }
    const handleDuplicateStep = async () => {
        threeDotsAutoClose();
        if(mode !== 'Create') return;
        try{
            if(stepsData.length >= 3){
                enqueueSnackbar('Cannot create more than 3 questions for a survey', {variant:'error'});
                return;
            }
            const stepIdx = stepsData.findIndex(elem => elem.id === stepOptionsId);
            const step = stepsData[stepIdx];
            const payload = new FormData();
            payload.append('question_type', step.type);
            if(step.title && step.title.trim().length){
                payload.append('title', step.title);
            }
            if(step.audio){
                payload.append('audio_file', step.audio);
            }
            payload.append('survey', surveyId);

            const res = await JService.post(`/api/v1/core/create-ivr-survey-question/`, payload)
            if(!res.success){
                throw new Error(res.message || 'Error while duplicating question');
            }
            setStepsData(prev => {
                let temp = [...prev];
                temp.splice(stepIdx + 1, 0, {id: res.data.id, type: res.data.question_type, title: res.data.title, audio: step.audio, audioURL: step.audioURL, errorTitle: null, errorRec: null})
                return temp;
            })
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while duplicating question', {variant:'error'});
        }
        finally{
            setStepOptionsId(null);
        }
    }
    const handleDeleteStep = async () => {
        threeDotsAutoClose();
        if(mode !== 'Create') return;
        try{
            if(stepsData.length <= 1){
                enqueueSnackbar('A survey must have minimum 1 question', {variant: 'error'});
                return;
            }
            const res = await JService.delete(`/api/v1/core/ivr-survey-question/${stepOptionsId}/`)
            if(!res.success){
                throw new Error(res.message || 'Error while deleting question');
            }
            setStepsData(prev => {
                let temp = [...prev];
                temp = temp.filter(step => step.id !== stepOptionsId)
                return temp;
            });
            if(activeStep.id === stepOptionsId)
                setActiveStep(stepsData[0]);
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while deleting question', {variant:'error'});
        }
        finally{
            setStepOptionsId(null);
        }
    }

    const handleUpdateSurveyDetails = async (field, value, setterFn) => {
        try{
            const recFields = ['intro_recording', 'thank_you_recording', 'end_action_recording'];
            let payload;
            if(recFields.includes(field)){
                if(value){
                    payload = new FormData();
                    payload.append('recording_type', field);
                    payload.append('audio_file', value);
                }
                else{
                    payload = {
                        [field]: null
                    }
                }
            }
            else if(field === 'title' && (!campaignName.value || !campaignName.value.trim().length)){
                setCampaignName(prev => ({...prev, error: 'Campaign name is required'}));
                return;
            }
            else{
                if(setterFn){
                    if(field === 'title')
                        setterFn({value: value, error: null});
                    else
                        setterFn(value)
                }
                payload = {
                    [field]: value
                }
                if(field === 'end_action' && value === 2){
                    if(endCallAudio.audioURL){
                        setEndCallAudio(prev => ({...prev, audio: null, audioURL: '', error: null}));
                        payload['end_action_recording'] = null;
                    }
                    
                }
            }
            const res = await JService.patch(`/api/v1/core/ivr-survey/${surveyId}/`, payload);
            if(!res.success){
                //TODO: get survey details and update states
                throw new Error(res.message || 'Error while updating survey details');
            }
            if(recFields.includes(field)){
                setterFn(prev => ({...prev, audio: res.data[field], audioURL: res.data[`${field}_url`], error: res.data[`${field}_url`] ? null : prev.error}))
            }
            else{
                if(field === 'title')
                    setCampaignName({value: res.data.title, error: null});
                else
                    setterFn(res.data[field]);
            }
            return true;
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while updating survey details', {variant: 'error'})
            return false;
        }
    }
    const handleUpdateQuesDetails = async (field, value) => {
        try{
            const id = activeStep.id
            if((field === 'title' && (!value || !value.trim().length)) || (field === 'recording' && !value)){
                setStepsData(prev => {
                    let temp = [...prev];
                    temp = temp.map(step => {
                        if(step.id !== id){
                            return step;
                        }
                        else{
                            if(field === 'title'){
                                return {
                                    ...step,
                                    title: '',
                                    errorTitle: true
                                }
                            }
                            else{
                                return {
                                    ...step,
                                    audio: null,
                                    audioURL: '',
                                    errorRec: true 
                                }
                            }
                        }
                    })
                    return temp;
                })
                if(field === 'title') return;
            }
            let payload;
            if(field === 'recording'){
                if(value){
                    payload = new FormData();
                    payload.append("audio_file", value)
                }
                else{
                    payload = {
                        'recording': null
                    }
                }
            }
            else{
                payload = {
                    [field]: value
                }
            }
            const res = await JService.patch(`/api/v1/core/ivr-survey-question/${id}/`, payload);
            if(!res.success){
                throw new Error(res.message || 'Error while updating question details');
            }
            setStepsData(prev => {
                let temp = [...prev];
                temp = temp.map(step => {
                    if(step.id === id){
                        const {title, question_type, recording, recording_url} = res.data;
                        return {...step, type: question_type, title: title, audioURL: recording_url, errorTitle: !title && field === 'title' ? true : prev.errorTitle, errorRec: !recording_url && field === 'recording' ? true : prev.errorRec }
                    }
                    else return step;
                });
                return temp;
            });
        }
        catch(err){
            enqueueSnackbar(err.message || 'Error while updating question details', {variant: 'error'});
        }
    }
    const handleSelectStep = (id, idx) => {
        if(activeStep.id === 'intro'){
            setIntroStep(prev => ({...prev, error: prev.audioURL ? '' : 'Introduction message is required'}));
        }
        else if (activeStep.id === 'thank-you'){
            setThankYouStep(prev => ({...prev, error: prev.audioURL ? '' : 'Thank you message is required'}));
        }
        else {
            setStepsData(prev => {
                const temp = [...prev];
                const curIdx = temp.findIndex(elem => elem.id === activeStep.id)
                temp[curIdx]['errorTitle'] = !temp?.[curIdx]?.['title']?.trim()?.length;
                temp[curIdx]['errorRec'] =  !temp?.[curIdx]?.audioURL;
                return temp;
            });
        }

        if(id === 'intro'){
            setActiveStep({...introStep, index: id});
        }
        else if(id === 'thank-you'){
            setActiveStep({...thankYouStep, index: id});
        }
        else{
            const step = stepsData.find(elem => elem.id === id)
            setActiveStep(step);
            setQuesTitle({value: step.title, error: step.errorTitle ? 'Question title is required' : null})
        }
    }
    const handleDragStart = (e, item) => {
        setStepBeingDragged(item);
    };

    const handleDragEnd = () => {
        setStepBeingDragged(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetStep) => {
        if (!stepBeingDragged) return;
        console.log(stepBeingDragged, targetStep);

        const currentIndex = stepsData.findIndex(step => step.id === stepBeingDragged.id);
        const targetIndex = stepsData.findIndex(step => step.id === targetStep.id);
        console.log(currentIndex, targetIndex);
        if (currentIndex !== -1 && targetIndex !== -1) {
            setStepsData(prev => {
                let temp = [...prev];
                temp.splice(currentIndex, 1);
                temp.splice(targetIndex, 0, stepBeingDragged);
                return temp;
            })
        }
    };
    const handleSetFile = (id, file) => {
        if(id === 'intro'){
            setIntroStep(prev => ({...prev, audio: file, updated: true}));
            if(mode === 'Create')
                handleUpdateSurveyDetails('intro_recording', file, setIntroStep);
        }
        else if(id === 'thank-you'){
            setThankYouStep(prev => ({...prev, audio: file, updated: true}));
            if(mode === 'Create')
                handleUpdateSurveyDetails('thank_you_recording', file, setThankYouStep);
        }
        else if (id === 'end_action_recording'){
            setEndCallAudio(prev => ({...prev, audio: file, updated: true}));
            if(mode === 'Create')
                handleUpdateSurveyDetails('end_action_recording', file, setEndCallAudio);
        }
        else{
            setStepsData(prev => {
                let temp = [...prev];
                temp = temp.map((step) => {
                    if(step.id !== id)
                        return step;
                    else 
                        return {
                            ...step,
                            audio: file,
                        }
                })
                return temp;
            });
            handleUpdateQuesDetails('recording', file);
        }
    }
    const handleSetAudioURL = (id, url) => {
        if(id === 'intro'){
            setIntroStep(prev => ({...prev, audioURL: url, error: url ? null : 'Introduction message is required'}));
        }
        else if(id === 'thank-you'){
            setThankYouStep(prev => ({...prev, audioURL: url, error: url ? null : 'Thank you message is required'}));
        }
        else if(id === 'end_action_recording'){
            setEndCallAudio(prev => ({...prev, audioURL: url, error: url ? null : 'End call message is required'}));
        }
        else{
            setStepsData(prev => {
                let temp = [...prev];
                temp = temp.map((step) => {
                    if(step.id !== id)
                        return step;
                    else 
                        return {
                            ...step,
                            audioURL: url,
                            errorRec: url ? null : 'Question recording is required'
                        }
                })
                return temp;
            });
        }
    }
    const handleDeleteAudio = (id) => {
        if(id === 'intro'){
            setIntroStep(prev => ({...prev, audio: null, audioURL: '', updated: true, error: 'Introduction message is required'}));
            if(mode === 'Create')
                handleUpdateSurveyDetails('intro_recording', null, setIntroStep);
        }
        else if(id === 'thank-you'){
            setThankYouStep(prev => ({...prev, audio: null, audioURL: '', updated: true, error: 'Thank you message is required'}));
            if(mode === 'Create')
                handleUpdateSurveyDetails('thank_you_recording', null, setThankYouStep);
        }
        else if(id === 'end_action_recording'){
            setEndCallAudio(prev => ({...prev, audio: null, audioURL: '', updated: true, error: 'End call message is required, when end call with message is selected'}))
            if(mode === 'Create')
                handleUpdateSurveyDetails('end_action_recording', null, setEndCallAudio);
        }
        else{
            handleUpdateQuesDetails('recording', null);
        }
    }
    const handleDisplayStepOptions = (e, id) => {
        e.stopPropagation();
        setStepOptionsVisible(true);
        setDropdownAnchorEl(e.target);
        setStepOptionsId(id)
    }
    const threeDotsAutoClose = () => {
        setStepOptionsVisible(false);
        setTimeout(() => setDropdownAnchorEl(null), 500);
    }
    const handleChangeQuestionTitle = (value) => {
        setStepsData(prev => {
            let temp = [...prev];
            temp = temp.map(step => {
                if(step.id !== activeStep.id)
                    return step;
                else
                    return {
                        ...step,
                        title: value,
                        errorTitle: value ? null : true
                    }
            });
            return temp;
            });
            setQuesTitle(prev => ({error: value ? null : 'Question title is required', value: value}))
    }
    const handleCampaignNameChange = (e) => {
        setCampaignName({value: e.target.value, error: null})
    }
    useEffect(()=>{
        if(!visible) return;
        if(mode === 'Edit' && !props?.id) return;
        (async () => {
            setShowLoader(true);
            if(mode === 'Edit'){
                await fetchSurveyData();
            }
            else if(mode === 'Create' && !surveyId){
                const id = await handleCreateSurvey();
                if(id) await handleAddStep(id, true);
            }
            setShowLoader(false);
        })();
    },[mode, visible, props?.id]);

    useEffect(()=>{
        const col1Filled = campaignName.value && campaignName.value.trim().length;
        const surveyRecordings = (endCallAction === 1 ? endCallAudio.audioURL : true) && introStep.audioURL && thankYouStep.audioURL;
        const stepsCompleted = !(stepsData.some(data => !data.title?.trim() || !data.audioURL))
        if(col1Filled && stepsCompleted && surveyRecordings){
            if(createDisabled) setCreateDisabled(false);
        }
        else{
            if(!createDisabled) setCreateDisabled(true);
        }
    },[campaignName, endCallAudio, endCallAction, stepsData, introStep, thankYouStep]);

    useEffect(() => {
        if(activeStep?.id === 'intro'){
            setActiveStep({...introStep, id: 'intro', index: 'intro'})
        }
        else if(activeStep?.id === 'thank-you'){
            setActiveStep({...thankYouStep, id: 'thank-you', index: 'thank-you'})
        }
        else{
            const stepIdx = stepsData.findIndex(step => step.id === activeStep.id);
            if(stepIdx !== -1) 
                setActiveStep({...stepsData[stepIdx], index: stepIdx});
        }
    },[introStep, thankYouStep, stepsData]);

    useEffect(() => {
        if (stepsOptionsVisible) window.addEventListener("click", threeDotsAutoClose);
        else window.removeEventListener("click", threeDotsAutoClose);
    
        return () => {
          window.removeEventListener("click", threeDotsAutoClose);
        };
      }, [stepsOptionsVisible]);

    return (
        <GModal 
            visible={visible} 
            closeModal={handleCancel} 
            heading={mode ==='Create' ? 'Create survey' : 'Post call survey'}
            bottomButtons={[
                <Button variant='outlined' hierarchy='white' buttonText='Cancel' onClick={handleCancel} disabled={false} isLoading={false}/>,
                <Button variant='contained' hierarchy='green' buttonText={mode === 'Create' ? 'Create' : 'Save'} onClick={handleSave} isLoading={false} disabled={createDisabled || isSavingSurvey}/>
            ]}
            modalStyle={{width:'962px', padding:'0', marginBottom:0}}
            body={
                (showLoader || stepsData.length <= 0 || Object.keys(activeStep).length === 0) ? <AnimationPage/> : (
                <div className={styles.container}>
                    <div className={styles.column}>
                        <form>
                            <CustomInput label='Campaign name' type='text' value={campaignName} handleChange={handleCampaignNameChange} placeholder='Enter campaign name' handleFocus={()=>null} handleBlur={(e) => handleUpdateSurveyDetails('title', e.target.value, setCampaignName)}/>
                            <hr/>
                            <CustomSelect 
                                label='Max response time per question' 
                                value={responseTime} 
                                handleChange={(e) => handleUpdateSurveyDetails('response_time', e.target.value, setResponseTime)} 
                                options={[{value: 5, displayValue: '5 secs'}, {value: 10, displayValue: '10 secs'}]
                                }
                                renderVal={(selected) => <p className="t7 regular-font">{selected} secs</p>}
                                labelStyles={{marginTop:'20px', display:'block'}}
                            />
                            <CustomSelect 
                                label='If invalid response' 
                                value={quesRepeatCount} 
                                handleChange={(e) => handleUpdateSurveyDetails('repeat_question_option', e.target.value, setQuesRepeatCount)} 
                                options={[{value: 1, displayValue: 'Repeat question 1 time'}, {value: 2, displayValue: 'Repeat question 2 times'}, {value: 3, displayValue: 'Repeat question 3 times'}]}
                                renderVal={(selected) => <p className="t7 regular-font">Repeat question {selected} time{selected == 1 ? '': 's'}</p>}
                                labelStyles={{marginTop:'20px', display:'block'}}
                            />
                            <CustomSelect 
                                label='If no valid response after repeats' 
                                value={endCallAction} 
                                handleChange={(e) => handleUpdateSurveyDetails('end_action', e.target.value, setEndCallAction)} 
                                options={[{value: 1, displayValue: 'End call with message'}, {value: 2, displayValue: 'End call'}]}
                                renderVal={(selected) => <p className="t7 regular-font">End call {selected === 1 ? 'with message': ''}</p>}
                                labelStyles={{marginTop:'20px', display:'block'}}
                            />
                            {endCallAction === 1 && (
                                <Audio label='' file={endCallAudio.audio} setFile={handleSetFile} setAudioURL={handleSetAudioURL} id='end_action_recording' audioURL={endCallAudio.audioURL} handleDeleteAudio={handleDeleteAudio} error={endCallAudio.error} inputStyle={{top: '-10px'}}/>
                            )}
                        </form>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.stepContainer}>
                            <div onClick={()=>handleSelectStep('intro')} className={`${styles.step} ${activeStep.id == 'intro' ? styles.activeStep : ''} ${introStep.error ? styles.stepError: ''}`}>
                                <p className="t7 regular-font">Introduction</p>
                            </div>
                            <div className={styles.verticalLineLong}></div>
                        </div>
                        {
                              stepsData?.map((step, idx) => {
                                return (
                                    <div key={step.id} className={styles.stepContainer} draggable={true} onDragStart={(e) => handleDragStart(e, step)} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, step)}>
                                        <div onClick={()=>handleSelectStep(step.id, idx)} className={`${styles.step} ${activeStep.id == step.id ? styles.activeStep : ''} ${(step.errorTitle || step.errorRec) ? styles.stepError: ''}`}>
                                            <p className="t7 regular-font">{`Question - ${idx + 1}`}</p>
                                            {mode === 'Create' && <div onClick={(e) => handleDisplayStepOptions(e, step.id)} className={styles.threeDotsIcon}>
                                                <div>{threeDotsIcon()}</div>
                                            </div>}
                                        </div>
                                        {<div className={styles.verticalLine}></div>}
                                    </div>
                                )
                            })
                        }
                        {
                            <div className={styles.stepContainer}>
                                {mode === 'Create' && stepsData?.length < 3 && <div className={styles.addStepBtn} onClick={handleAddStep}>{topNavAccountPlusIcon("#2eb258", 20, 20)}</div>}
                                <div className={styles.verticalLineLong}></div>
                            </div>
                        }
                        <div className={styles.stepContainer}>
                            <div onClick={()=>handleSelectStep('thank-you')} className={`${styles.step} ${activeStep.id == 'thank-you' ? styles.activeStep : ''} ${thankYouStep.error ? styles.stepError: ''}`}>
                                <p className="t7 regular-font">Thank you</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <h2 className="t7 medium-font">{activeStep.id === 'intro' ? 'INTRODUCTION' : activeStep.id === 'thank-you' ? 'THANK YOU' : `QUESTION - ${findActiveStepIdx() + 1}`}</h2>
                        {!['intro', 'thank-you'].includes(activeStep.id) && (
                           <>
                           <CustomSelect 
                            label='Question type' 
                            value={activeStep.type} 
                            handleChange={(e)=>handleUpdateQuesDetails('question_type', e.target.value)} 
                            options={[{value: 'Rating', displayValue: 'Rating'}, {value: 'Yes/No', displayValue: 'Yes/No'}]}
                            disabled={mode === 'Edit'}
                            renderVal={(selected) => <p className="t7 regular-font">{selected}</p>}
                            labelStyles={{marginTop:'20px', display:'block'}}
                          /> 
                          <div className={styles.quesDescContainer}>
                            {activeStep.type === 'Rating' && <p className="t7 regular-font">1 to 5</p>}
                            {activeStep.type === 'Yes/No' && (
                                <>
                                    <span className="t7 medium-font">Keypress</span><span className="t7 medium-font">Answer</span>
                                    <hr/>
                                    <div className={`${styles.keyContainer} t7 regular-font`}>
                                        <div className={styles.key}>1</div>
                                        <p>Yes</p>
                                    </div>
                                    <div className={`${styles.keyContainer} t7 regular-font`}>
                                        <div className={styles.key}>2</div>
                                        <p>No</p>
                                    </div>
                                </> 
                            )}
                          </div>
                          <CustomInput label='Question title' type='text' value={quesTitle} handleChange={(e)=>handleChangeQuestionTitle(e.target.value)} placeholder='Enter title' handleFocus={()=>null} handleBlur={() => handleUpdateQuesDetails('title', quesTitle.value)}/>
                          </>
                        )
                        }
                        <>
                           {/* {!stepsData[activeStep.id].audio && stepsData[activeStep.id].error && <p className={`t7 regular-font ${styles.errMessage}`}>Select {[0, 99999].includes(activeStep.id) ? `${activeStep.stepName} message`:'Question recording'}</p>} */}
                           <Audio label={['intro', 'thank-you'].includes(activeStep.id) ? `${activeStep.id === 'intro' ? 'Introduction' : 'Thank you' } message`:'Question recording'} mode={mode} file={activeStep?.audio} setFile={handleSetFile} setAudioURL={handleSetAudioURL} id={activeStep.id} audioURL={activeStep?.audioURL} handleDeleteAudio={handleDeleteAudio} error={activeStep.errorRec ? 'Question recording is required' : activeStep.error ? activeStep.error : ''}/>
                        </>
                    </div>
                    <Menu
                        disableScrollLock={true}
                        open={stepsOptionsVisible}
                        anchorEl={dropdownAnchorEl}
                        sx={localStyles.menu}
                    >
                        <MenuItem
                        onClick={handleDuplicateStep}
                        sx={localStyles.menuItem}
                        disabled={activeStep.errorTitle || stepsData.length >= 3}
                        >
                        Duplicate
                        </MenuItem>

                        <MenuItem
                        onClick={handleDeleteStep}
                        sx={localStyles.menuItem}
                        >
                        Delete
                        </MenuItem>
                    </Menu>
                </div>
                )
            }
        />
    )
}

export default SurveyModal;

const localStyles = {
    menu: {
      "& .MuiPaper-root": {
       boxShadow: 'none',
       border: `1px solid ${colors.nc_gray_300}`,
      },
      "& .MuiPaper-root .MuiList-root.MuiMenu-list":{
        padding: 0
      }
    },
    menuItem: {
      cursor: "pointer",
      padding: '12px 20px', 
      ...gstyles.t7, 
      ...gstyles.regular_font, 
      color: colors.nc_gray_900 
    }
  }