import { Box } from "@mui/material";
import colors from "../../../design/colors";
import { backIcon } from "../../../../utility-functions/assetLoader";

const Notes = ({notes, handleChange, handleBack}) => {
    return (
    <Box sx={localStyles.notesOuterContainer}>
        <Box sx={localStyles.notesInnerContainer}>
          <div
            title="Enter notes in the text area below and click here to proceed"
            onClick={handleBack}
            className="notes--backicon"
          >
            {backIcon()}
          </div>
        </Box>
        <Box sx={localStyles.textareaContainer}>
          <textarea
            onChange={handleChange}
            value={notes}
            placeholder="Enter notes"
            className='notes--textarea'
          ></textarea>
        </Box>
    </Box>
    )
}

const localStyles = {
    notesOuterContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        height: "382px",
    },
    notesInnerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "16px",
        height: "50px",
        borderRadius: "6px 6px 0px 0px",
        borderBottom: `1px solid ${colors.nc_gray_300}`,
    },
    textareaContainer: {
        padding: "16px",
        flex: 1
    }
}

export default Notes;