import { createPortal } from "react-dom"
import GModal from "../design/components/GModal"
import styles from './PlanModal1.module.css'
import { useEffect, useState } from "react"
import Button from "../design/Button";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import PlanModal2 from './PlanModal2'
import { JService } from "../api-service/ApiService";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from 'notistack';
import { getOrgURLParam } from "../../utility-functions";

const featuresList = [
    {
      name:'1 User',
      isPro:false,
    },
    {
      name: "1 Virtual number",
      isPro: false,
      
    },
    {
      name: `$5 Credits`,
      isPro: false,
      displayFor:'Global'
    },
    {
      name:'Unlimited local calls',
      isPro:false,
      displayFor:'India'
    },
    {
      name: "Call logs & reports",
      isPro: false,
    },

    { name: "Call recording",  isPro: false },
    {
      name: "Click to call extension",
      isPro: false,
      
    },
    { name: "Custom reports",  isPro: true },

   
    {
      name: "Incoming call routing",
      isPro: true,
      
    },
  ];


const PlanModal1=({visible, handleClose})=>{
    const options=['Monthly','Quarterly','Annual']

    const reduxAccount = useSelector((state) => state?.account?.value);

    const [billingCycle,setBillingCycle]=useState('Monthly')
    const [planModal2Visible,setPlanModal2Visible]=useState(false)
    const [selectedPlan,setSelectedPlan]=useState(null)
    const [showLoader,setShowLoader]=useState(false)
    const [plansData,setPlansData]=useState([])
    const [plansCurrency,setPlansCurrency]=useState()

    const fetchPlans=()=>{
      setShowLoader(true)
      JService.get(`/api/v1/subscriptions/plans/${getOrgURLParam()}`)
      .then((res)=>{
        if(!res?.success) throw new Error(res?.message || 'Could not retrieve plans')
        setPlansData(res?.data)
        setPlansCurrency(res?.data[0]?.currency)
      })
      .catch(err=>{
		enqueueSnackbar(err?.message || 'Could not retrieve plans', { variant: "error"})
      })
      .finally(()=>{
        setShowLoader(false)
      })
    }

    useEffect(()=>{
      if(!visible) return;
      fetchPlans()
    },[visible])

    const handleOpenModal2=(type)=>{
        setPlanModal2Visible(true)
        for(let i=0;i<plansData.length;i++){
          if(plansData[i]?.name?.split('-')[0]?.toUpperCase()===type && plansData[i].billing_cycle===billingCycle?.toUpperCase()){
            setSelectedPlan(plansData[i]?.name)
            return
          }
        }
       
    }

  const handlePlanModal2Close=()=>{
    setPlanModal2Visible(false)
    handleClose()
  }

    const calcPrice=(planType)=>{
      if(!plansData || plansData.length===0) return
      let price=0
      for(let i=0;i<plansData.length;i++){
        if(plansData[i]?.name?.split('-')[0]===planType && plansData[i].billing_cycle===billingCycle?.toUpperCase()){
          let priceToDisplay=plansData[i].discounted_price
          if(billingCycle==='Quarterly'){
            priceToDisplay/=3
            //priceToDisplay-=0.06*parseInt(priceToDisplay,10)
          }
          else if(billingCycle==='Annual'){
            priceToDisplay/=12
            //priceToDisplay-=0.15*parseInt(priceToDisplay,10)
          }
          price=`${plansData[i]?.currency==='INR'?'₹':'$'}${Math.round(priceToDisplay)}`
          return price
        }
      }
      return ''
    }

    const findDiscount=(billingCycle)=>{
      if(!plansData || plansData.length===0) return
      const plan=plansData?.find((plan)=>plan?.billing_cycle===billingCycle)
      return plan?.discount;
    }

    if(planModal2Visible){
        return <PlanModal2 type={selectedPlan} billingCycle={billingCycle} visible={planModal2Visible} handleBack={()=>setPlanModal2Visible(false)} handleClose={handlePlanModal2Close}/>
    }
    
    return (
        <GModal 
            heading='Upgrade plan'
            visible={visible}
            closeModal={handleClose}
            bottomButtons={[]}
            bodyWrapperStyle={{paddingRight:'0'}}
            modalStyle={{padding:'40px',marginBottom:'40px',maxHeight:'80vh'}}
            body={
            showLoader ? <AnimationPage/>: plansData && plansData.length>0 ?
            <div style={{display:'flex',flexDirection:'column',alignItems:"center",}}>
                <div className={styles.slider_wrapper}>
                {options.map((option, index) => (
                  <div
                    onClick={() => setBillingCycle(option)}
                    key={index}
                    className={`${styles.option} ${
                      option === billingCycle ? styles.selected : null
                    } medium-font t7`}
                  >
                    {option}
                  </div>
                ))}
              </div>
              {plansData && plansData?.length>0 && ('discount' in plansData[0]) && <p className="t7 regular-font">Save {findDiscount('QUARTERLY')}% on Quarterly and {findDiscount('ANNUAL')}% on Annual billing</p>}
              <div className={styles.priceCardContainer}>
              <PriceCard type="STANDARD" plansCurrency={plansCurrency} price={calcPrice('Standard')} handleOpenModal2={handleOpenModal2}/>
              <PriceCard type="PROFESSIONAL" plansCurrency={plansCurrency} price={calcPrice('Professional')} handleOpenModal2={handleOpenModal2}/>
              </div>
            </div>
            :null
            }
        />
    )
}

const PriceCard=({type,price,handleOpenModal2,plansCurrency})=>{
    return (
        <div className={styles.price_card}>
        <h2>{type}</h2>

        <h1>{price}</h1>
        <p style={{ marginTop: "12px" }} className="t7 regular-font">Per license/month</p>
        <Button buttonText='Upgrade' onClick={()=>handleOpenModal2(type)} hierarchy="green" disabled={false} styleOverride={{marginTop:'24px',height:'40px',width:'200px',fontWeight:'500'}}/>
        
        <div className={styles.seperator} />

        {featuresList.map((feature, index) => {
        if((feature.displayFor==='Global' && plansCurrency==='INR') || (feature.displayFor==='India' && plansCurrency==='USD')){
          return '';
        }
        return (
            <div key={index} className={styles.feature_list_item}>
            {feature.isPro && type === "STANDARD"
                ? topNavAccountSelectedAccountTickIcon('#e1e5e2')
                : topNavAccountSelectedAccountTickIcon('#2eb258')}
            <p
                style={{
                marginLeft: "12px",
                color:
                    feature.isPro && type === "STANDARD" ? "#C6ccc8" : "#464d48",
                }}
                className="t7 regular-font"
            >
                {feature.name}
            </p>
            </div>
        );
        })}
    </div>
    )
}

export default PlanModal1
