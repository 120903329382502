import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { formatFileSize } from "../../../utility-functions";
import GModal from "../../design/components/GModal";
import CloseIcon from "@mui/icons-material/Close";
import campaignSampleFile from "../../../assets/campaign_sample.csv";
import baseFileIcon from "../../../assets/base file icon.svg";
import Button from "../../design/Button";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { CCDropdown, CustomInput } from "../../design/components/customComponents";


function AddContactModal({
  visible,
  hideModal,
  listId,
  triggerReload,
}) {
  const [type, setType] = useState("list");
  const [newListFile, setNewListFile] = useState([]);
  const [newContactName, setNewContactName] = useState("");
  const [newContactNumber, setnewContactNumber] = useState({value: '', error: '' });
  const [countryCode, setCountryCode] = useState({value: '+91', error: ''});

  const closeModal = () => {
    setNewListFile([]);
    setNewContactName("");
    setnewContactNumber({value: '', error: null});
    setCountryCode({value: "+91", error: null});
    hideModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let url, payload;
    if (type === "list") {
      url = `/api/v1/core/upload-contacts/${listId}/`;
      payload = new FormData();
      payload.append("file", newListFile[0]);
    } else if (type === "single") {
      url = `/api/v1/core/create-contact/${listId}/`;
      payload = {
        name: newContactName,
        // country_code: "+91",
        country_code: countryCode.value,
        number: newContactNumber.value,
      };
    }

    JService.post(url, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Error while creating contact list")
		enqueueSnackbar(res?.message, { variant: "success"})
		triggerReload();
		closeModal();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  };

  return (
    <GModal
      closeModal={closeModal}
      bodyWrapperStyle={{overflowY: 'visible'}}
      heading={"Add Contacts"}
      visible={visible}
      bottomButtons={[
        <Button
		  className="ui--button ui--cancel"
          variant="filled"
          buttonText="Cancel"
          icon={{}}
          disabled={false}
          isLoading={false}
          hierarchy="white"
          onClick={closeModal}
        />,
        <Button
		  className="ui--button ui--add"
          variant="filled"
          buttonText="Add"
          icon={{}}
          disabled={type==="list" ?  newListFile.length === 0 : newContactName.length===0 || newContactNumber.value.length===0}
          isLoading={false}
          hierarchy="green"
          onClick={(e) => handleSubmit(e)}
        />,
      ]}
      body={
        <div>
          <FormControl sx={{ marginBottom: "40px" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="list"
              name="radio-buttons-group"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value="list"
                  control={<Radio sx={localStyles.radioStyle} />}
                  label="Upload contact list"
                />
                <FormControlLabel
                  value="single"
                  control={<Radio sx={localStyles.radioStyle} />}
                  label="Add individually"
                />
              </div>
            </RadioGroup>
          </FormControl>

          <form className="new-contact-list-form add-contact-form">
            {type === "single" && (
              <>
                <label
                  className="t7 medium-font"
                  style={{ marginBottom: "32px" }}
                >
                  Name
                  <input
                    placeholder="Enter Contact Name"
                    className="t7 ui--input ui--name"
                    type="text"
                    onChange={(e) => setNewContactName(e.target.value)}
                    value={newContactName}
                  />
                </label>
                {/* <label
                  className="t7 medium-font"
                  style={{ marginBottom: "32px" }}
                >
                  Phone number
                  <input
                    placeholder="Enter Phone Number"
                    className="t7 ui--input ui--phone"
                    type="text"
                    maxLength={10}
                    onChange={(e) => {
                      setnewContactNumber(e.target.value);
                      setFormListener((prevState) => prevState + 1);
                    }}
                    value={newContactNumber}
                  />
                </label> */}
                <CustomInput dropdown={<CCDropdown selectedCountryCode={countryCode?.value} handleCountrySelect={(val)=>{setCountryCode({value: val, error: null})}}/>} value={newContactNumber} label="Phone number" handleFocus={()=>null} handleChange={(e)=>{setnewContactNumber({value: e.target.value, error: null})}} name="phone_number" type='tel' />
              </>
            )}

            {type === "list" && (
              <>
                <span className="t7 medium-font">Upload list</span>
                <label
                  htmlFor="upload-list-file"
                  className=" upload-file-label"
                >
                  {newListFile.length === 0 && (
                    <>
                      <div className="no-file">
                        <div>
                          <a className="t6 ic-green-500">Click to upload</a>
                          <span className="t6 medium-font">
                            &nbsp;or drag and drop
                          </span>
                          <p className="t8 regular-font nc-gray-600">
                            File format: csv, xls or xlsx (max size: 10MB)
                          </p>
                        </div>
                      </div>
                      <input
                        // key={fileKey || ""}
                        id="upload-list-file"
                        placeholder="or drag and drop files here"
                        className="regular-font t7"
                        name="file"
                        type="file"
                        disabled={newListFile[0]?.name}
                        accept={".csv,.xls,.xlsx"}
                        onChange={(e) => {
                          const _file = e.target.files[0];
                          if (
                            _file?.type === "text/csv" ||
                            _file?.type ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            _file?.type === "application/vnd.ms-excel"
                          ) {
                            setNewListFile([e.target.files[0]]);
                            // setFormListener((prevState) => prevState + 1);
                          } else setNewListFile([]);
                        }}
                      />
                    </>
                  )}
                  {newListFile[0]?.name && (
                    <div className="has-file">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={baseFileIcon} />
                        <div
                          className="t6 regular-font"
                          style={{
                            marginLeft: "20px",
                          }}
                        >
                          {/* <p className="nc-gray-900">{newListFile[0]?.name}</p> */}
                          <p className="nc-gray-900">
                            {newListFile[0].name.substring(0, 27)}
                            {newListFile[0].name.length > 27 ? "…" : ""}
                          </p>
                          <p className="nc-gray-600">
                            {formatFileSize(newListFile[0]?.size)}
                          </p>
                        </div>
                      </div>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          // e.stopPropagation();
                          setNewListFile([]);
                          // setFormListener((prevState) => prevState + 1);
                        }}
                      />
                    </div>
                  )}
                </label>
                <a
                  download
                  href={campaignSampleFile}
                  className="ic-green-500 regular-font t7"
                  style={{
                    cursor: "pointer",
                    lineHeight: "20px",
                    textDecoration: "underline",
                  }}
                >
                  Download sample file
                </a>
              </>
            )}
          </form>
        </div>
      }
    />
  );
}

export default AddContactModal;

const localStyles = {
	radioStyle: {
		"&, &.Mui-checked": {
			color: "#2EB358",
		},
	}
}