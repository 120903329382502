import GModal from "../design/components/GModal"
import { commonStyles } from "../signup/commonStyles";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../design/Button";
import colors from "../design/colors";
import { deleteIcon, topNavAccountPlusIcon, topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import AnimationPage from "../shared/animation-page/AnimationPage";
const TriggerModal=({showModal, isLoading, closeModalHandler, triggers, handleCancel, handleCreateTriggers, deleteTrigger, templateId, orgId, handleUpdateTriggers})=>{
    const reduxOrg=useSelector((state)=>state?.account?.value);

    const [isDeleting, setIsDeleting]=useState(false)
    const [triggersData, setTriggersData]=useState([]);		//triggersData structure: [{id: callReason: callOutcome:, isFilled: Boolean, tempId:},...]
  const [triggersCount, setTriggersCount]=useState(0);

    const reasonsAndOutcomes=useMemo(()=>{
        const {allOrgs, selectedOrg}=reduxOrg
        let _selectedOrg=Array.isArray(allOrgs) ? allOrgs[0] : allOrgs
        if(selectedOrg){
            _selectedOrg=allOrgs.find(org=>org.id===selectedOrg)
        }
        return {
                reasons: _selectedOrg.call_reasons,
                outcomes: _selectedOrg.call_outcomes
            }
      },[reduxOrg])

      useEffect(()=>{
        if(triggers && Array.isArray(triggers) && triggers.length>0){
            const temp=triggers.map((elem, idx)=>({
                id:elem.id,
                callReason:elem.call_reason,
                callOutcome:elem.call_outcome,
                isFilled:true,
                tempId:idx+1
            }))
            setTriggersData(temp)
            setTriggersCount(triggers.length)
        }
      },[triggers])
      const handleAddTriggerInput=()=>{
        setTriggersData(prev=>([...prev, {id:null, callReason:null, callOutcome:null, isFilled:false, tempId: triggersCount+1}]))
        setTriggersCount(prev=>prev+1)
      }
    
      const handleDeleteTrigger= async (elem)=>{
        
        if(elem.id){
            setIsDeleting(elem.tempId)
            const success =	await deleteTrigger(elem.id)
            if(success){
                setTriggersData(prev=>{
                    let temp=[...prev]
                    const idx= temp.findIndex(el=>el.tempId===elem.tempId)
                    temp.splice(idx,1)
                    return temp
                })
            }
            setIsDeleting(false)
        }
        else if(!elem.id){
            setTriggersData(prev=>{
                let temp=[...prev]
                const idx= temp.findIndex(el=>el.tempId===elem.tempId)
                temp.splice(idx,1)
                return temp
            })
            }
      }
    const handleCreate=()=>{
        const triggersToEdit=[];
        const triggersToCreate=[];
        triggersData.forEach(t=>{
            let temp={
                call_reason:t.callReason,
                call_outcome:t.callOutcome,
                template:templateId,
                org:orgId
            }
            if(t.id){
                temp.id=t.id;
                triggersToEdit.push(temp)
            }
            else{
                triggersToCreate.push(temp)
            }

        })
        if(triggersToCreate.length)
        handleCreateTriggers(triggersToCreate)

        if(triggersToEdit.length)
        handleUpdateTriggers(triggersToEdit)
    }
    
    const modalBody=(
      <form className="msg-template__form">
        <label className="t7 medium-font nc-gray-900">
          Template name
          <input
            type="text"
            className="t6 regular-font nc-gray-900"
            value={showModal?.templateName}
            disabled={true}
          />
        </label>
        {isLoading && <AnimationPage/>}
        {!isLoading && <div className="trigger-container">
			<p className='t7 medium-font'>Automatically send when:</p>
            
			{triggersData?.map((elem, idx)=>{
				return (
					<div key={elem.tempId}>
					{idx>0 && <p className='t7 medium-font' style={{textAlign:'center'}}>or</p>}
					<div className="trigger">
						<div className="select-container">
						<label className="t7 regular-font">Call reason</label>
						<Select
						IconComponent={ExpandMoreIcon}
						sx={commonStyles.selectStyles}
						MenuProps={commonStyles.selectMenuStyles}
						placeholder="Select reason"
						value={elem.callReason}
						native={false}
						displayEmpty
						renderValue={(val) => {
						
							return (
							val?<span style={{color:colors.nc_gray_900}}>{val}</span>:<span style={{color:colors.nc_gray_400}}>Select reason</span>
							)
						}}
						onChange={(e) => setTriggersData(prev=>{
							let temp=[...prev]
							const newData=temp.map(el=>{
								if(el.tempId===elem.tempId){
								 return {...el,callReason:e.target.value, isFilled: el.callOutcome && e.target.value}
								}
								else 
								return el
							})
							return newData
						})}
						>
						{reasonsAndOutcomes.reasons.map((elem, index) => {
							return (
							<MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
								<span className="t7 regular-font">{elem}</span>{triggersData[idx]['callReason']===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
							</MenuItem>
							);
						})}
						</Select>
						</div>
						<div className="select-container">
						<label className="t7 regular-font">Call outcome</label>
						<Select
						IconComponent={ExpandMoreIcon}
						sx={commonStyles.selectStyles}
						MenuProps={commonStyles.selectMenuStyles}
						placeholder="Select outcome"
						value={elem.callOutcome}
						native={false}
						displayEmpty
						renderValue={(val) => {
						
							return (
							val?<span style={{color:colors.nc_gray_900}}>{val}</span>:<span style={{color:colors.nc_gray_400}}>Select outcome</span>
							)
						}}
						onChange={(e) => setTriggersData(prev=>{
							let temp=[...prev]
							const newData=temp.map(el=>{
								if(el.tempId===elem.tempId)
								return {...el,callOutcome:e.target.value, isFilled: el.callReason && e.target.value}
								else 
								return el
							})
							return newData
						})}
						>
						{reasonsAndOutcomes.outcomes.map((elem, index) => {
							return (
							<MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
								<span className="t7 regular-font">{elem}</span>{triggersData[idx]['callOutcome']===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
							</MenuItem>
							);
						})}
						</Select>
						</div>
						<Button styleOverride={{minWidth:'36px', height:'36px', width:'36px', padding:'4px'}} className="delete-icon" onClick={()=>handleDeleteTrigger(elem, idx)} hierarchy='white' variant='outlined' isLoading={isDeleting && isDeleting===elem.tempId} buttonText={deleteIcon('#464d48','25','25')}/>
					</div>
					
					</div>
				)
			})}
			{triggersData.length === 0 && <Button variant='outlined' icon={{startIcon:topNavAccountPlusIcon('#2eb258','16','16')}} styleOverride={{width:'440px', color:colors.ic_green_400, fontSize:'14px', fontWeight:'500', color:'#2eb258'}} buttonText='Add trigger' disabled={false} isLoading={false} onClick={handleAddTriggerInput} hierarchy='white'/>}
			{triggersData.length > 0 && <Button variant='outlined' hierarchy='white' styleOverride={{width:'24px',minWidth:'24px',height:'24px', padding:'4px'}} buttonText={topNavAccountPlusIcon((!triggersData?.[triggersData.length - 1]?.isFilled)?colors.nc_gray_400:colors.ic_green_300)} onClick={handleAddTriggerInput} disabled={!triggersData?.[triggersData.length - 1]?.isFilled} isLoading={false}/>}
		</div>}
      </form>
    )
    return <GModal
                noScrollbar={true}
                visible={showModal?.visible}
                closeModal={closeModalHandler}
                heading={'Automation trigger'}
                modalStyle={{maxHeight:'70vh', overflowY:'scroll'}}
                bottomButtons={[
                    <Button
                      variant="outlined"
                      buttonText="Cancel"
                      icon={{}}
                      disabled={isLoading}
                      isLoading={false}
                      hierarchy="white"
                      onClick={handleCancel}
                    />,
                    <Button
                      variant="outlined"
                      buttonText="Save"
                      icon={{}}
                      disabled={false}
                      isLoading={isLoading}
                      hierarchy="green"
                      onClick={()=>
                         handleCreate()
                      }
                    />,
                  ]}
                body={modalBody}
                />
}

export default TriggerModal;