import GModal from "../../design/components/GModal"
import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";
import { commonStyles } from "../../signup/commonStyles"
import Button from "../../design/Button";
import { Box, Chip, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from "react";
import { formatDate } from "../../../utility-functions";
import { errorIcon, topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader";
import colors from "../../design/colors";
import styles from './ManageLicenseModal.module.css'
import { JService } from "../../api-service/ApiService";
import { setVirtualNumbersCount } from "../../features/virtual-number/virtualNumber";
import { useDispatch } from "react-redux";
import { CustomChip } from "../../design/components/customComponents";

const VirtualNumberModal=({visible, handleClose,renewalDate,handleRemoveNumbers})=>{
    const dispatch=useDispatch()

    const [updateType,setUpdateType]=useState('Keep number')
    const [selectedNumbers,setSelectedNumbers]=useState([])
    const [numbers,setNumbers]=useState([])
    const [info,setInfo]=useState({visible:false,message:''})
    const [freeNumbersCount,setFreeNumbersCount]=useState(0)

    const handleUpdateNumbers=()=>{
      handleRemoveNumbers(selectedNumbers?.map(vn=>vn.id))
    }
    const handleCloseModal=()=>{
      setSelectedNumbers([])
      setInfo({visible:false,message:''})
      setUpdateType('Keep number')
      handleClose()
    }

    useEffect(()=>{
        if(!visible) return;
        if(selectedNumbers.length>0){
            setInfo({visible:true,message:<p className="t7 regular-font">You are requesting to remove <span className="medium-font">{selectedNumbers.length}</span> numbers from your account.<br/>
                The changes will take effect from <span className="medium-font">{formatDate(renewalDate)}</span>.<br/>
                You can continue to use the number until then.</p>})
        }else{
            setInfo({visible:true,message:<p className="t7 regular-font">Virtual numbers not included with plan will incur a monthly fee from <span className="medium-font">{formatDate(renewalDate)}</span>.</p>})
        }
    },[selectedNumbers,renewalDate])

    useEffect(()=>{
        if(!visible) return;
        JService.get('/api/v1/auth/virtual-numbers')
        .then((res)=>{
            if(!res?.success ) throw new Error(res?.message || 'Could not retrieve virtual numbers')
            setNumbers(res?.data?.filter(vn=>(!vn.addon && !vn.delete_scheduled)))
            let vnInfoObj={}
            let freeNumbers=0
          res?.data?.forEach(vn=>{
            let location=vn.location
            if(location.includes('India'))   location='India'
            if(!vnInfoObj[location]){
              vnInfoObj[location]={total:0,includedInPlan:0}
            }
            vnInfoObj[location]['total']+=1
            vnInfoObj[location]['includedInPlan']+= (!vn.addon?1:0)
            freeNumbers+=(!vn.addon?1:0)
          })
         dispatch(setVirtualNumbersCount(vnInfoObj))
         setFreeNumbersCount(freeNumbers)
        })
        .catch(err=>{
        })
    },[visible])
   
    return (
        <GModal visible={visible} closeModal={handleCloseModal} heading='Keep/remove virtual number'
         bottomButtons={[<Button styleOverride={btnStyle} variant='outlined' hierarchy='white' buttonText='Cancel' onClick={handleCloseModal} disabled={false}/>,
                        <Button styleOverride={btnStyle} hierarchy='green' buttonText='Update numbers' onClick={handleUpdateNumbers} disabled={!updateType || (updateType==='Remove number' && (!numbers || !numbers.length))}/>]}
        modalStyle={{padding:'32px',width:'560px',marginBottom:'32px'}}
         body={
            <div>
                 <p className="t7">
                    <span className="medium-font">Virtual numbers: </span>
                    <span className="regular-font">{numbers?.length} numbers </span>
                    <span style={{color:colors.nc_gray_800}}>({freeNumbersCount} included with plan)</span>
                </p>
                <p className="t7">
                    <span className="medium-font">Renewal: </span>
                    <span className="regular-font">{formatDate(renewalDate)}</span>
                </p>
                <form style={{margin:'32px 0'}}>
                <div className="select-container" style={{marginBottom:'4px'}}>
                    <label className="t7 medium-font">Keep/remove virtual number</label>
                    <Select
                        IconComponent={ExpandMoreIcon}
                        sx={commonStyles.selectStyles}
                        MenuProps={commonStyles.selectMenuStyles}
                        value={updateType}
                        native={false}
                        displayEmpty
                        renderValue={(val) => {
                        return (
                            val?<span style={{color:colors.nc_gray_900}}>{val}</span>:null
                            )
                        }}
                        onChange={(e) => {
                            if(e.target.value==='Keep number') setSelectedNumbers([])
                            setUpdateType(e.target.value)
                        }}
                        >
                        {['Keep number','Remove number'].map((elem, index) => {
                            return (
                            <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem} key={index}>
                                <span>{elem}</span>{updateType===elem && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
                            </MenuItem>
                            );
                        })}
                        </Select>
                    </div>
                    {updateType==='Remove number' && (
                        
                      <Select
                        value={selectedNumbers}
                        multiple={true}
                        multiline={true}
                        autoFocus={false}
                        onChange={(e,val) => {
                          let {
                            target: { value },
                          } = e;
                          if (value instanceof Array) value = value?.reverse()[0];
                          setSelectedNumbers((prev) => ([
                            ...prev,
                            value
                          ]));
                        }}
                        sx={{
                          alignSelf: "flex-start",
                          minWidth: 200,
                          width: "100%",
                          maxWidth: "100%",
                          outline: "none",
                          borderRadius: "4px",
                          padding: 0,
                          marginBottom: "0",
                          //...Styles.t6,
                          //...Styles.regular_font,
                          //...Colors.nc_gray_900,
                          "&:before": {
                            outline: "none",
                            border: "none",
                          },
                          "&:focus .MuiOutlinedInput-input": {
                            border: "none",
                            outline: "none",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 12px 8px 12px",
                            border: "none",
                            outline: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #34CB65",
                            boxShadow: "none"
                          },
                        }}
                        renderValue={(selected) => {
                          if (numbers.length === 0)
                            return (
                              <p className="regular-font t6 nc-gray-400">
                                No numbers available
                              </p>
                            );
                          if (selected?.length === 0)
                            return (
                              <p className="regular-font t6 nc-gray-400">Select number</p>
                            );
                          // check selected to see all seleted values, at this point, selected has duplicates
                          // removing duplicates from rendering, remove duplicates from Select value before sending
                          let temp = selected?.reduce(function (x, y) {
                            if (x.indexOf(y) < 0) x.push(y);
                            return x;
                          }, []);
                          return (
                            <Box
                              sx={{
                                maxWidth: "340px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                rowGap: "4px",
                              }}
                            >
                              {temp?.map((value) => {
                                if (!value || (Array.isArray(value) && value?.length === 0))
                                  return;
                                // let id =
                                //   value instanceof Array
                                //     ? value[0]?.split(",")[1]
                                //     : value?.split(",")[1];
                                // let name =
                                //   value instanceof Array
                                //     ? value[0]?.split(",")[0]
                                //     : value?.split(",")[0];
                                return (
                                  <CustomChip
                                    key={value.id}
                                    label={value.country_code+' '+value.number}
                                    onDelete={(e) => {
                                      e.stopPropagation();
                                      setSelectedNumbers(prev => {
                                        return prev.filter(num => num.id !== value.id)
                                      })
                                    }}
                                  />
                                );
                              })}
                            </Box>
                          );
                        }}
                        placeholder="Select number"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {numbers?.map((num, index) => (
                          <MenuItem value={num} key={index}>
                            {num?.country_code+' '+num?.number}
                          </MenuItem>
                        ))}
                      </Select>
                      
                    )}
                </form>
                {info?.visible && <div className={styles.warningContainer}><span style={{marginTop:'4px'}}>{errorIcon('#464c48','16.67','16.67')}</span>{info?.message}</div>}
            </div>
         }
         />
    )
}


const btnStyle={
    height:'36px',
    fontSize:'14px',
    lineHeight:'14px',
    fontWeight:'400',
    padding:'10px 16px'
  }

export default VirtualNumberModal