import { Box } from "@mui/material";
import colors from "../../../design/colors";
import { backIcon } from "../../../../utility-functions/assetLoader";
import "../topNavStyles.css";
import { useState } from "react";
const Dialpad = ({ handleBack, session, isHeaderVisible = true, btnStyle={} }) => {
  const letters = [
    "",
    "",//"ABC",
    "",//"DEF",
    "",//"GHI",
    "",//"JKL",
    "",//"MNO",
    "",//"PQRS",
    "",//"TUV",
    "",//"WXYZ",
    "",
    "+",
    "",
  ];
  const digits = [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"];
  const [value, setValue] = useState("");

  const handleDigitClick = (index) => {
    setValue((prevState) => `${prevState}${digits[index]}`);
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: `Signal=${digits[index]}\r\nDuration=1000`,
        },
      },
    };
    session?.info(options);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        height: "434px",
      }}
    >
      {isHeaderVisible && <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: "16px",
          height: "50px",
          borderRadius: "6px 6px 0px 0px",
          borderBottom: `1px solid ${colors.nc_gray_300}`,
        }}
      >
        {/* back arrow icon */}

        <div
          title="Enter notes in the text area below and click here to proceed"
          onClick={handleBack}
          style={{ height: "20px", width: "20px", cursor: "pointer" }}
        >
          {backIcon()}
        </div>
      </Box>}
      <Box className="dialpad--screen">{value}</Box>
      <Box
        sx={{
          padding: "15px",
          flex: 1,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {digits.map((elem, index) => {
          return (
            <button
              key={elem}
              type="button"
              style={{...btnStyle}}
              className="dialpad--btn"
              onClick={() => handleDigitClick(index)}
            >
              <p>{elem}</p>
              {/* <p>{letters[index]}</p> */}
            </button>
          );
        })}
      </Box>
    </Box>
  );
};

export default Dialpad;
