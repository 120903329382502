import { enqueueSnackbar } from "notistack";
import { JService } from "../api-service/ApiService";

import WarnModal from "../contact-lists/WarnModal/WarnModal";
import { useState } from "react";

const DeleteSurveyModal = ({visible, handleClose, id, title, callback}) => {
    const [showLoader, setShowLoader] = useState(false);
    const handleDeleteSurvey = async (id) => {
        setShowLoader(true);
        try{
          const res = await JService.delete(`/api/v1/core/ivr-survey/${id}/`);
          if(!res.success){
            throw new Error(res.message || 'Error while deleting survey');
          }
          callback();
        }
        catch(err){
          enqueueSnackbar(err.message || 'Error while deleting survey', {variant:'error'});
        }
        finally{
          setShowLoader(false);
          handleClose();
        }
    }

    return (
        <WarnModal
        visible={visible}
        closeModal={handleClose}
        heading={<p className="t6 medium-font">Delete survey</p>}
        message={
          <div className="t7 regular-font">
            Are you sure you want to delete the &nbsp;
            {(
              <p
                style={{
                  display: "inline",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                {title + ' '}
              </p>
            )}
            survey ?
            <ul style={{marginTop: '24px', paddingLeft: '25px'}}>
                <li className="t7 regular-font">All survey data and responses will be permanently lost</li>
            </ul>
          </div>
        }
        actionButtonTitle="Delete"
        handleAction={() => handleDeleteSurvey(id)}
        showLoader = {showLoader}
      />
    )
}

export default DeleteSurveyModal;