import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { lazy, Suspense } from 'react';
import { useSelector } from "react-redux";

import "./components/design/legacyCSS.css";
import { Helmet } from "react-helmet";
import { useHubspotChat } from "./hubspot";
import hubspotChatOpen from "./assets/hubspot-chat-open.svg";
import hubspotChatClose from "./assets/hubspot-chat-close.svg";
import { SnackbarProvider } from 'notistack';

//NOTE: Component imports
import Navbar from "./components/shared/navbar/Navbar";
import Login from "./components/login/Login";
import LoginScreen2 from "./components/login/LoginScreen2";
import Signup from "./components/signup/Signup";
import Invite from "./components/invite-flow/Invite";
import PlatformRules from "./components/platform-rules/PlatformRules";
import ActivityLogs from "./components/ActivityLogs/ActivityLogs";
import CustomSnackbar from "./components/design/components/CustomSnackbar.jsx"
// import AnimationPage from "./components/shared/animation-page/AnimationPage.js";
import SignupScreen2 from "./components/signup/SignupScreen2";
import SignupScreen4 from "./components/signup/SignupScreen4";
import SignupScreen6 from "./components/signup/SignupScreen6";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import PageNotFound from "./components/page-not-found/PageNotFound";
import Dashboard from "./components/dashboard/dashboard";
import Settings from "./components/settings/Settings";
import Denied from "./components/denied/Denied";
import SharedCallRecording from "./components/shared-call-recording/SharedCallRecording";
import ForgotPasswordVerify from "./components/forgot-password/ForgotPasswordVerify";
import ForgotPasswordReset from "./components/forgot-password/ForgotPasswordReset";
import Users from "./components/users-and-teams/users/Users";
import Teams from "./components/users-and-teams/teams/Teams";
import ContactLists from "./components/contact-lists/ContactLists";
import TeamView from "./components/users-and-teams/teams/TeamView";
import ContactListsView from "./components/contact-lists-view/ContactListsView";
// import InteractionHistory from "./components/contact-lists-view/InteractionHistory/Activity";

// import Status from "./components/shared/status/Status";
// import VoiceBroadcast from "./components/voice-broadcast/VoiceBroadcast";
// import VoiceBroadcastView from "./components/voice-broadcast-view/VoiceBroadcastView";
// import AddAccount from "./components/add-account/AddAccount";

import VirtualNumber from "./components/virtual-numbers/VirtualNumber";
import AddVirtualNumber from "./components/virtual-numbers/AddVirtualNumber";
import VirtualNumberView from "./components/virtual-number-view/VirtualNumberView";
import Autodial from "./components/autodial/Autodial";
import AutodialView from "./components/autodial-view/AutodialView";
import Survey from "./components/survey/Survey.jsx";
import SurveyView from "./components/survey/SurveyView.jsx";
import MessageTemplates from "./components/message-templates/MessageTemplates";

import Oauth from "./components/settings/Oauth";
import SignupScreen7 from "./components/signup/SignupScreen7";
import Billing from "./components/billing/Billing";
import AccountSuspended from "./components/AccountSuspended";
import CallPricing from "./components/call-pricing/CallPricing"

// const Survey = lazy(() => import('./components/survey/Survey.jsx'));
// const SurveyView = lazy(() => import("./components/survey/SurveyView.jsx"));
// const MessageTemplates = lazy(() => import("./components/message-templates/MessageTemplates"));
// const VirtualNumber = lazy(() => import("./components/virtual-numbers/VirtualNumber"));
// const AddVirtualNumber = lazy(() => import("./components/virtual-numbers/AddVirtualNumber"));
// const VirtualNumberView = lazy(() => import("./components/virtual-number-view/VirtualNumberView"));
// const AccountSuspended = lazy(() => import("./components/AccountSuspended"));
// const ForgotPassword = lazy(() => import("./components/forgot-password/ForgotPassword"));
// const ForgotPasswordVerify = lazy(() => import("./components/forgot-password/ForgotPasswordVerify"));
// const ForgotPasswordReset = lazy(() => import("./components/forgot-password/ForgotPasswordReset"));
// const SharedCallRecording = lazy(() => import("./components/shared-call-recording/SharedCallRecording"));
// const Billing = lazy(() => import("./components/billing/Billing"));
// const Dashboard = lazy(() => import("./components/dashboard/dashboard"));
// const Settings = lazy(() => import("./components/settings/Settings"));
// const CallPricing = lazy(() => import("./components/call-pricing/CallPricing"));
// const Oauth = lazy(() => import("./components/settings/Oauth"));
// const Users = lazy(() => import("./components/users-and-teams/users/Users"));
// const Teams = lazy(() => import("./components/users-and-teams/teams/Teams"));
// const TeamView = lazy(() => import("./components/users-and-teams/teams/TeamView"));
// const ContactLists = lazy(() => import("./components/contact-lists/ContactLists"));
// const ContactListsView = lazy(() => import("./components/contact-lists-view/ContactListsView"));
// const Autodial = lazy(() => import("./components/autodial/Autodial"));
// const AutodialView = lazy(() => import("./components/autodial-view/AutodialView"));
// const SignupScreen2 = lazy(() => import("./components/signup/SignupScreen2"));
// const SignupScreen4 = lazy(() => import("./components/signup/SignupScreen4"));
// const SignupScreen6 = lazy(() => import("./components/signup/SignupScreen6"));
// const SignupScreen7 = lazy(() => import("./components/signup/SignupScreen7"));
// const PageNotFound = lazy(() => import("./components/page-not-found/PageNotFound"));
// const Denied = lazy(() => import("./components/denied/Denied"));

const SeoFreindlyLogin = () => {
  return (
    <>
      <Helmet>
        <title>Login - FreJun</title>
        <meta
          name="description"
          content="Login to your FreJun account to make outbound calls and access your call recordings with call metrics."
        />
        <meta name="keywords" content="cloud telephony" />
      </Helmet>
      <Login />
    </>
  );
};

export default function App() {
	const reduxSubscription=useSelector(state=>state?.account?.value?.subscription)
	const accessList = useSelector((state) => state?.account?.value?.data?.access_list);
	const userRole = useSelector((state) => state?.account?.value?.data?.profile?.role);
	const checkParams = () => {
		const searchParams = new URLSearchParams(window.location.search)
		const fromSupport = searchParams.get("support")
		// open widget
		if(fromSupport) {
			console.log("opening widget")
			handleToggleWidget()
		}
	}
	const { hasLoaded, activeConversation, handleToggleWidget, isWidgetOpen } = useHubspotChat("5132602", checkParams);
	const isCurrentDate7DaysLaterThanTrialEnd = (date) => {
		const endDate = new Date(date);
		const today = new Date();
		const timeDifference = today.getTime() - endDate.getTime();
		const daysSince = Math.ceil(timeDifference / (1000 * 3600 * 24));
		if (daysSince >= 7)
		  return true;
	
		  return false;
	  }
	return (
		<>
			<BrowserRouter>
				{/* <Suspense fallback={<AnimationPage/>}> */}
				<SnackbarProvider
					autoHideDuration={6000} 
					maxSnack={1} 
					anchorOrigin={{horizontal: "center", vertical: "bottom"}}
					Components={{
						success: CustomSnackbar,
						error: CustomSnackbar,
					}}
				/>
				
				{/* page meta and navbar */}
				<Navbar />

				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/login" element={<SeoFreindlyLogin />} />
					<Route path="/invite" element={<Invite />} />
					<Route path="/login/verification" element={<LoginScreen2 />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/forgot-password/verification" element={<ForgotPasswordVerify />} />
					<Route path="/forgot-password/reset" element={<ForgotPasswordReset />} />
					<Route path="/signup/verification" element={<SignupScreen2 />} />
					<Route path="/signup/company-details" element={<SignupScreen4 />} />
					<Route path="/signup/trial-number" element={<SignupScreen6  handleToggleWidget={handleToggleWidget}/>}/>
					<Route path="/card-details" element={<SignupScreen7 isWidgetOpen={isWidgetOpen} handleToggleWidget={handleToggleWidget} />}/>
					<Route path='/account-suspended' element={<AccountSuspended/>}/>

					{/* TODO: shouldn't the check be reduxSubscription?.status === 'INACTIVE'? */}
					{(reduxSubscription?.status!=='INACTIVE' ||
					 (reduxSubscription?.status === 'INACTIVE' && !reduxSubscription?.payment_due_date && !isCurrentDate7DaysLaterThanTrialEnd(reduxSubscription?.end_date)))  && 
						<>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path='/billing' element={<Billing/>}/>
							<Route path="/settings" element={<Settings />} />
							<Route path="/oauth/authorize" element={<Oauth />} />
							
							<Route path="/contact-lists" element={<ContactLists />} />
							<Route path="/contact-lists/:id" element={<ContactListsView />} />
							<Route path="/call-logs"element={<ActivityLogs tab='Calls'/>}/>
          					<Route path="/activity-logs/calls" element={<ActivityLogs tab='Calls'/>}/>
          					<Route path="/activity-logs/messages" element={<ActivityLogs tab='Messages'/>}/>
							<Route path="/messages/:id" element={<ActivityLogs tab="Messages"/>}/>
							<Route path="/deny" element={<Denied />} />
							<Route path="/interviews/shared-interview/:id" element={<SharedCallRecording />}/>
						
							{accessList?.includes('AUTODIAL') &&
								<>
									<Route path="/autodial" element={<Autodial />} />
									<Route path="/autodial/:id" element={<AutodialView />} />
								</>
							}
							{accessList?.includes('IVR SURVEY') && [1,4].includes(userRole) &&
								<>
									<Route path="/survey" element={<Survey />} />
									<Route path="/survey/:id" element={<SurveyView />} />
								</>
							}
							<Route path="/users" element={<Users />} />
							<Route path="/messages" element={<MessageTemplates />} />
							<Route path="/teams" element={<Teams />} />
							<Route path="/teams/:id" element={<TeamView />} />
							<Route path="/virtual-numbers" element={<VirtualNumber isWidgetOpen={isWidgetOpen} handleToggleWidget={handleToggleWidget}/>} />
							<Route path="/virtual-numbers/add-number" element={<AddVirtualNumber/>}/>
							<Route path="/virtual-numbers/:number" element={<VirtualNumberView />}/>
							<Route path="/platform-rules" element={<PlatformRules />} />
						</>
					}

					<Route path="/call-pricing-usd" element={<CallPricing />} />
					<Route path="/call-pricing-inr" element={<CallPricing />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
				{/* </Suspense> */}
			</BrowserRouter>
			
			<ChatSupport isWidgetOpen={isWidgetOpen} handleToggleWidget={handleToggleWidget}/>
		</>
	);
}


function ChatSupport({isWidgetOpen, handleToggleWidget}) {
	return (
		<div style={localStyles.chatSupportContainer}>
			<button
				style={ localStyles.chatSupportButton}
				onClick={handleToggleWidget}
			>
				<img
					src={isWidgetOpen ? hubspotChatClose : hubspotChatOpen}
					alt="open chat"
				/>
			</button>
		</div>
	)
}

const localStyles = {
	chatSupportContainer: {
		position: "fixed",
		right: "16px",
		bottom: "16px",
		width: "70px",
		height: "70px",
		zIndex: "10",
	},
	chatSupportButton: {
		width: "100%",
		height: "100%",
		borderRadius: "50%",
		backgroundColor: "#00a4bd",
		display: "block",
	}
}

// removed paths
// {/* <Route path="/add-account" element={<AddAccount />} /> */}
// {/* <Route path="/call-logs/:id" element={<CallRecording />} /> */}
// {/* <Route path="/voice-broadcast" element={<VoiceBroadcast />} /> */}
// {/* <Route path="/voice-broadcast/:id" element={<VoiceBroadcastView />} /> */}
