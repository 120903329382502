import React, { useEffect, useReducer,useState } from 'react'
import GHeader from '../design/components/GHeader'
// TODO: move to assetLoader
import userEditIcon from "../../assets/call-logs-edit.svg"
import GTable from '../design/components/GTable'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import colors from '../design/colors'
import styles from '../design/styles'
import { JService } from '../api-service/ApiService'
import { filtersReducer, formatDate } from '../../utility-functions'
import { enqueueSnackbar } from 'notistack'

const statusToColorMapping = {
	"ongoing": {
		bg: colors.tc_blue,
		bgBorderColor: colors.tc_border_blue
	},
	"cancelled": {
		bg: '#FFF1F3',
		// bgBorderColor: 'red',
		textColor: colors.ic_black
	},
	"finished": {
		bg: colors.tc_green,
		bgBorderColor: colors.tc_border_green
	},
	"paused": {
		bg: colors.tc_yellow,
		bgBorderColor: colors.tc_border_yellow
	},
	"": {
		bg: colors.ic_white,
		bgBorderColor: colors.ic_white
	}
}

const generateRowsFromResponseData = (data) => {
	const returnData = Object.values(data)?.reduce((cumulative, key) => {
		let {
			id,
			name,
			created_at,
			contacts,
			contacts_remaining,
			contacts_failed,
			status,
		} = key

		contacts = Object.values(contacts)
		contacts_remaining = Object.values(contacts_remaining)
		contacts_failed = Object.values(contacts_failed)

		// let contactedPercent = (100 -  parseInt((100*(contacts_remaining?.length + contacts_failed?.length))/ contacts?.length )).toString() + '%'
		let reachedContacts = (contacts?.length - (contacts_failed?.length + contacts_remaining?.length))

		let row = {
			id,
			autodialSessionsName: name,
			// start is used as contactedOn
			autodialSessionscreatedOn: formatDate(created_at),
			autodialSessionTotalCount: contacts?.length,
			autodialSessionsContacted: reachedContacts,
			autodialSessionStatus: status,
		}

		cumulative.push(row)
		return cumulative
	}, [])
	return returnData
}

const initialState = {
	campaignStatus: {
		all: [
		  { id: "ongoing", name: "Ongoing" },
		  { id: "paused", name: "Paused" },
		  { id: "finished", name: "Completed" },
		  { id: "cancelled", name: "Cancelled" },
		  
		],
		selected: [],
	},
	currentSet: '',
	searchText: '',
	searchFields: [],
}

export default function Autodial() {
	const navigate = useNavigate()

	const [rows, setRows] = useState([])
	const [masterRows, setMasterRows] = useState([])
	const [data, setData] = useState([])
	const [showLoader, setShowLoader] = useState(false)

	const cols = [
		{
			field: "autodialSessionsName",
			headerName: "Campaign",
			headerClassName: 'user-table-header-user-name',
			flex: 1,
			renderCell: (params) => {
				return (
				<p style={{ textDecoration: "underline", color: "#28994B", paddingLeft: '14px',fontSize:'14px' }}>
					{params?.row?.autodialSessionsName}
				</p>
				);
			},
		},
		{
			field: "autodialSessionscreatedOn",
			headerName: "Created on",
			flex: 1,
		},
		{
			field: "autodialSessionTotalCount",
			headerClassName: "autodial-screen-table-header-contacts",
			headerName: "Contacts",
			flex: 0.7,
		},
		{
			field: "autodialSessionsContacted",
			headerName: "Reached",
			flex: 1,
		},
		{
			field: "autodialSessionStatus",
			headerName: "Status",
			flex: 1,
			renderCell: params => {
				let statusLower = params?.row?.autodialSessionStatus?.toLowerCase()
				let bg = colors.ic_white
				let bgBorderColor = colors.ic_white
				let textColor = colors.nc_gray_900

				bg = statusToColorMapping[statusLower]?.bg
				bgBorderColor = statusToColorMapping[statusLower]?.bgBorderColor
				
				if(statusLower === 'cancelled') {
					bg = '#FFF1F3'
					textColor = colors.ic_black
				}
				
				return <Box>
				<Box sx={{
					backgroundColor: bg,
					padding: '6px 10px',
					color: textColor,
					fontSize:'14px' ,
					borderRadius: '4px',
					...styles.b2,
					...styles.regular_font,
					border: `1px solid ${bgBorderColor}`,
				}}>
					{params?.row?.autodialSessionStatus?.toLowerCase() === 'finished' ? 'Completed' : params?.row?.autodialSessionStatus}
				</Box>
			</Box>}

			
		},
	];

	useEffect(()=>{
		const controller = new AbortController();
		console.log('setting loader to true');
		setShowLoader(true)
		
		JService.get(`/api/v1/core/retrieve-autodial-sessions/`, {}, {signal: controller.signal})
		.then(res => {
			if(!('success' in res)) return;
			if(!res?.success) throw new Error(res?.message || res?.detail || 'Could not retreive autodial sessions')
			setShowLoader(false);
			enqueueSnackbar(res?.message || 'Retrieved autodial sessions', { variant: "success"})
			let initialTemp = res?.data?.sort((a, b) => b?.id - a?.id)
			let temp = generateRowsFromResponseData(initialTemp)
			setRows(temp)
			setMasterRows(temp)
			setData(initialTemp)
		})
		.catch(err => {
			setShowLoader(false); 
			enqueueSnackbar(err?.message, { variant: "error"})
		}) 

		return () => controller.abort();
		
	}, [])

	const [availableData, dispatchAvailableData] = useReducer(filtersReducer, initialState)

	const onSearch = searchText => {
		const searchTextLower = searchText?.toLowerCase()
		const temp = masterRows?.filter(mrf => mrf?.autodialSessionsName?.toLowerCase()?.includes(searchTextLower))
		combineSearchAndFilters(temp)
	}

	useEffect(()=>{
		if(onSearch instanceof Function)  {
			onSearch(availableData?.searchText)
		}
	}, [availableData]) //eslint-disable-line
	
	function combineSearchAndFilters(arg1) {
		// if filters and search is empty
		if((!availableData?.campaignStatus?.selected?.length && availableData?.searchText.trim()?.length === 0)) {
			if(arg1 != rows){
				setRows(arg1) //eslint-disable-line
			}
			return
		}

		let temp = arg1
		if(availableData?.campaignStatus?.selected?.length) {
			temp = temp?.filter(te => availableData?.campaignStatus?.selected?.includes(te?.autodialSessionStatus?.toLowerCase()))
		}

		setRows(temp)
	}

	return (
		<div className="screen-container autodial-screen-container">
			<GHeader
				breadcrumbs={false}
				threeDots={false}
				topHeader={{}}
				bottomHeader={{
				contentTitle: "Autodial campaigns",
				buttons: [],
				isCalendar: false,
				}}
			/>

			<GTable
				leftHeader={{
					filters: [{name: 'Campaign status', toggle: false, isSearch: false, payloadFor: 'campaignStatus'},],
					isSearch: true,
				}}
				dispatchAvailableData={dispatchAvailableData}
				availableData={availableData}
				fromScreen='autodialScreen'
				rightHeader={[]}
				rows={rows}
				columns={cols}
				useMuiTable={true}
				isLoading={showLoader}
				getTableRowClassName={() => `teams-table-row`}
				additionalProps={{ bgHover: true }}
				onRowClick={(params, event, details) => {
				navigate(`/autodial/${params?.id}`, {
					// state: { campaignName: params.row.name },
				});
				}}
				onSearch={onSearch}
				combineSearchAndFilters={combineSearchAndFilters}
			/>
		</div>
	)
}