// # components
import GBreadcrumbs from "./GBreadcrumbs";

// # css
import "./gheaderStyles.css";
import styles from "../styles";
import colors from "../colors";
import "rsuite/dist/rsuite.min.css";

// # lib
import React, { useEffect, useState } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import { Box, Menu, MenuItem } from "@mui/material";
import { formatDate } from "../../../utility-functions";

// # assets
import threedotsIcon from "../../../assets/gcall-logs-threedots-icon.svg";
import searchIcon from "../../../assets/gusers-and-teams-gtable-search-icon.svg";
import searchActiveIcon from "../../../assets/gusers-and-teams-gtable-search-active-icon.svg";
import {
  callLogsHeaderCalendar,
  callLogsHeaderCalendarN,
} from "../../../utility-functions/assetLoader";
import breadcrumbsSeparatorIcon from "../../../assets/gusers-and-teams-breadcrumbs-separtor.svg";

const localStyles = {
  spanNoOverFlowStyle: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
};

const { afterToday, after, before, combine } = DateRangePicker;

const DATE_PICKER_BOX_SHADOW = "var(--rs-state-focus-shadow)";

export default function GHeader({
  breadcrumbs,
  title,
  threeDots,
  contentTitle,
  topHeader,
  topHeaderVisible=true,
  bottomHeader,
  separator,
  handleSetSearchText,
  gsepStyles={}
}) {
  // datepicker buttonText and box-shadow styles
  const [currentOpenCalendar, setCurrentOpenCalendar] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (bottomHeader && bottomHeader?.search) handleSetSearchText(searchText);
  }, [searchText]);

  function updateCalendarButtonText(dateValue = null) {
    if (currentOpenCalendar?.length) {
      let formattedDate = dateValue ? formatDate(dateValue.toString()) : "";
      let buttonElement = document.querySelector(
        ".rs-calendar-header-title-date"
      );
      if (buttonElement) {
        buttonElement.title = `Click to open Year View to select "${currentOpenCalendar}" date`;
        buttonElement.textContent = `${currentOpenCalendar} ${
          formattedDate || buttonElement.textContent
        }`;
      }
    }
  }

  useEffect(() => {
    updateCalendarButtonText();
  }, [currentOpenCalendar]);

  const [datePickerBoxShadow, setDatePickerBoxShadow] = useState({
    start: "none",
    end: "none",
  });
  // -- datepicker buttonText and box-shadow styles end

  // threedots
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  function handleThreeDotsClick(e) {
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.target || e.currentTarget || null);
  }

  // when one of the options in the dropdown is clicked
  function handleDropdownClick(e, menuitemtype) {
    if (menuitemtype === "delete")
      threeDots?.modal?.setDeleteModalVisible(true);
    else return;
  }

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    // clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);
  // -- threedots end

  return (
    <>
      <div className="gheader-container">
        {/*breadcrumbs, title and header-threeDots */}
        {topHeaderVisible && <><div className="top-header-container">
          {/* breadcrumbs */}
          {breadcrumbs.isVisible && (
            <GBreadcrumbs
              breadcrumbs={breadcrumbs?.breadcrumbs}
              separator={
                <img
                  src={breadcrumbsSeparatorIcon}
                  alt="breadcrumbs separtor icon"
                />
              }
            />
          )}

          {title && <div
            className="title"
          >
            {/* header/route title */}
            
            <p
              title={title}
              style={{
                ...localStyles?.spanNoOverFlowStyle,
                maxWidth: "620px",
                cursor: "default",
              }}
              className="t3 medium-font nc-gray-900 "
            >
              {title}
            </p>

            {/* threedots */}
            {threeDots?.isVisible && (
              <div
                className="threedots-container"
                style={{ cursor: "pointer" }}
                onClick={handleThreeDotsClick}
              >
                <img
                  className={`threedots-${threeDots?.id}`}
                  data-callid={threeDots?.id}
                  onClick={(e) => handleThreeDotsClick(e)}
                  src={threedotsIcon}
                  alt="Three dots icon"
                />
              </div>
            )}
          </div>
          }     
        </div>

        {/* separator that extends to both sides. Can be */}
        {/* placed in a child to render to parent's width */}
        <div className="gsep" style={gsepStyles}></div>
        </>
}
        {/*bottomHeader: subsection title, calendar, buttons */}
        <div className="bottom-header-container" style={{paddingTop:topHeaderVisible?'24px':'0', ...bottomHeader.style}}>
          <div className="bottom-left-header">
            {bottomHeader.contentTitle && (
              <p className="t5 medium-font nc-gray-900">
                {" "}
                {bottomHeader.contentTitle || ""}{" "}
              </p>
            )}
            {bottomHeader.search && (
              <div
                className="left-header-item search"
                style={{
                  border: `1px solid ${colors.nc_gray_300}`,
                  maxHeight: "40px",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  alignItems: "center",
                  display: "flex",
                  marginTop:'24px'
                }}
              >
                <img
                  onClick={(e) =>
                    document.getElementById("gheader-search")?.focus()
                  }
                  //style={{ ...rightHeaderIconStyle }}
                  src={searchText?.length > 0 ? searchActiveIcon : searchIcon}
                  alt="Search icon"
                />
                <input
                  id="gheader-search"
                  style={{
                    border: "none",
                    margin: "0 12px 0 6px",
                  }}
                  className="t6 regular-font"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            )}
          </div>
          {/* After clearing the start and end dates currently
			  there is no way to know the previously selected
			  dates */}
          <div className="bottom-right-header">
            {Array.isArray(bottomHeader?.buttons) &&
              bottomHeader?.buttons?.length > 0 &&
              bottomHeader.buttons.map((button, index) => (
                <div key={index}>{button}</div>
              ))}{" "}
            {/* // eslint-disable-line */}
            {bottomHeader?.isCalendar && (
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* calendar icon */}
                <div
                  title="Click to choose start date"
                  style={{
                    marginLeft: "0px",
                    cursor: "default",
                    transform: "translate(30px, 2px)",
                    ...styles.t6,
                  }}
                >
                  {bottomHeader?.calendarDisabled
                    ? callLogsHeaderCalendar(colors.nc_gray_400, 18, 18)
                    : callLogsHeaderCalendar(colors.nc_gray_900, 18, 18)}
                </div>

                {/* startDate calendar */}
                {/* {console.log('********bottomHeader.calendar ', bottomHeader.calendar)} */}
                <DatePicker
                  title={bottomHeader?.calendarDisabled ? "Click 'Custom range' to choose a custom start date from calendar" : "Click to choose start date"}
                  onFocus={() =>
                    setDatePickerBoxShadow({
                      start: DATE_PICKER_BOX_SHADOW,
                      end: "none",
                    })
                  }
                  appearance="subtle"
                  value={
                    bottomHeader?.calendar?.date_start !== null &&
                    bottomHeader?.calendar?.time_start !== null
                      ? new Date(
                          `${bottomHeader?.calendar?.date_start} ${bottomHeader?.calendar?.time_start}`
                        )
                      : null
                  }
                  className="gheader-calendar-calendar"
                  format="dd-MM-yyyy"
                  style={{
                    ...styles.t6,
                    border: `1px solid ${colors.nc_gray_400}`,
                    boxShadow: datePickerBoxShadow?.start || "none",
                    borderRadius: "6px",
                    paddingLeft: "30px",
                    paddingRight: "6px",
                  }}
                  onNextMonth={(value) => {
                    //console.log("calendar value change next month, ", value);
                    updateCalendarButtonText(value);
                  }}
                  onPrevMonth={(value) => {
                    //console.log("calendar value change PREV month, ", value);
                    updateCalendarButtonText(value);
                  }}
                  disabledDate={
                    Object.values(bottomHeader?.calendar)
                      ?.slice(-2)
                      ?.every((dateItem) => Boolean(dateItem))
                      ? combine(
                          afterToday(),
                          after(
                            new Date(
                              `${bottomHeader?.calendar?.date_end} ${bottomHeader?.calendar?.time_end}`
                            ).toString()
                          )
                        )
                      : after(new Date().toString())
                  }
                  onOk={(calendarValueOnOk) =>
                    bottomHeader?.handleCalendarSet(calendarValueOnOk, "start")
                  }
                  onChange={(calendarValueOnChange) => {
                    bottomHeader?.handleCalendarChange(
                      calendarValueOnChange,
                      "start"
                    );
                  }}
                  onChangeCalendarDate={(value) =>
                    updateCalendarButtonText(value)
                  }
                  placement="bottomStart"
                  placeholder="dd-mm-yyyy"
                  ranges={[]}
                  cleanable={false}
                  preventOverflow={true}
                  caret={false}
                  onOpen={() => setCurrentOpenCalendar("From")}
                  onClose={() => {
                    setCurrentOpenCalendar("");
                    setDatePickerBoxShadow((prevState) => ({
                      ...prevState,
                      start: "none",
                    }));
                  }}
                  disabled={bottomHeader?.calendarDisabled || false}
                />

                <div
                  className="regular-font"
                  style={{
                    color: bottomHeader?.calendarDisabled
                      ? colors.nc_gray_600
                      : colors.nc_gray_900,
                    ...styles.t6,
                    transform: "translate(10px, 0px)",
                    cursor: "default",
                  }}
                >
                  to
                </div>

                {/* calendar icon */}
                <div
                  title="Click to choose end date"
                  style={{
                    marginLeft: "0px",
                    cursor: "default",
                    transform: "translate(30px, 2px)",
                    ...styles.t6,
                  }}
                >
                  {bottomHeader?.calendarDisabled
                    ? callLogsHeaderCalendar(colors.nc_gray_400, 18, 18)
                    : callLogsHeaderCalendar(colors.nc_gray_900, 18, 18)}
                </div>

                {/* endDate calendar*/}
                <DatePicker
                  title={bottomHeader?.calendarDisabled ? "Click 'Custom range' to choose a custom end date from calendar" : "Click to choose end date"}
                  onFocus={() =>
                    setDatePickerBoxShadow({
                      start: "none",
                      end: DATE_PICKER_BOX_SHADOW,
                    })
                  }
                  caret={false}
                  appearance="subtle"
                  className="gheader-calendar-calendar"
                  value={
                    bottomHeader?.calendar?.date_end != null &&
                    bottomHeader?.calendar?.time_end != null
                      ? new Date(
                          `${bottomHeader?.calendar?.date_end} ${bottomHeader?.calendar?.time_end}`
                        )
                      : null
                  }
                  disabledDate={
                    Object.values(bottomHeader?.calendar)
                      ?.slice(0, 1)
                      ?.every((dateItem) => Boolean(dateItem))
                      ? combine(
                          afterToday(),
                          before(
                            new Date(
                              `${bottomHeader?.calendar?.date_start} ${bottomHeader?.calendar?.time_start}`
                            ).toString()
                          )
                        )
                      : after(new Date().toString())
                  }
                  format="dd-MM-yyyy"
                  style={{
                    ...styles.t6,
                    border: `1px solid ${colors.nc_gray_400}`,
                    boxShadow: datePickerBoxShadow?.end || "none",
                    borderRadius: "6px",
                    paddingLeft: "30px",
                    paddingRight: "6px",
                  }}
                  onNextMonth={(value) => {
                    console.log("calendar value change next month, ", value);
                    updateCalendarButtonText(value);
                  }}
                  onPrevMonth={(value) => {
                    console.log("calendar value change PREV month, ", value);
                    updateCalendarButtonText(value);
                  }}
                  onOk={(calendarValueOnOk) =>
                    bottomHeader?.handleCalendarSet(calendarValueOnOk, "end")
                  }
                  onChange={(calendarValueOnChange) => {
                    bottomHeader?.handleCalendarChange(
                      calendarValueOnChange,
                      "end"
                    );
                  }}
                  onChangeCalendarDate={(value) =>
                    updateCalendarButtonText(value)
                  }
                  placeholder="dd-mm-yyyy"
                  placement="bottomEnd"
                  ranges={[]}
                  cleanable={false}
                  preventOverflow={true}
                  onOpen={() => setCurrentOpenCalendar("To")}
                  disabled={bottomHeader?.calendarDisabled}
                  onClose={() => {
                    setCurrentOpenCalendar("");
                    setDatePickerBoxShadow((prevState) => ({
                      ...prevState,
                      end: "none",
                    }));
                  }}
                />
              </Box>
            )}
          </div>
        </div>
      </div>

      {/* threedots dropdown */}
      <Menu
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoFocus={false}
        disableScrollLock={true}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        {threeDots?.options?.map((threeDotOption, index) => (
          <MenuItem
            key={index}
            onClick={(e) =>
              handleDropdownClick(e, threeDotOption?.option?.toLowerCase())
            }
            sx={{ cursor: "pointer" }}
          >
            {threeDotOption?.option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
