// # components
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import { useLocation } from "react-router-dom";

// # lib
import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";

import { setNavbarPaths } from "../../features/navbar/navbarPaths";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { pushToClevertap } from '../../../utility-functions/clevertap';

export default function Navbar() {
	const {isNavbarPath, pathname} = useIsNavbarPath()
	// console.log(isNavbarPath, "isNavbarPath", pathname)
	return (
		<>
			<PageMeta pathname={pathname} />
			{isNavbarPath && (
				<Box className="navbar">
					<TopNav />
					<SideNav />
				</Box>
			)}
		</>
	);
}

const PageMeta = ({ pathname }) => {
	if(pathname === "login") return null
	return pathname === "signup" ? (
		<Helmet>
			<title>Get Started with FreJun</title>
			<meta 
				name="description" 
				content="Get started with your free trial on Frejun.com. Automate your calls and integrate with your favourite workflow tools for a more efficient and productive experience"
			/>
		</Helmet>
	) : (
		<Helmet>
			<title>FreJun</title>
			<meta
				name="description"
				content="FreJun automates calling, logging your business calls and insights with your favourite workflow tools in a single click "
			/>
			<meta
				name="keywords"
				content="cloud telephony india calling voip services"
			/>
		</Helmet>
	)
}

const NAVBAR_PATHS = [
	"dashboard",
	"contact-lists",
	"voice-broadcast",
	"call-logs",
	"settings",
	"settings?tab=integrations",
	"teams",
	"users",
	"messages",
	"autodial",
	"virtual-numbers",
	"platform-rules",
	"billing",
	"account-suspended",
  	"activity-logs",
	"interaction-history",
	"survey"
];

function useIsNavbarPath() {
	const location = useLocation()
	const dispatch = useDispatch()
	const prevLocationHref = useRef("")
	const [isNavbarPath, setIsNavbarPath] = useState(false)
	const [pathname, setPathname] = useState("")

	useEffect(() => {
		console.log("location changed", location.pathname)
		// extract base path from location
		const basePath = location.pathname?.split("/")?.at(1)
		const previousBasePath = prevLocationHref.current?.split("/")?.at(1)

		setPathname(basePath)
		
		// prevLocationHref == current location implies page was reloaded/visited again, return
		if(prevLocationHref.current === window.location.href) {
			// special case of /call-logs being reloaded
			if(basePath === "call-logs") {
				dispatch(setNavbarPaths({
					current: basePath,
					previous: "",
					isGoodPath: true,
				}))
				
				// code inside if will never be executed but included for good measure
				if(!isNavbarPath) setIsNavbarPath(true)
			}
			return
		}

		if(NAVBAR_PATHS?.includes(basePath)) {
			dispatch(setNavbarPaths({
				current: basePath,
				previous: previousBasePath,
				isGoodPath: true,
			}))
			setIsNavbarPath(true)
		}
		else {
			dispatch(setNavbarPaths({
				current: basePath,
				previous: previousBasePath,
				isGoodPath: false,
			}))
			setIsNavbarPath(false)
		}

		// NOTE: if above variant causes re-renders, use the variant below with explicit checks
		// check if current path/screen needs Navbar and update in storage
		// if(NAVBAR_PATHS?.includes(basePath) && !isNavbarPath) {
		// 	dispatch(setNavbarPaths({
		// 		current: basePath,
		// 		previous: previousBasePath,
		// 		isGoodPath: true,
		// 	}))
		// 	setIsNavbarPath(true)
		// }
		// else if(!NAVBAR_PATHS?.includes(basePath) && isNavbarPath) {
		// 	dispatch(setNavbarPaths({
		// 		current: basePath,
		// 		previous: previousBasePath,
		// 		isGoodPath: false,
		// 	}))
		// 	setIsNavbarPath(false)
		// }

		// push to clevertap
		if(basePath === "call-logs") pushToClevertap("Used Call Logs", true)

		// update prevLocationHref to current location
		prevLocationHref.current = location.pathname;
	}, [location.pathname])

	return {isNavbarPath, pathname}
}