import "../../design/components/gtableStyles.css";
import { useEffect, useReducer, useState } from "react";
import Button from "../../design/Button";
import GHeader from "../../design/components/GHeader";
import GTable from "../../design/components/GTable";

import addToTeamIcon from "../../../assets/gusers-and-teams-add-to-team-icon.svg";
import userEditIcon from "../../../assets/call-logs-edit.svg";

import "./teamViewStyles.css";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import Colors from "../../design/colors";
import GModal from "../../design/components/GModal";
import { Chip, Menu, MenuItem, Select } from "@mui/material";
import styles from "../../design/styles";
import colors from "../../design/colors";
import chipDeleteIcon from "../../../assets/gusers-and-teams-chip-delete-icon.svg";
import { JService } from "../../api-service/ApiService";
import { enqueueSnackbar } from 'notistack';
import { filtersReducer } from "../../../utility-functions";
import { CustomChip } from "../../design/components/customComponents";

const initialState = {
  roles: {
    all: [
      { id: "Admin", name: "Admin" },
      { id: "Super Admin", name: "Super Admin" },
      { id: "User", name: "User" },
    ],
    selected: [],
  },
  currentSet: "",
  searchText: "",
  searchFields: ["teamViewUser"],
};

const API_BASE_URL = process.env.REACT_APP_BETA;

const generateRowsFromData = (data) => {
    const returnData = Object.values(data).reduce((cumulative, key) => {
      let { id, first_name, last_name, virtual_numbers, role } = key;

      let row = {
        id,
        teamViewUser: first_name + " " + last_name,
        // choosing the first virtual number
        teamViewVirtualNumber:
          Array.isArray(virtual_numbers) && virtual_numbers?.length
            ? virtual_numbers[0]?.country_code +
              " " +
              virtual_numbers[0]?.number
            : "",
        teamViewRole: role,
      };

      cumulative.push(row);
      return cumulative;
    }, []);
    return returnData;
  };

export default function TeamView(props) {
  const location = useLocation();

  const [showLoader, setShowLoader] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [controller, setController] = useState(0); // hook up to useEffect and change controller to fetch data
  const [cols, setCols] = useState([]);
  const [masterRows, setMasterRows] = useState([]);
  const [rows, setRows] = useState([]);

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addModalValues, setAddModalValues] = useState({
    // managers: masterRows?.filter(mr => mr ?.teamVIewRole === 'Admin') || [],
    members: [],
  });

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [contentTitle, setContentTitle] = useState("");

  // mainly for selected usre's id for threeDots dropdown
  const [user, setUser] = useState();

  // users api response data used in threeDotsClick option 'remove'
  const [userStringArray, setUserStringArray] = useState([]);

  // users response data is here also
  const [users, setUsers] = useState([]);

  const handleAddToTeamClick = (e) => {
    if(deleteModalVisible) handleDeleteModalClose();
    setAddModalValues({
      // managers: masterRows?.filter(mr => mr ?.teamVIewRole === 'Admin'),
      members: masterRows
        ?.filter((mr) => mr?.id === 0 || mr?.id)
        ?.map((mrf) => mrf?.teamViewUser + "," + mrf?.id),
    });

    setAddModalVisible(true);
  };

  const addToTeamButton = (
    <Button
      buttonText="Add to team"
      icon={{
        startIcon: (
          <img
            style={{ width: "18px" }}
            src={addToTeamIcon}
            alt="Create team icon"
          />
        ),
        endIcon: null,
      }}
      hierarchy="green"
      variant="outlined"
      disabled={false}
      isLoading={false}
      onClick={handleAddToTeamClick}
	  className="ui--add-to-team"
    />
  );
  
  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  function handleThreeDotsClick(e, id) {
    e.stopPropagation();

    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setUser(id);
  }

  useEffect(() => {
    // simple search
    const searchTextLower = availableData?.searchText?.toLowerCase();
    let finalResult = [];
    let intermediateResult = []; // eslint-disable-line
    availableData?.searchFields?.forEach((searchField) => {
      let intermediateResult = masterRows?.filter((row) =>
        row[searchField]?.toLowerCase()?.includes(searchTextLower)
      );
      finalResult = finalResult.concat(intermediateResult);
    });

    combineSearchAndFilters(finalResult);
  }, [availableData]); // eslint-disable-line

  useEffect(() => {
    const abortController = new AbortController();
    const teamId = window.location.pathname.split("/").pop();

    if (!showLoader) setShowLoader(true);

    JService.get(API_BASE_URL + `/api/v1/auth/teams/${teamId}/`, {}, {signal: abortController.signal})
      .then((res) => {
        if(!('success' in res)) return;
        if (!res?.success) throw new Error(res?.message || "Failed to retreive team details");
        setShowLoader(false);
		    enqueueSnackbar(res?.message || 'Retreived team details', { variant: "success"})
        setCols([
          {
            field: "teamViewUser",
            headerName: "User",
            headerClassName: "teams-view-table-header-team-name",
            // cellClassName: 'teams-view-table-cell-user-name',
            flex: 1,

            renderCell: (params) => (
              <Box style={{ paddingLeft: "18px" }}>
                {params?.row?.teamViewUser}
              </Box>
            ),
          },
          // {
          // 	field: 'teamViewVirtualNumber',
          // 	headerName: 'Virtual Number',
          // 	flex: 1,
          // },
          {
            field: "teamViewRole",
            headerName: "Role",
            flex: 1,
          },
          {
            field: "teamViewEdit",
            headerName: "",
            flex: 0.5,
            cellClassName: "table-cell-edit",
            renderCell: (params) => {
              return (
                <Box>
                  <img
                    onClick={(e) => handleThreeDotsClick(e, params?.id)}
                    src={userEditIcon}
                    alt="three dots icon"
                  />
                </Box>
              );
            },
          },
        ]);

        let temp = generateRowsFromData(res?.data?.users);
        setMasterRows(temp);
        setRows(temp);

        setContentTitle(res?.data?.name || "");
      })
      .catch((err) => {
        setShowLoader(false);
        enqueueSnackbar(err?.message, { variant: "error"})
      })
      // .finally(() => setShowLoader(false));

    // retreive-users

    JService.get(API_BASE_URL + `/api/v1/auth/retrieve-users/`)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Failed to retreive users");
		// enqueueSnackbar(res?.message || 'Retreived users', { variant: "success"})
        setUsers(res?.data);
        let temp = res?.data?.map(
          (rd) => rd?.first_name + " " + rd?.last_name + "," + rd?.id
        );

        setUserStringArray(temp);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));

      return () => abortController.abort();
  }, [controller]); // eslint-disable-line

  const breadcrumbs = [
    <Link style={{ color: Colors.nc_gray_900 }} to="/teams">
      Teams
    </Link>,
    <Link
      style={{ color: Colors.ic_green_400,fontWeight:"500",fontFamily:"inter" }}
      to={"/teams/" + (window.location.pathname.split("/").pop() || "1") + "/"}
    >
      {contentTitle || "-"}
    </Link>,
  ];

  function handleRemoveFromAddModalMembers(value, selectedMembers) {
    setAddModalValues((prevState) => {
      let temp = selectedMembers?.filter(
        (selectedMember) => selectedMember !== value
      );
      return { ...prevState, members: temp };
    });
  }

  // add-to-team
  const addModalBody = addModalVisible ? (
    <Box>
      <form
        style={localStyles.form}
      >
        {/* members */}
        <label className="t7 nc-gray-900 medium-font" style={localStyles.membersLabel}>
          Members
        </label>
		<Select
			multiple={true}
			multiline={true}
			autoFocus={false}
			placeholder="No members selected"
			value={addModalValues?.members}
			onChange={(e) => {
				let {
				target: { value },
				} = e;
				if (value instanceof Array) value = value?.reverse()[0];

				setAddModalValues((prevState) => ({
				...prevState,
				members: [...prevState?.members, value],
				}));
			}}
			renderValue={(selected) => {
				if (userStringArray?.length === 0)
				return <p className="t6 nc-gray-400 regular-font">No members</p>;
				if (selected?.length === 0)
				return (
					<p className="t6 nc-gray-400 regular-font">Select members</p>
				);
				// check selected to see all seleted values, at this point, selected has duplicates
				// removing duplicates from rendering, remove duplicates from Select value before sending
				let temp = selected?.reduce(function (x, y) {
				if (x.indexOf(y) < 0) x.push(y);
				return x;
				}, []);
				return (
				<Box
					sx={{
					maxWidth: "340px",
					width: "100%",
					// border: '1px solid red',
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					flexWrap: "wrap",
					rowGap: "4px",
					}}
				>
					{temp?.map((value) => {
					if (!value) return;
					let id = value?.split(",")[1];
					let name = value?.split(",")[0];
					return (
            <CustomChip
              key={id}
              label={name}
              onDelete={(e) => {
                e.stopPropagation();
                handleRemoveFromAddModalMembers(value, selected);
              }}
            />
					);
					})}
				</Box>
				);
			}}
			sx={localStyles.membersSelect}
			inputProps={{ "aria-label": "Without label" }}
			displayEmpty
			className="ui--select"
		>
			{userStringArray?.map((userString, index) => (
				<MenuItem key={index} value={userString}>
				{userString?.split(",")[0]}{" "}
				</MenuItem>
			))}
		</Select>
      </form>
    </Box>
  ) : null;

  // remove-user-from-team
  const deleteModalBody = deleteModalVisible ? (
    <Box>
      <p className="medium-font t6 nc-gray-900">
        Are you sure you want to remove&nbsp;
        <span
          className="medium-font t6 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {users?.find((d) => d?.id == user)?.first_name +
            " " +
            users?.find((d) => d?.id == user)?.last_name}{" "}
          {/* eslint-disable-line */}
        </span>
        &nbsp;from&nbsp;
        <span
          className="medium-font t6 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {contentTitle}
        </span>
        &nbsp;team?
      </p>
    </Box>
  ) : null;

  // filtering happens here
  function combineSearchAndFilters(arg1) {
    if (!availableData?.roles?.selected?.length) {
      if (arg1 != rows) {
        setRows(arg1); //eslint-disable-line
      }
      return;
    }

    let temp = arg1;

    // roles selected changed
    if (availableData?.roles?.selected?.length) {
      temp = temp?.filter((te) =>
        availableData?.roles?.selected?.includes(te?.teamViewRole)
      );
    }

    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);

    setRows(temp);
  }

  // modal handlers
  // add-modal-save
  function handleAddModalSave(e) {
    const teamId = window.location.pathname.split("/").pop();

    if (!showLoader) setShowLoader(true);

    // member manager sent together

    let temp = [
      ...addModalValues?.members?.reduce(function (x, y) {
        if (x.indexOf(y) < 0) x.push(y);
        return x;
      }, []),
    ];

    let sendMemberIds = [];
    temp.map((te) => {
      //eslint-disable-line
      let id = te.split(",")[1];
      if (!sendMemberIds?.includes(id)) sendMemberIds.push(id);
    });

    sendMemberIds.forEach((s) => parseInt(s));

    let payload = {
      name: addModalValues?.name,
      members: sendMemberIds,
    };

    JService.patch(API_BASE_URL + `/api/v1/auth/teams/${teamId}/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Could not update team details");
		
		enqueueSnackbar(res?.message || "Team details updated", { variant: "success"})
        setController((prevState) => ++prevState);
        handleAddModalClose();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  }

  function handleAddModalClose() {
    setAddModalValues({
      members: [],
    });
    setAddModalVisible(false);
  }

  function handleDeleteModalSave() {
    if (!showLoader) setShowLoader(true);

    // remove user from teamMembers
    let payload = {
      members: masterRows?.map((mr) => mr?.id)?.filter((mrf) => mrf != user), // eslint-disable-line
    };
    const teamId = window.location.pathname.split("/").pop();

    JService.patch(API_BASE_URL + `/api/v1/auth/teams/${teamId}/`, payload)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Failed to remove team member");

		enqueueSnackbar(res?.message || "Member removed from team", { variant: "success"})
        setController((prevState) => ++prevState);
        handleDeleteModalClose();
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  }

  function handleDeleteModalClose() {
    setDeleteModalVisible(false);
    setUser(null);
  }

  function handleDropdownClick(e) {
    const menuitemtype = e.target.dataset.menuitemtype;
    if (menuitemtype === "remove") {
      setDeleteModalVisible(true);
      if (addModalVisible) handleAddModalClose();
    }
    setIsDropdownVisible(false);
  }

  const [csvData, setCSVData] = useState({
    headers: [["User"], ["Role"]],
    data: [],
    ids: [],
  });

  const csvDownloadBody = null;

  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      const newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [item?.teamViewUser, item?.teamViewRole];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
    };
  }, [isDropdownVisible]);

  return (
    <div className="screen-container">
      {/* use current team's title */}
      {console.log("location: ", location)}
      <GHeader
        breadcrumbs={{ isVisible: true, breadcrumbs }}
        threeDots={{
          isVisible: false,
          options: [
            {
              option: "Edit",
            },
            {
              option: "Delete",
            },
          ],
          // // reference props.location.state.teamId in the TeamView page.
          //
          //
          //
          modal: {
            deleteModalVisible: null, //location?.state?.deleteModalVisible,
            setDeleteModalVisible: null, //location?.state?.setDeleteModalVisible,
          },
          id: location?.state?.id,
        }}
        topHeader={{}}
        bottomHeader={{
          contentTitle: "Team members",
          buttons: [addToTeamButton],
          isCalendar: false,
        }}
      />

      <GTable
        leftHeader={{ filters: [{ name: "Role", toggle: false, isSearch: false, payloadFor: 'roles' }] }}
        dispatchAvailableData={dispatchAvailableData}
        availableData={availableData}
        rightHeader={{ isSearch: true, isRefresh: true, isDownload: true }}
        download={{
          data: csvData,
          body: csvDownloadBody,
          filename:
            "frejun-team-members-" +
            new Date().toLocaleString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
        }}
        rows={rows}
        fromScreen="team-view"
        columns={cols}
        useMuiTable={true}
        isLoading={showLoader}
        // getTableRowClassName={getUsersRowClassName} // to be revised
        additionalProps={{ bgHover: true }}
        searchFields={["teamViewUser"]}
        combineSearchAndFilters={combineSearchAndFilters}
      />

      {/* threeDots click */}

      {/* three dots dropdown menu */}
      <Menu open={isDropdownVisible} anchorEl={dropdownAnchorEl}>
        <MenuItem
          data-menuitemtype="remove"
          // disabled={masterRows?.find(mr => mr?.id == user)?.teamViewRole?.toLowerCase() === 'admin' && masterRows?.filter(mr => mr?.teamViewRole?.toLowerCase() === 'admin')?.length < 2}
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          Remove
        </MenuItem>
      </Menu>

      {/* add user to team */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleAddModalClose}
        body={addModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Add to team</span>
        }
        visible={addModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleAddModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Add"
            isLoading={false}
            onClick={handleAddModalSave}
          />,
        ]}
      />

      {/* remove user from team */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleDeleteModalClose}
        body={deleteModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">Remove user</span>
        }
        visible={deleteModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleDeleteModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="red"
            variant="contained"
            buttonText="Remove"
            isLoading={false}
            onClick={handleDeleteModalSave}
          />,
        ]}
      />

    </div>
  );
}

const localStyles = {
	form: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%"
	},
	membersLabel: {
		marginBottom: "4px"
	},
	membersSelect: {
		alignSelf: "flex-start",
		minWidth: 200,
		width: "100%",
		maxWidth: "100%",
		outline: "none",
		borderRadius: "4px",
		padding: 0,
		marginBottom: "24px",
		...styles.t6,
		...styles.regular_font,
		...colors.nc_gray_900,
		"&:before": {
		  outline: "none",
		  border: "none",
		},
		"&:focus .MuiOutlinedInput-input": {
		  border: "none",
		  outline: "none",
		},
		"& .MuiOutlinedInput-input": {
		  padding: "8px 12px 8px 12px",
		  border: "none",
		  outline: "none",
		},
		"& .Mui-disabled": {
			cursor: "not-allowed",
		},
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
      boxShadow: "none"
    },
	}
}