import { useEffect, useRef, useState } from "react";

import {
  oauthFrejunLogoIcon,
  oauthFrejunNavbarLogoIcon,
  oauthGreenTickIcon,
  oauthLinkIcon,
  oauthErrorIcon,
} from "../../utility-functions/assetLoader.js";
import Button from "../design/Button.jsx";
import colors from "../design/colors.js";
import AnimationPage from "../shared/animation-page/AnimationPage.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';

const API_BASE_URL = process.env.REACT_APP_BETA;
const SearchParamKeys = {
  RedirectUri: "redirect_uri",
  ClientId: "client_id"
}

export default function Oauth(props) {
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(false);
  const [isDeclinePendingResponse, setIsDeclinePendingResponse] =
    useState(false);
  const [isAcceptPendingResponse, setIsAcceptPendingResponse] = useState(false);
  const [isAcceptDisabled, setIsAcceptDisabled] = useState(true);
  const [isDeclineDisabled, setIsDeclineDisabled] = useState(true);
  const [data, setData] = useState(undefined);
  const [isClientIdInvalid, setIsClientIdInvalid] = useState(false);

  const otherParamsRef = useRef("");
  const customRedirectUri = useRef("");

  const navigate = useNavigate()

  const allAccounts = [
    "Team account",
    "Customer account",
    "Sales account",
    "Finance accounts",
  ];

  function redirectToLogin() {
    // save client id
    localStorage.setItem(
      "frejun-login-redirect",
      JSON.stringify({ url: window.location.href, isRead: false })
    );

    // redirect to login page // remove from history to prevent back button navigation
    window.location.replace("/login");
  }

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    setShowLoader(true);
    // if (!(reduxAuth.access_token && reduxAuth.refresh_token)) {
    // if (localStorage.getItem('frejun-current-account') && !JSON.parse(localStorage.getItem('frejun-current-account'))?.isLoggedIn) {
    //   redirectToLogin();
    //   return;
    // }

    let client_id = "";
	const decodedSearchParams = decodeURIComponent(window.location.search);
    if (decodedSearchParams && decodedSearchParams?.length > 0) {
      client_id = decodedSearchParams
        .substring(1)
        ?.split("&")
        ?.find((aa) => aa.includes(SearchParamKeys.ClientId))
        .split("=")
        .reverse()
        .at(0) || "";
    }

    if (!client_id || client_id?.trim()?.length === 0) {
      setShowLoader(false);
      setIsClientIdInvalid(true);
      return;
    } else {
      if (isClientIdInvalid) setIsClientIdInvalid(false);
    }

	const locUrl = new URL(window.location);
	if(locUrl.searchParams.size > 1 && locUrl.searchParams.has(SearchParamKeys.ClientId))  {
		locUrl.searchParams.delete(SearchParamKeys.ClientId);
    if(locUrl.searchParams.has(SearchParamKeys.RedirectUri)) {
      customRedirectUri.current =  locUrl.searchParams.get(SearchParamKeys.RedirectUri);
      locUrl.searchParams.delete(SearchParamKeys.RedirectUri);
    }
		otherParamsRef.current = locUrl.search
	}

    // redirect from login page : alreadyLoggedIn
    const url =
      API_BASE_URL + `/api/v1/oauth/client-details/?client_id=${client_id}`;
    let isSuccess = false;
    fetch(url, {
      method: "get",
      credentials: "include",		
    })
      .then((res) => {
        if(res.status === 401){
          localStorage.setItem("frejun-login-redirect", JSON.stringify({url: window.location.href, isRead: false}));
          navigate('/', {replace: true});
          return null
        }
        return res?.json()
      })
      .then((res) => {
        // FIXME: request for change to {success, message, data} format
        console.log("response ", res);
        if(res === null) return;
        if (res?.error) throw new Error(res?.error || "Failed to load page");
        if (res && 'success' in res && !res?.success) {
          setIsClientIdInvalid(true);
          throw new Error(res?.message || "Failed to load page");
        }
        isSuccess = true;
        setData(res.data);
        setIsAcceptDisabled(false);
        setIsDeclineDisabled(false);
      })
      .catch((error) => {
        // console.log(error?.response?.data?.error)
        console.log(error);
        setIsDeclineDisabled(true);
        setIsAcceptDisabled(true);
		enqueueSnackbar(error?.response?.data?.error || error?.message, { variant: "error"})
      })
      .finally(() => {
        setShowLoader(false);
        if(isSuccess) localStorage.removeItem("frejun-login-redirect");
      });

    return () => {
      setData(null);
    };
  }, []);

  function handleAccept() {
    if (!isAcceptPendingResponse) setIsAcceptPendingResponse(true);
    const url =  `/api/v1/oauth/get-access-code/?client_id=${searchParams.get(SearchParamKeys.ClientId)}`
	
    fetch(API_BASE_URL + url,
      {
        method: "get",
        // headers: {
        //   Authorization: "Bearer " + reduxAuth?.access_token,
        // },
        credentials: "include",		
      }
    )
      .then((res) => {
        console.log("headers in response ", res.headers);
        return res?.json();
      })
      .then((res) => {
        if (!res?.success) {
          throw new Error(
            res?.error || res?.message || res?.detail || "Accept failed"
          );
        } else {
          setTimeout(() => {
			let callBackUrl = `${customRedirectUri.current || res?.callback_uri}?code=${res?.code}`
			if(otherParamsRef.current) {
				// pass other params from location url to request url
				callBackUrl += `&${otherParamsRef.current.replace("?", "")}`
			}
            window.location.replace(callBackUrl)
          }, 3000);
        }
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setIsAcceptPendingResponse(false));
  }

  function handleDecline() {
    if (!isDeclinePendingResponse) setIsDeclinePendingResponse(true);
    // for now show error and navigate to "/"
    fetch(
      API_BASE_URL +
        `/api/v1/oauth/decline-permission/?client_id=${searchParams.get(
          "client_id"
        )}`,
      {
        method: "get",
        // headers: {
        //   Authorization: "Bearer " + reduxAuth?.access_token,
        // },
        credentials: "include",		
      }
    )
      .then((res) => {
        console.log("headers in response ", res.headers);
        return res?.json();
      })
      .then((res) => {
        console.log(res, "response from decline permission");
        if (!res?.success) {
          throw new Error(
            res?.error || res?.message || res?.detail || "Decline failed"
          );
        } else {
          setTimeout(() => {
			let callBackUrl = `${customRedirectUri.current || res?.callback_uri}?code=`
			if(otherParamsRef.current) {
				// pass other params from location url to request url
				callBackUrl += `&${otherParamsRef.current.replace("?", "")}`
			}
            window.location.replace(callBackUrl);
          }, 3000);
        }
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => {
        setIsDeclinePendingResponse(false);
      });
  }
  // if(!data && (!showLoader)) return <PageNotFound />

  return (
    <div
      className="screen-container"
      style={{
        zIndex: 141,
        background: colors.ic_white,
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <div style={localStyles.oauthNavbar}>
        <div
          style={{
            cursor:
              isAcceptPendingResponse || isDeclinePendingResponse
                ? "not-allowed"
                : "pointer",
          }}
          onClick={() => {
            if (isAcceptPendingResponse || isDeclinePendingResponse) return;
            setShowLoader(true);
            navigate("/call-logs", {replace: true})
          }}
        >
          {oauthFrejunNavbarLogoIcon()}
        </div>
      </div>
      {showLoader ||
      (isAcceptDisabled && isDeclineDisabled && !isClientIdInvalid) ? (
        <AnimationPage />
      ) : !isClientIdInvalid ? (
        // oauth card
        <div style={localStyles.containerCard}>
          <div style={localStyles.containerHeader}>
            <div style={localStyles.headerLogoIconContainer}>
              {oauthFrejunLogoIcon()}
            </div>

            {/* <div style={localStyles.headerLinkIconContainer}>
							{oauthLinkIcon()}
						</div> */}

            {/* <div style={localStyles.headerLogoIconContainer}>
							<img src={props?.integrationIcon}/>
						</div> */}
          </div>

          <div style={localStyles.containerBody}>
            <p
              className="nc-gray-900 t6 regular-font"
              style={{ lineHeight: "20px !important", marignBottom: "16px" }}
            >
              <strong>'{data?.app_name?.toUpperCase()}'</strong> would like to:
            </p>

            <p
              className="nc-gray-900 t6 regular-font"
              style={localStyles.textPermission}
            >
              {oauthGreenTickIcon()}
              Access user and account information.
            </p>
            <p
              className="nc-gray-900 t6 regular-font"
              style={localStyles.textPermission}
            >
              {oauthGreenTickIcon()}
              Access information about your calls.
            </p>

            {/* <div style={{marginTop: '40px', marginBottom: '4px'}}>
							<strong> Authorize for </strong>
						</div> */}

            {/* <Select
						  disabled={isAcceptPendingResponse || isDeclinePendingResponse || (isAcceptDisabled && isDeclineDisabled)}
						  value={authorizeValues}
						  multiline={false}
						  multiple={false}
						  onChange={e => {
							console.log('select value changed:', e.target.value)
							setAuthorizeValues(e.target.value)	
						  }}
						  displayEmpty
						  sx={{
							height: '40px',
							width: '100%',
							'& .MuiOutlinedInput-notchedOutline': {
								border: `1px solid ${colors.nc_gray_300}`,
							},
							'& .Mui-disabled': {
								cursor: 'not-allowed !important',
							},
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								border: "1px solid #34CB65",
								boxShadow: "0px 0px 0px 4px #F0FCF4",
							},
							outline: 'none',
							borderRadius: '4px',
							...styles.t6,
							...styles.regular_font,
							color: colors.nc_gray_900,
							background: colors.ic_white,
						  }}
						  renderValue={selected => {
							if(!selected) return <p aria-label='' className='regular-font t6 nc-gray-400'>Select account</p>
							return <p aria-label={selected} className='regular-font t6 nc-gray-900'>{selected}</p>
						  }}
						  autoFocus={false}
						>
							{allAccounts?.map((singleAccount, index) => <MenuItem value={singleAccount} key={index}>{singleAccount}</MenuItem>
							)}
						</Select> */}
          </div>

          <div style={localStyles.containerButtons}>
            <Button
              buttonText="Decline"
              variant="outlined"
              hierarchy="white"
              disabled={isDeclineDisabled}
              isLoading={isDeclinePendingResponse}
              onClick={handleDecline}
              styleOverride={{
                marginRight: "12px",
              }}
            />
            <Button
              buttonText="Accept"
              variant="filled"
              disabled={isAcceptDisabled}
              isLoading={isAcceptPendingResponse}
              hierarchy="green"
              onClick={handleAccept}
            />
          </div>
        </div>
      ) : (
        <div style={localStyles.errorContainer}>
          <span>{oauthErrorIcon()}</span>
          <h4 className="t6 medium-font" style={localStyles.errorTitle}>
            Invalid client id
          </h4>
          <p className="t7 regular-font">
            Please contact app developer for more information.
          </p>
        </div>
      )}
    </div>
  );
}

const localStyles = {
  imgGreenTick: {
    display: "inline-block",
  },
  textPermission: {
    lineHeight: "20px !important",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },
  containerBody: {
    maxWidth: "320px",
    width: "320px",
    position: "relative",
    flex: 4.05,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  containerHeader: {
    background: colors.nc_gray_100,
    flex: 1.8,
    alignSelf: "stretch",
    borderBottom: `1px solid ${colors.nc_gray_300}`,
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  containerCard: {
    background: colors.ic_white,
    borderBottom: `1px solid ${colors.nc_gray_300}`,
    borderRadius: "10px 10px 0px 0px",
    // height: 'calc(100vh - 80px)',
    height: "400px",
    width: "600px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "48px",
    border: `1px solid ${colors.nc_gray_400}`,
    borderRadius: "10px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  containerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    padding: "20px 24px",
    borderTop: `0.75px solid ${colors.nc_gray_300}`,
    borderRadius: "0px 0px 10px 10px",
    position: "absolute",
    bottom: 0,
    width: "100%",
    flex: 1,
  },
  headerLogoIconContainer: {
    background: colors.ic_white,
    width: "64px",
    height: "64px",
    borderRadius: "5px",
    border: `1px solid ${colors.nc_gray_300}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerLinkIconContainer: {
    borderRadius: "none",
    border: "none",
    width: "56px",
    height: "64px",
    alignContent: "center",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingLeft: "10px",
  },
  oauthNavbar: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  errorContainer: {
    padding: "40px 32px",
    borderRadius: "10px",
    margin: "80px auto",
    border: `1px solid ${colors.nc_gray_400}`,
    width: "461px",
    textAlign: "center",
  },
  errorTitle: {
    marginTop: "16px",
    marginBottom: "8px",
    lineHeight: "24px",
  },
};
