// TODO: add onClick in JSDOCS
import LoadingButton from "@mui/lab/LoadingButton/index";
import { CircularProgress, Menu, Tooltip } from "@mui/material";
import colors from "./colors";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import styles from './styles';

/**
 *
 * @param {string} 		  				variant    		- either of "text", "contained" or "outlined".
 * @param {string} 		  				buttonText 		- text content of button.
 * @param {object} 			  		  	icon
 * @param {HTMLImageElement}   			icon.startIcon 	- img if icon is at the start.
 * @param {HTMLImageElement}   			icon.endIcon  	- img if icon is at the end.
 * @param {Boolean} 					disabled 		- whether button is disabled or not.
 * @param {Boolean}						isLoading		- disables and shows loading indicator.
 * @param {string}						hierarchy		- "primary" || green ,"destructive" || red or "secondary" || white
 * @returns {import('react').ReactNode}					reusable button component.
 */

export const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	backgroundColor: "#E1E6E2",
	color: "#464D48",
	maxWidth: 220,
	border: "1px solid #dadde9",
	borderRadius: "4px",
	},
	"&.MuiPopperUnstyled-root": {
		zIndex: 1303,
	}
}));

const Button = ({
	variant,
	buttonText,
	icon = { startIcon: null, endIcon: null },
	disabled,
	isLoading,
	hierarchy,
	onClick,
	buttonStyle,
	tooltipMessage,
	styleOverride={},
	spanStyles={},
	onSubmit=()=>null,
	form=null,
	type='button',
	className,
	passref=null
}) => {


	const [tooltipVisible, setTooltipVisible] = useState(false)

	const handleMouseInAction = e => {
		setTooltipVisible(true)
	}
	
	const handleMouseOutAction = e => {
		setTooltipVisible(false)
	}
	const handleMouseClick=e=>{
		if(tooltipVisible) setTooltipVisible(false)
		onClick(e)
	}
	return <>

		
		<StyledTooltip
			placement='top'
			arrow
			open={(tooltipVisible && tooltipMessage?.length > 0)}
			title={<p className="t7">{tooltipMessage}</p>}
			sx={{
				"& .MuiTooltip-arrow": {
				color: "#E1E6E2",
				},
			}}
		>
			<span style={{...spanStyles}}>
				<LoadingButton
					ref={passref}
					variant={variant}
					style={buttonStyle}
					startIcon={icon?.startIcon}
					endIcon={icon?.endIcon}
					disabled={disabled}
					size="large"
					type={type}
					//onClick={e => onClick(e)}
					onClick={handleMouseClick}
					onSubmit={e => onSubmit(e)}
					form={form}
					onBlur={e => {
						if(tooltipMessage?.length > 0 && (!tooltipVisible)) setTooltipVisible(true)
					}}
					onPointerEnter={handleMouseInAction}
					onPointerLeave={handleMouseOutAction}
					loading={isLoading}
					sx={{
						...styles.b1,
						...styles.regular_font,
						
						boxShadow: 'none',
						paddingLeft: '24px',
						paddingRight: '24px',
						color:
						variant === "text"
							? colors.ic_green_500
							: hierarchy === "white"
							? colors.nc_gray_900
							: colors.ic_white,
						borderRadius: "4px",
						// width: '125px', // disabled to set default, 'auto'.
						height: "40px",
						backgroundColor:
						hierarchy === "green"
							? colors.ic_green_300
							: hierarchy === "red"
							? colors.ic_red_300
							: "transparent",
						border:
						(hierarchy === "white" && variant === 'outlined')
							? `1px solid ${colors.nc_gray_300}`
							: "none",
						cursor: isLoading || disabled ? "not-allowed" : "pointer",
						"&:hover, &.Mui-active": {
						border:
							(hierarchy === "white" && variant === 'outlined')
							? `1px solid ${colors.nc_gray_300}`
							: "none",
						backgroundColor:
							hierarchy === "green"
							? colors.ic_green_400
							: hierarchy === "red"
							? colors.ic_red_400
							: colors.nc_gray_100,
						cursor: "pointer",
						boxShadow: 'none',
						},
						"&.Mui-disabled, &.Mui-error": {
						color:  colors.nc_gray_400 ,
						border: "none",
						backgroundColor:
							variant === "text" ? "transparent" : colors.nc_gray_200,
						pointerEvents: "all",
						cursor: "not-allowed",
						},
						...styleOverride,
					}}
					className={className}
				>
					{buttonText}
				</LoadingButton>
			</span>
		</StyledTooltip>
	</>
}

export default Button;
