import clevertap from "clevertap-web-sdk";
import { JService } from "../components/api-service/ApiService";
import { setDialer } from "../components/features/dialer/dialer";
import {store} from '../index';

export const formatFileSize = (number) => {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
};
const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
export const formatDate = (d) => {
   	if(["-", "+"].includes(String(d)?.at(-5))) {
		d = d?.slice(0, -5) || d
	}

  const dateObj = new Date(d);
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  let ordinalSuffix = "";

  switch (day % 10) {
    case 1:
      ordinalSuffix = "st";
      break;
    case 2:
      ordinalSuffix = "nd";
      break;
    case 3:
      ordinalSuffix = "rd";
      break;
    default:
      ordinalSuffix = "th";
  }
  if (day > 3 && day < 21) ordinalSuffix = "th";

  const dateStamp = day + ordinalSuffix + " " + monthNames[month] + " " + year;

  return dateStamp;
};

export const formatTime = (d) => {
  if(["-", "+"].includes(String(d)?.at(-5))) {
	d = d?.slice(0, -5) || d
  }
  let _d = "";
  let _hour = new Date(d).getHours();
  let _minute = new Date(d).getMinutes();
  if (_minute < 10) _minute = "0" + _minute;
  if (_hour > 12) {
    // 13:00 hours etc
    _hour -= 12;
    // no zero needed at the start
    if (_hour >= 10) _d = _hour + ":" + _minute + " PM";
    else _d = "0" + _hour + ":" + _minute + " PM";
  } else if (_hour >= 10 && _hour <= 12) {
    // 10:00 to 12:59 hours
    if (_hour === 12) _d = _hour + ":" + _minute + " PM";
    else _d = _hour + ":" + _minute + " AM";
  } else if (_hour < 10) {
    _d = "0" + _hour + ":" + _minute + " AM";
  }
  return _d;
};

export const capitalizeString = (str) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

// Function will accept time in minutes and return an object with hrs,mins,secs
// Eg: 3242.3214 -> {hrs:52, mins:2, secs:19}
// Eg: 68.489 -> {hrs:1, mins:8, secs:29}
export const getHMSfromMins = (time) => {
  const hrs = Math.floor(time / 60);
  time = time % 60;
  const mins = Math.floor(time);
  time = time - mins;
  const secs = Math.floor(time * 60);

  return { hrs: hrs, mins: mins, secs: secs };
};

// Function will accept time in miliseconds and return an object with hrs,mins,secs
export const getHMSfromMilliSecs = (time) => {
  const secs = parseInt(time / 1000) % 60;
  const mins = parseInt((time / (1000 * 60)) % 60);
  const hrs = parseInt((time / (1000 * 60 * 60)) % 24);

  return { hrs: hrs, mins: mins, secs: secs };
};

export const validateEmail = (email) => {
  const reg = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,15}$/;
  console.log("zz", reg.test(email));
  return reg.test(email);
};

// sleep for ms milliseconds
export async function sleep(ms) {
  return new Promise((resolve, reject) => {
    if (!Number.isInteger(ms) || ms < 0) {
      // ms is not an integer
      reject("expected 'positive integer' for argument 'ms', got ", ms);
    }
    setTimeout(() => {
      resolve(`slept for "${ms / 1000}" seconds`);
    }, ms);
  });
}

export const formatCurrentDate = (date = Date.now()) => {
  return new Date(date).toLocaleDateString("en-IN", {
    day: "numeric",
    weekday: "long",
    month: "long",
    year: "numeric",
  });
};

export const fetchData = (url, setterFn, resourceName) => {
  JService.get(url)
    .then((res) => {
      if(!res?.success) throw new Error(res?.message || `Could not retrieve ${resourceName}`);
      setterFn(res?.data);
    })
    .catch((err) => console.error(`Could not retrieve ${resourceName}`, err));
};

export const initiateCall=(e, candidateNumber, contact_list_id)=>{ 
  e.stopPropagation();
  const reduxAccount = store.getState().account.value;
  const reduxDialer = store.getState().globalDialer.value;
  const maskNumbers = reduxAccount?.data?.number_masking && reduxAccount?.data?.profile?.role !== 1
  try {
    let _phone = candidateNumber;
    store.dispatch(
      setDialer({
        phone_number: _phone,
        country_code: null,
        isVisible: true,
        contact_list_id,
        maskNumbers
      })
    );
    const payload = {
      candidate_number: _phone,
      virtual_number: "-1",
    };
    // @clevertapv1
    clevertap.event.push("Phone button clicked", {
      source: "message logs",
      userEmail: reduxAccount?.data?.user_email,
      payload: JSON.stringify(payload),
      name: "initiating call from phone button",
      path: window.location.href,
      IDENTITY: clevertap.getCleverTapID(),
    });
  } catch (err) {
    // @clevertap
    clevertap.event.push("ERROR on phone button clicked", {
      source: "message logs",
      userEmail: reduxAccount?.data?.user_email,
      payload: "error on initiating call from phone button",
      path: window.location.href,
      error: JSON.stringify({
        name: err?.name,
        stack: err?.stack,
        message: err?.message,
        payload: {
          candidate_number: reduxDialer?.phone_number,
        },
      }),
      IDENTITY: clevertap.getCleverTapID(),
    });
  }

}
export function filtersReducer (state, action) {
  const {payload, payloadFor, extraPayload} = action?.payload || {};
  switch(action.type){
    case "setPayloadToAllOfType" : {
      console.log('here', payloadFor);
      console.log({
        ...state, 
        [payloadFor]: {...state[payloadFor], all: payload, ...(payloadFor === 'teams' ? {teamUserMapping: extraPayload} : {})}
      });
      return {
        ...state, 
        [payloadFor]: {...state[payloadFor], all: payload, ...(payloadFor === 'teams' ? {teamUserMapping: extraPayload} : {})}
      }
    }
    case "handleSelectedOfType": {
      return {
        ...state,
        [payloadFor]: {...state[payloadFor], selected: payload}
      }
    }
    case "handleClearOfType": {
      return {
        ...state,
        [payloadFor]: {...state[payloadFor], selected: []}
      }
    }
    case "clearAllFilters": {
      const temp = {...state};
      Object.keys(state)?.forEach(key => {
        if(typeof temp[key] === 'object' && !Array.isArray(temp[key])){
          temp[key] = {...temp[key], ...('selected' in temp[key] ? {selected: []} : {})}
        }
      })
      if("searchText" in temp) temp['searchText'] = ""
      return temp;
    }
    case "setSearchText": {
      if (typeof action.payload !== "string") return state;
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case "overwriteState": {
      let __payload = {
        ...JSON.parse(localStorage.getItem("frejun-temp-logs-filters")),
        persist: false,
      };
      return __payload;
     }
    default:
      return state;
  }
}

export const getOrgURLParam = () => {
  const reduxOrg = store.getState().account.value;
  let orgUrlParam = "";
    if (reduxOrg?.data?.profile?.account_holder &&
        reduxOrg?.allOrgs?.length > 1) {
      orgUrlParam = reduxOrg?.selectedOrg + "/";
      if (!reduxOrg?.selectedOrg && reduxOrg?.selectedOrg !== 0)
        orgUrlParam = "";
    }
  return orgUrlParam;
}