import Button from "../../../design/Button";
import '../topNavStyles.css';

const Micpermission = ({micPermissions, handleMicPermission}) => {
    return (
        <div className="dialer--mic_permissions">
            {!micPermissions || micPermissions === "dismissed" ? (
              <p>Grant microphone access to start making calls from FreJun.</p>
            ) : (
              <p>
                Access to the microphone is explicitly blocked by the system or
                a user.
              </p>
            )}

            {
              <Button
                hierarchy="green"
                buttonText="Allow access"
                onClick={handleMicPermission}
                styleOverride={{ width: "100%" }}
              />
            }
          </div>
    )
}

export default Micpermission;