import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import colors from "../design/colors";
import GBreadcrumbs from "../design/components/GBreadcrumbs";
import breadcrumbsSeparatorIcon from "../../assets/gusers-and-teams-breadcrumbs-separtor.svg";
import "./VirtualNumberView.css";
import GeneralTab from "./GeneralTab";
import IncomingTab from "./IncomingTab";
import { JService } from "../api-service/ApiService";
import OutgoingTab from "./OutgoingTab";
import { enqueueSnackbar } from "notistack"
import { useSelector } from "react-redux";

function VirtualNumberView() {
  const location = useLocation();
  const { id: VirtualNumberId, type: numberType } = location?.state;

  const accessList = useSelector(state => state?.account?.value?.data?.access_list);

  const [tabNames, setTabNames] = useState([]);
  const [activeTab, setActiveTab] = useState("General");
  const [originalSettings, setOriginalSettings] = useState([]);
  const [surveysList, setSurveysList] = useState([]);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    fetchData();
    if(accessList.includes('IVR SURVEY')) fetchSurveys();
  }, [accessList]);

  const fetchData = () => {
    const url = `/api/v1/auth/virtual-numbers/${
      numberType === "Calling" ? "calling" : "voice-broadcast"
    }/${VirtualNumberId}/`;

    setShowLoader(true);
    JService.get(url)
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Error occurred while fetching number details")
		
		setOriginalSettings(res.data);

		if (res.data.type === "Calling") {
			setTabNames(["General", "Incoming calls", "Outgoing calls"]);
		}
		if (res.data.type === "Voice Broadcast") {
			setTabNames(["General", "Outgoing calls"]);
		}
		// if (res.data.type === "IVR") {
		//   setTabNames(["General", "Incoming calls"]);
		// }
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  };

  const fetchSurveys = async () => {
    try {
      const res = await JService.get("/api/v1/core/retrieve-ivr-surveys/");
      if (!res.success) {
        throw new Error(res.message || "Error while retrieving surveys");
      }
      const temp = res.data.map(elem => ({id: elem.id, title: elem.title}));
      setSurveysList(temp);
    } catch (err) {
      enqueueSnackbar(err.message || "Error while retrieving surveys", {variant: "error"});
    }
  }

  const breadcrumbs = [
    <Link
      style={{ color: colors.nc_gray_900, userSelect: "none" }}
      to="/virtual-numbers"
    >
      Virtual numbers
    </Link>,
    <Link
      style={{ color: colors.ic_green_400, userSelect: "none", fontWeight:"500px", fontFamily:'inter'}}
      to={"/virtual-numbers/" + (VirtualNumberId || "1") + "/"}
    >
      {originalSettings.name}
    </Link>,
  ];

  return (
    <div
      className="screen-container "
      style={{
        paddingTop: "32px",
        height:
          document.documentElement.scrollHeight - 56 >= window.innerHeight
            ? "fit-content"
            : window.innerHeight,
      }}
    >

      <GBreadcrumbs
        breadcrumbs={breadcrumbs}
        separator={
          <img src={breadcrumbsSeparatorIcon} alt="breadcrumbs separtor icon" />
        }
      />

      <p
        className="t3 medium-font nc-gray-900"
        style={{
          display: "inline-block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          maxWidth: "620px",
          cursor: "default",
          marginTop: "25px",
        }}
      >
        {originalSettings.name || "-"}
      </p>

      {!!tabNames && (
        <div className="tab-selection">
          {tabNames.map((tabName, index) => {
            return (
              <p
                className={`b1 nc-gray-100 tab-label ${
                  tabName === activeTab && "selected-tab-label"
                }`}
                key={index}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </p>
            );
          })}
        </div>
      )}

      {/* General Tab */}
      {activeTab === "General" && (
        <GeneralTab
          originalSettings={originalSettings}
          setOriginalSettings={setOriginalSettings}
          surveysList={surveysList}
        />
      )}

      {/* Incoming Tab */}
      {activeTab === "Incoming calls" && (
        <IncomingTab
          originalSettings={originalSettings}
          setOriginalSettings={setOriginalSettings}
        />
      )}

      {/* Outgoing Tab */}
      {activeTab === "Outgoing calls" && (
        <OutgoingTab
          originalSettings={originalSettings}
          setOriginalSettings={setOriginalSettings}
        />
      )}
    </div>
  );
}

export default VirtualNumberView;
