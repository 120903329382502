// #css
import "./sideNavStyles.css";
import colors from "../../design/colors";

// #lib
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/system";

// # assets
import userTeamsIcon from "../../../assets/gusers-and-teams-nav-icon.svg";
import {
  sideNavCallLogsIcon,
  sideNavCampaignsIcon,
  sideNavContactListsIcon,
  sideNavDashboardIcon,
  sideNavSettingsIcon,
  sideNavUsersTeamsGroupIcon,
  sideNavTemplatesIcon,
  sideNavVoiceBroadcastIcon,
  virtualNumbersIcon,
  voiceBroadcastIcon,
  sideNavPlatformRulesIcon,
  topNavAccountPlusIcon,
  sideNavBillingIcon,
} from "../../../utility-functions/assetLoader";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function SideNav() {
  const accountDetails = useSelector((state) => state.account.value);
  const reduxSubscription=useSelector((state)=>state?.account?.value?.subscription)
  const accessList = useSelector((state) => state?.account?.value?.data?.access_list);
  const userRole = accountDetails?.data?.profile?.role;

  var isCampaignVisible = false;
  if ((accessList)?.includes("AUTODIAL") || accessList?.includes("IVR SURVEY")) {
    isCampaignVisible = true;
  } 

  const [usersTeamsOpen, setUsersTeamsOpen] = useState(false);
  const [campaignsOpen, setCampaignsOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);

  function handleScrollForNav(e) {
    const _scrolledBy = Math.ceil(
      Math.abs(document.body.getBoundingClientRect().top)
    );

    const _sideNav = document.querySelector(".side-nav-ul");

    if (_scrolledBy >= 64) {
      _sideNav.style.position = "fixed";
      _sideNav.style.top = "0px";
      _sideNav.style.left = "0";
      _sideNav.style.height = "calc(100vh)";
      _sideNav.classList.add(["with-top-border"]);
    }

    if (_scrolledBy === 0) {
      _sideNav.style.position = "fixed";
      _sideNav.style.top = `var(--top-nav-height)`;
      _sideNav.style.left = "unset";
      _sideNav.style.height = "calc(100vh - var(--top-nav-height))";
      _sideNav.classList.remove(["with-top-border"]);
    } else if (_scrolledBy < 64) {
      let negativeScrollAdjustment = -1 * parseInt(window.scrollY);
      _sideNav.style.position = "fixed";
      _sideNav.style.top = `calc(var(--top-nav-height) + ${negativeScrollAdjustment}px)`;
      _sideNav.style.left = "unset";
      _sideNav.style.height = `calc(100vh - var(--top-nav-height) + 8px - ${negativeScrollAdjustment}px)`;
      _sideNav.classList.add(["with-top-border"]);
    }
    //logic: position:fixed (to 0,0) when scroll>=90 else position:relative
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrollForNav);
    return () => window.removeEventListener("scroll", handleScrollForNav);
  }, []);

  // useEffect(()=>{
  //   const root=document.querySelector(':root');
  //   if([1,4].includes(accountDetails?.data?.profile?.role))
  //   root.style.setProperty('--credit-container-height', '49px');
  //   else
  //   root.style.setProperty('--credit-container-height', '0px');
  // },[accountDetails]);

  if(window.location.pathname==='/account-suspended')
  return null

  return (
    <>
    <div className="side-nav-ul-container">
    <ul className="side-nav-ul b1 regular-font nc-gray-900">
      {/* DASHBOARD */}
      <li className="side-nav-li b1 regular-font nc-gray-900">
        {accountDetails?.data?.profile?.role.toString() !== "2" &&
        accountDetails?.data?.profile?.role.toString() !== "3" ? (
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "side-nav-link active-link" : "side-nav-link"
            }
          >
            {({ isActive }) => (
              <>
                {isActive
                  ? sideNavDashboardIcon(colors.ic_green_400)
                  : sideNavDashboardIcon()}
                <p
                // style={{marginLeft: '8px'}}
                >
                  Dashboard
                </p>
              </>
            )}
          </NavLink>
        ) : null}
      </li>

      {/* CALL LOGS */}
      <li className="side-nav-li">
        <NavLink
          className={({ isActive }) =>
            (isActive || window.location.pathname.includes('activity-logs') || window.location.pathname.includes('call-logs')) ? "side-nav-link active-link" : "side-nav-link"
          }
          to={reduxSubscription?.plan?.name?.includes('USD')? "/activity-logs/calls":"/call-logs"}
        >
          {({ isActive }) => (
            <>
              {(isActive || window.location.pathname.includes('activity-logs') || window.location.pathname.includes('call-logs'))
                ? sideNavCallLogsIcon(colors.ic_green_400)
                : sideNavCallLogsIcon(colors.nc_gray_900)}
              <p>{reduxSubscription?.plan?.name?.includes('USD')? "Activity logs":"Call logs"}</p>
            </>
          )}
        </NavLink>
      </li>

      {/* CONTACT LISTS */}
      <li className="side-nav-li">
        <NavLink
          to="/contact-lists"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavContactListsIcon(colors.ic_green_400)
                : sideNavContactListsIcon()}
              <p>Contact lists</p>
            </>
          )}
        </NavLink>
      </li>

      {/* CAMPAIGNS : VB & AUTODIAL */}
      <>
      {isCampaignVisible && (
        <><li
                className="side-nav-li collapse-nav-container"
                onClick={() => setCampaignsOpen((prevState) => !prevState)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ marginRight: "8px" }}>{sideNavCampaignsIcon()}</Box>
                  <p className="b1 regular-font nc-gray-900">Campaigns</p>
                </div>
                {campaignsOpen ? <ExpandLess /> : <ExpandMore />}
              </li><Collapse in={campaignsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {(accessList)?.includes("AUTODIAL") && 
                      <li className="side-nav-li">
                        <NavLink
                          to="/autodial"
                          className={({ isActive }) => isActive
                            ? "side-nav-link active-link child-link"
                            : "side-nav-link child-link"}
                        >
                          <p>Autodial</p>
                        </NavLink>
                      </li>
                    }
                    {(accessList)?.includes("IVR SURVEY") && [1,4].includes(userRole) &&
                      <li className="side-nav-li">
                        <NavLink
                          to="/survey"
                          className={({ isActive }) => isActive
                            ? "side-nav-link active-link child-link"
                            : "side-nav-link child-link"}
                        >
                          <p>Survey</p>
                        </NavLink>
                      </li>
                    }

                    {/* <li className="side-nav-li b1">
      <NavLink
        to="/voice-broadcast"
        className={({ isActive }) =>
          isActive
            ? "side-nav-link active-link child-link"
            : "side-nav-link child-link"
        }
      >
        <p>Voice broadcast</p>
      </NavLink>
    </li> */}
                  </List>
                </Collapse></>
        )}
      </>

      {/* USERS & TEAMS*/}
      <>
        {accountDetails?.data?.profile?.role.toString() !== "2" &&
          accountDetails?.data?.profile?.role.toString() !== "3" && (
            <li
              className="side-nav-li collapse-nav-container"
              onClick={() => setUsersTeamsOpen((prevState) => !prevState)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={userTeamsIcon}
                  alt="Users and teams icon"
                  style={{ marginRight: "8px" }}
                />
                <p className="b1 regular-font nc-gray-900">Users & teams</p>
              </div>
              {usersTeamsOpen ? <ExpandLess /> : <ExpandMore />}
            </li>
          )}

        <Collapse in={usersTeamsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <li className="side-nav-li">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  isActive
                    ? "side-nav-link active-link child-link"
                    : "side-nav-link child-link"
                }
              >
                <p>Users</p>
              </NavLink>
            </li>

            <li className="side-nav-li b1">
              <NavLink
                to="/teams"
                className={({ isActive }) =>
                  isActive
                    ? "side-nav-link active-link child-link"
                    : "side-nav-link child-link"
                }
              >
                <p>Teams</p>
              </NavLink>
            </li>
          </List>
        </Collapse>
      </>
      {/* Templates */}
      <>
        {accountDetails?.data?.profile?.role.toString() !== "2" &&
          accountDetails?.data?.profile?.role.toString() !== "3" && (
            <li
              className="side-nav-li collapse-nav-container"
              onClick={() => setTemplatesOpen((prevState) => !prevState)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ marginRight: "8px" }}>{sideNavTemplatesIcon()}</Box>
                <p className="b1 regular-font nc-gray-900">Templates</p>
              </div>
              {templatesOpen ? <ExpandLess /> : <ExpandMore />}
            </li>
          )}

        <Collapse in={templatesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <li className="side-nav-li">
              <NavLink
                to="/messages"
                className={({ isActive }) =>
                  isActive
                    ? "side-nav-link active-link child-link"
                    : "side-nav-link child-link"
                }
              >
                <p>Messages</p>
              </NavLink>
            </li>

            {/* <li className="side-nav-li b1">
              <NavLink
                to="/audio-files"
                className={({ isActive }) =>
                  isActive
                    ? "side-nav-link active-link child-link"
                    : "side-nav-link child-link"
                }
              >
                <p>Audio Files</p>
              </NavLink>
            </li> */}
          </List>
        </Collapse>
      </>
      {/* VIRTUAL NUMBERS */}
      {accountDetails?.data?.profile?.role.toString() !== "2" &&
        accountDetails?.data?.profile?.role.toString() !== "3" && (
          <li className="side-nav-li">
            <NavLink
              to="/virtual-numbers"
              className={({ isActive }) =>
                isActive ? "side-nav-link active-link" : "side-nav-link"
              }
            >
              {({ isActive }) => (
                <>
                  {isActive
                    ? virtualNumbersIcon(colors.ic_green_400)
                    : virtualNumbersIcon()}
                  <p>Virtual numbers</p>
                </>
              )}
            </NavLink>
          </li>
        )}

      {/* BILLING */}
      {accountDetails?.data?.profile?.role.toString() !== "2" &&
        accountDetails?.data?.profile?.role.toString() !== "3" && (
      <li className="side-nav-li">
        <NavLink
          to="/billing"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavBillingIcon(colors.ic_green_400)
                : sideNavBillingIcon()}
              <p>Billing</p>
            </>
          )}
        </NavLink>
      </li>)}

      {/* SETTINGS */}
      <li className="side-nav-li">
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavSettingsIcon(colors.ic_green_400)
                : sideNavSettingsIcon()}
              <p>Settings</p>
            </>
          )}
        </NavLink>
      </li>
      
	  
	  {/* PLATFORM RULES */}
      {/* <li className="side-nav-li">
        <NavLink
          to="/platform-rules"
          className={({ isActive }) =>
            isActive ? "side-nav-link active-link" : "side-nav-link"
          }
        >
          {({ isActive }) => (
            <>
              {isActive
                ? sideNavPlatformRulesIcon(colors.ic_green_400)
                : sideNavPlatformRulesIcon()}
              <p>Platform rules</p>
            </>
          )}
        </NavLink>
      </li> */}
    </ul>
 </div>
   
    </>
  );
}
