import animationData from "../../../assets/animations/lottie-loader.json";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

function AnimationPage(props) {
  const styles = {
    containerStyle: {
      // to align the loader wrt the entire viewport
      // left: '50%',
      // transform: 'translate(-50%, 0%)',
      // position: 'fixed',
      // or enable below line to center loader according to the space excluding sideNavbar
      // height: '350px',
      height: props?.height || "150px",
      transform: "translate(-5%, 5%)",
      ...props.style,
    },
  };

  const lottieRef = useRef();
  useEffect(() => {
    lottie.loadAnimation({
      container: lottieRef.current,
      animationData,
    });
    return () => {
      lottie.destroy();
    }
  }, []);
  return <div ref={lottieRef} style={styles.containerStyle}></div>;
}

export default AnimationPage;
